import merge from "lodash/merge";
import { Reducer } from "redux";
import {
  UserDashboardsAction,
  UserDashboardCardAction,
  DashboardTypesAction,
  AuditStatusesAction,
  AllUserDashboardCardAction,
} from "./actions";
import { ActionTypes } from "../enums/ActionTypes";
import { UserDashboard } from "../../abstractions/userDashboard/UserDashboard";
import { DashboardType } from "../../abstractions/dashboardTypes/DashboardType";
import { Keywords } from "../../abstractions/dashboardTypes/Keywords";
import { AuditStatuses } from "../../abstractions/dashboardTypes/AuditStatuses";
import { forEach, cloneDeep } from "lodash";
import { ErrorStr } from "../../types";

export interface DashboardsState {
  dashboards: DashboardsList;
  dashboardTypes: Record<string, DashboardType>;
  keywords: Keywords;
  auditStatuses: AuditStatuses;
  errorMessage?: string;
}

export interface DashboardsList {
  [Id: string]: UserDashboard;
}

type ReducerActions =
  | UserDashboardsAction
  | UserDashboardCardAction
  | AllUserDashboardCardAction
  | DashboardTypesAction
  | AuditStatusesAction;

// Updates dashboards store in response to successful dashboards request
const reducer: Reducer<DashboardsState | undefined, ReducerActions> = (
  state: DashboardsState | undefined,
  action: ReducerActions
) => {
  if (state === undefined) {
    return {
      dashboards: {},
      dashboardTypes: {},
      keywords: {},
      auditStatuses: {},
    } as DashboardsState;
  }

  let returnState = state;

  switch (action.type) {
    case ActionTypes.UserDashboardsSuccess: {
      if (action.response) {
        const dashboardArray = action.response as unknown as UserDashboard[];
        const dashboards = {} as DashboardsList;
        forEach(dashboardArray, (dash) => {
          dashboards[dash.Id as string] = dash;
          const options = dash.Options;
          if (typeof options === "string") {
            dashboards[dash.Id as string].Options = JSON.parse(
              options
            ) as Record<string, unknown>;
          }
        });
        returnState = merge({}, state, { dashboards }) as DashboardsState;
      }
      return returnState;
    }

    case ActionTypes.UserDashboardsFailure: {
      returnState = merge({}, state, {
        errorMessage:
          action.error && action.error !== ErrorStr.Default
            ? action.error
            : ErrorStr.UserDashboardsFailure,
      });
      return returnState;
    }

    case ActionTypes.UserDashboardsCreateSuccess: {
      if (action.response) {
        const dashboard = action.response as unknown as UserDashboard;
        returnState = merge({}, state, {
          dashboards: { [dashboard.Id as string]: dashboard },
        });
      }
      return returnState;
    }

    case ActionTypes.UserDashboardsCreateFailure: {
      returnState = merge({}, state, {
        errorMessage:
          action.error && action.error !== ErrorStr.Default
            ? action.error
            : ErrorStr.UserDashboardsCreateFailure,
      });
      return returnState;
    }

    case ActionTypes.UserDashboardCardSuccess: {
      if (action.response && action.UserDashboardId) {
        const stateCopy = cloneDeep(state);
        delete stateCopy.dashboards[action.UserDashboardId].Data;
        const dashWithData = merge(
          {},
          stateCopy.dashboards[action.UserDashboardId],
          {
            Data: action.response,
          }
        );
        returnState = merge({}, stateCopy, {
          dashboards: { [action.UserDashboardId]: dashWithData },
        });
      }
      return returnState;
    }

    case ActionTypes.UserDashboardCardFailure: {
      returnState = merge({}, state, {
        errorMessage:
          action.error && action.error !== ErrorStr.Default
            ? action.error
            : ErrorStr.UserDashboardCardFailure,
      });
      return returnState;
    }

    case ActionTypes.AllUserDashboardCardsSuccess: {
      if (action.response) {
        const stateCopy = cloneDeep(state);
        const dashboards: Record<string, unknown> = {};
        for (const dash in state.dashboards) {
          delete stateCopy.dashboards[dash].Data;
          dashboards[dash] = merge({}, { Data: action.response[dash] });
        }
        returnState = merge({}, stateCopy, { dashboards });
      }
      return returnState;
    }

    case ActionTypes.AllUserDashboardsUpdateRequest: {
      if (action.payload && action.payload.item) {
        const dashboards = {} as Record<string, UserDashboard>;
        const payload = action.payload.item as Record<string, UserDashboard>;
        // Move contents of Properties directly onto dashboards and remove it
        for (const dash in payload) {
          dashboards[dash] = merge({}, payload[dash], {
            ...payload[dash].Properties,
          });
          delete dashboards[dash].Properties;
        }
        returnState = merge({}, state, { dashboards });
      }
      return returnState;
    }

    case ActionTypes.AllUserDashboardsUpdateFailure: {
      if (action.payload && action.payload.prevItem) {
        returnState = merge({}, state, {
          dashboards: action.payload.prevItem,
          errorMessage:
            action.error && action.error !== ErrorStr.Default
              ? action.error
              : ErrorStr.AllUserDashboardsUpdateFailure,
        });
      }
      return returnState;
    }

    case ActionTypes.UserDashboardsUpdateRequest: {
      if (action.payload && action.payload.item) {
        let dashboard = {} as UserDashboard;
        const payload = action.payload.item as UserDashboard;
        // Move contents of Properties directly onto dashboards and remove it
        dashboard = merge({}, payload, {
          ...payload.Properties,
        });
        delete dashboard.Properties;
        returnState = merge({}, state, {
          dashboards: {
            [dashboard.Id as string]: dashboard,
          },
        } as DashboardsState);
      }
      return returnState;
    }

    case ActionTypes.UserDashboardsUpdateFailure: {
      if (action.payload && action.payload.prevItem) {
        returnState = merge({}, state, {
          dashboards: {
            [action.payload.prevItem.Id as string]: action.payload.prevItem,
          },
          errorMessage:
            action.error && action.error !== ErrorStr.Default
              ? action.error
              : ErrorStr.UserDashboardsUpdateFailure,
        } as DashboardsState);
      }
      return returnState;
    }

    case ActionTypes.UserDashboardsDeleteRequest: {
      if (action.payload && action.payload.item) {
        const newDashboards = merge({}, state);
        delete newDashboards.dashboards[action.payload.item.Id as string];
        returnState = newDashboards;
      }
      return returnState;
    }

    case ActionTypes.UserDashboardsDeleteFailure: {
      if (action.payload && action.payload.prevItem) {
        returnState = merge({}, state, {
          dashboards: {
            [action.payload.prevItem.Id as string]: action.payload.prevItem,
          },
          errorMessage:
            action.error && action.error !== ErrorStr.Default
              ? action.error
              : ErrorStr.UserDashboardsDeleteFailure,
        } as DashboardsState);
      }
      return returnState;
    }

    case ActionTypes.DashboardTypesSuccess: {
      if (action.response) {
        const dashboardTypes = {} as Record<string, unknown>;
        forEach(action.response, (dashType) => {
          dashboardTypes[(dashType as Record<string, string>).Id] = merge(
            {},
            dashType
          );
        });
        returnState = merge({}, state, {
          dashboardTypes,
        });
      }
      return returnState;
    }

    case ActionTypes.AuditStatusesSuccess: {
      if (action.response) {
        returnState = merge({}, state, {
          auditStatuses: action.response.Values,
        });
      }
      return returnState;
    }

    case ActionTypes.UserDashboardsClearError: {
      returnState = cloneDeep(state);
      delete returnState.errorMessage;
      return returnState;
    }
  }

  return returnState;
};

export default reducer;
