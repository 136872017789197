import merge from "lodash/merge";
import { Reducer } from "redux";
import { ErrorsAction } from "./actions";
import { ActionTypes } from "../enums/ActionTypes";

export interface ErrorsState {
  Id: string;
  Name: string;
  Message: string;
  Anacdote: string;
}

const reducer: Reducer<ErrorsState | undefined, ErrorsAction> = (
  state: ErrorsState | undefined,
  action: ErrorsAction
) => {
  if (action.type === ActionTypes.ErrorDetailSuccess && action.response) {
    const res = action.response as ErrorsState;
    return merge({}, state, res);
  }

  if (state === undefined) {
    return {} as ErrorsState;
  }

  return state;
};

export default reducer;
