import { FileDefinition } from "abstractions/file/FileDefinition";
import { Request } from "abstractions/request/Request";
import _ from "lodash";

export const getUriObjectsToString = (
  editRequest: Request
): FileDefinition[] => {
  return (
    // Convert any attachment Uris into strings
    // Given a uri http://www.contoso.com/Chapters/Chapter1/Sections/Section1.htm, Segments is going to be ['/','Chapters/','Chapter1/','Sections/','Section1.htm']
    editRequest.Attachments?.map((attachment) => {
      if (attachment.Uri) {
        if (typeof attachment.Uri !== "string") {
          const newUri = attachment.Uri as {
            AbsoluteUri: string | undefined;
            Segments: string[];
          };
          attachment.Uri = newUri.AbsoluteUri ?? newUri.Segments.join("");
        }
      }
      return attachment;
    }) ?? []
  );
};

export const getObjectDifferences = (obj1: Request, obj2: Request): Request => {
  const diff = _.differenceWith(_.toPairs(obj1), _.toPairs(obj2), (a, b) => {
    if (a[0] === "Attachments") {
      const attachmentsDiff = _.differenceWith(
        a[1] as FileDefinition[],
        b[1] as FileDefinition[],
        (attachment1, attachment2) => {
          return attachment1?.Name === attachment2?.Name && ((attachment1?.Blob?.FileId === attachment2?.Blob?.FileId) || (attachment1?.Uri === attachment2?.Uri))
        }
      );

      return attachmentsDiff.length === 0;
    }

    if (["Name", "Description"].includes(a[0])) return _.isEqual(a[1], b[1]);

    return true;
  });
  const differences = _.fromPairs(diff);

  if (!differences?.Attachments) {
    const obj1AttachmentLength = obj1?.Attachments ? obj1?.Attachments?.length : 0;
    const obj2AttachmentLength = obj2?.Attachments ? obj2?.Attachments?.length : 0;

    if (obj1AttachmentLength < obj2AttachmentLength) {
      differences.Attachments = obj1?.Attachments;
    }
  }

  return differences;
};

// At least 12 characters
// At least 8 unique characters
// Let the server handle the rest
export const validatePasswordQuality = (password: string) : boolean => {
  if (password?.length >= 12 && password.length <= 255) {
    const set = new Set(Array.from(password));
    return set.size >= 8;
  }

  return false;
}

export const checkURIsExist = (
  uploadedFiles: FileDefinition[],
  newFiles: FileDefinition[]
) => {
  const newFileURIs = newFiles.map((file) => file.Uri);
  const allURIsExist = newFileURIs.every((newUri) => {
    return uploadedFiles.some((oldFile) => oldFile.Uri === newUri);
  });
  return allURIsExist;
};

export const checkURIsAreUnique = (newFiles: FileDefinition[]) => {
  const newFileURIs = newFiles.map((file) => file.Uri);
  return newFileURIs.every(
    (newUri, index, self) => self.indexOf(newUri) === index
  );
};

export const guidAreEqual = (guid1: string, guid2: string) => { 
  const guidRegex = /^[0-9a-f]{8}(-?[0-9a-f]{4}){3}-?[0-9a-f]{12}$/i; 

  return guidRegex.test(guid1) && guidRegex.test(guid2) && guid1.split("-").join("") === guid2.split("-").join(""); 
} 

export const getChunks = (file: File | Blob, chunkSize: number) => {
  let start = 0;
  const blobs: Blob[] = [];

  while (start < file.size) {
    blobs.push(file.slice(start, start + chunkSize, file.type));
    start += chunkSize;
  }

  return blobs;
};