import { cloneDeep, forEach, map } from "lodash";
import merge from "lodash/merge";
import { Reducer } from "redux";
import { ProfileType } from "../../abstractions/profileType/ProfileType";
import { User } from "../../abstractions/user/User";
import { UserGroup } from "../../abstractions/user/UserGroup";
import { ActionTypes } from "../enums/ActionTypes";
import { ProfileTypesAction, UserAction, UsersPageAction } from "./actions";

export interface UsersState {
  users: {
    [Id: string]: User;
  };
  userGroups?: {
    [Id: string]: UserGroup;
  };
  count?: number;
  page?: number;
  pageSize?: number;
  profileTypes?: ProfileType[];
  filterQueryList?: string[];
  sortQueryList?: string[];
  searchModel?: string;
  newUser?: User;
}

// Updates account cache in response to unknown action with response
const reducer: Reducer<
  UsersState | undefined,
  UserAction | ProfileTypesAction | UsersPageAction
> = (
  state: UsersState | undefined,
  action: UserAction | ProfileTypesAction | UsersPageAction) => {
  if (state === undefined) {
    return { users: {}, page: 0, pageSize: 10 };
  }

  let returnState = state;

  switch (action.type) {
    case ActionTypes.ProfileSuccess: {
      let newUser;
      forEach(action.response?.entities?.users, (u) => {
        newUser = u as User
      })

      if (newUser) {
        returnState = merge({}, state, {
          newUser: newUser
        });
      }
      break;
    }

    case ActionTypes.UserSuccess: {
      if (
        action.response &&
          (action.response.entities || Array.isArray(action.response))
      ) {
        const stateCopy = cloneDeep(state);
        if (action.refresh) {
          stateCopy.users = {};
        }
        if (action.response["@odata.count"]) {
          stateCopy.count = action.response["@odata.count"] as number;
        }

        const userArray = action.response.entities
          ? action.response.entities.users
          : action.response;

        forEach(userArray, (value) => {
          const user = value as User;
          const userId = user.Id as string;
          stateCopy.users[userId] = user;
        });

        let nUserGroups: string[] = [];

        forEach(stateCopy.users, (user) => {
          const groups = user.Groups as {
            Group?: { Name?: string };
            GroupId?: string;
            Id?: string;
          }[];
          nUserGroups = map(groups, (g) => {
            return g.Id as string;
          });
        });

        returnState = merge({}, stateCopy, {
          users: { ...stateCopy.users },
          userGroups: [...nUserGroups],
          page: action.page,
          // userTeams:
        /*teams: action.response.value?.teams,
            
            ,*/
        });
      }
      break;
    }

    case ActionTypes.UserUpdateRequest: {
      if (action.payload && action.payload.item) {
      // ProfileTypes update type is different than what is returned from the server
      // so we do not optimistically update it
        delete action.payload.item.ProfileTypes;
        returnState = merge({}, state, {
          users: {
            [action.payload.item.Id as string]: action.payload.item,
          },
        });
      }
      break;
    }

    case ActionTypes.UserUpdateFailure: {
      if (action.payload && action.payload.prevItem) {
        returnState = merge({}, state, {
          users: {
            [action.payload.prevItem.Id as string]: action.payload.prevItem,
          },
        });
      }
      break;
    }

    case ActionTypes.ProfileTypesSuccess: {
      if (action.response && action.response.Values) {
        returnState = merge({}, state, {
          profileTypes: action.response.Values,
        });
      }
      break;
    }

    case ActionTypes.UserGroupSuccess: {
      if (
        action.response &&
          action.response.entities &&
          action.response.entities.usergroups
      ) {
        const stateCopy = cloneDeep(state);
        const usergroups = action.response.entities.usergroups as Record<
            string,
            UserGroup
          >;
        forEach(stateCopy.users, (user) => {
          user.Groups = [];
        });
        forEach(usergroups, (usergroup) => {
          const user = stateCopy.users[usergroup.UserId];
          if (user) {
            user.Groups = user.Groups?.concat(usergroup);
          }
        });
        returnState = merge({}, stateCopy, {
          userGroups: action.response.entities.usergroups,
        });
      }
      break;
    }

    case ActionTypes.UserGroupAddSuccess: {
      const stateCopy = cloneDeep(state);
      if (action.response) {
        const user = stateCopy.users[action.response.UserId as string];
        if (typeof user.Groups === "undefined") {
          user.Groups = [];
        }

        const newGroup = {
          GroupId: action.response.GroupId as string,
          Id: action.response.Id as string,
          UserId: user.Id as string,
          Group: { Name: action.payload?.item?.Name as string },
        };
        user.Groups.push(newGroup);
        returnState = merge({}, state, {
          userGroups: {
            [action.response.Id as string]: action.response,
          },
          users: {
            [action.response.UserId as string]: user,
          },
        });
      }
      break;
    }

    case ActionTypes.UserGroupDeleteSuccess: {
      if (action.payload && action.payload.item) {
        const stateCopy = cloneDeep(state);
        const selectedUser =
            stateCopy.users[action.payload.item.UserId as string];
        const groupId = action.payload.item.GroupId as string;
        const groups = selectedUser.Groups;

        const afterRemoved = groups?.filter((group) => {
          const curGroup = group as { GroupId?: string };
          return curGroup.GroupId !== groupId;
        });

        selectedUser.Groups = afterRemoved;
        stateCopy.users[action.payload.item.UserId as string] = selectedUser;
        returnState = merge({}, stateCopy);
      }
      break;
    }

    case ActionTypes.UsersSetPage: {
      const stateCopy = cloneDeep(state);
      if (action.page !== undefined) {
        stateCopy["page"] = action.page;
      }
      if (action.pageSize !== undefined) {
        stateCopy["pageSize"] = action.pageSize;
      }
      if (action.filterQueryList) {
        stateCopy["filterQueryList"] = action.filterQueryList.concat();
      }
      if (action.sortQueryList) {
        stateCopy["sortQueryList"] = action.sortQueryList;
      }
      if (typeof action.searchModel !== "undefined") {
        stateCopy["searchModel"] = action.searchModel;
      }
      returnState = merge({}, stateCopy);
      break;
    }
  }

  return returnState;
};

export default reducer;