// React/Redux Library Imports
import * as React from "react";
import { compose } from "redux";

// Redux-Store Imports
import { FileDefinition } from "../../../abstractions/file/FileDefinition";

// Material-ui Imports
import {
  Container,
  Paper,
  Box,
  Grid,
  Typography,
  createStyles,
  WithStyles,
  withStyles,
  Theme,
  Button,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { checkURIsExist, checkURIsAreUnique } from "helpers/general";
import { TextField } from "@mui/material";
// Component Imports

// Other Imports

const URL_REGEX =
  /https:\/\/(www\.)?[a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/; // eslint-disable-line no-useless-escape
const MAX_URL_LENGTH = 1024;
const addFileInputStyles = () =>
  createStyles({
    root: {
      width: "40%",
    },
    textField: {
      width: "100%",
    },
  });

class AddFileInputComponent extends React.Component<
  {
    name?: string;
    url?: string;
    fileIndex: number;
    updateFile: (index: number, updatedFile: FileDefinition) => void;
    removeFile: (index: number) => void;
    disableRemove?: boolean;
    nameInvalid?: boolean;
    urlInvalid?: boolean;
    urlLenghtValid?: boolean;
  } & WithStyles<typeof addFileInputStyles>
> {
  onChangeFileName = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { url, fileIndex, updateFile } = this.props;
    updateFile(fileIndex, { Name: event.target.value, Uri: url });
  };

  onChangeUrl = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, fileIndex, updateFile } = this.props;
    updateFile(fileIndex, { Name: name, Uri: event.target.value });
  };

  onRemove = () => {
    const { fileIndex, removeFile } = this.props;
    removeFile(fileIndex);
  };

  render() {
    const {
      name,
      url,
      fileIndex,
      disableRemove,
      classes,
      nameInvalid,
      urlInvalid,
      urlLenghtValid,
    } = this.props;

    return (
      <Grid item container>
        <div style={{ width: "100%" }}>
          <TextField
            placeholder="Name"
            onChange={this.onChangeFileName}
            value={name}
            error={nameInvalid}
            id={`file-name-input-${fileIndex}`}
            helperText={
              nameInvalid && "Please enter a name for this attachement"
            }
            style={{ width: "100%", marginBottom: "2%" }}
            size="small"
          />
          <TextField
            placeholder="Web URL"
            onChange={this.onChangeUrl}
            value={url}
            error={urlInvalid || urlLenghtValid}
            helperText={
              (urlInvalid && "Please enter a valid URL") ||
              (urlLenghtValid && "Please enter a URL less than 1024 characters")
            }
            id={`url-input-${fileIndex}`}
            style={{ width: "100%" }}
            size="small"
          />
        </div>
        <Grid item xs={1}>
          {!disableRemove && (
            <IconButton id={`url-remove-${fileIndex}`} onClick={this.onRemove}>
              <CloseIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
    );
  }
}

const AddFileInput = withStyles(addFileInputStyles)(AddFileInputComponent);

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: { padding: 25 },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "&>div": {
        padding: spacing(2),
      },
    },
    buttonIcon: {
      margin: `0 ${spacing(1)}px`,
    },
    textField: {
      width: "100%",
    },
    button: {
      margin: spacing(1),
      height: "32px",
      border: "1px solid black",
    },
    txt: {
      fontFamily: "Roboto, sans-serif"
    }
  });

interface PublicProps {
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onAddFile?: (file: FileDefinition[]) => void;
  files?: FileDefinition[];
}

type AddFileUrlProps = PublicProps & WithStyles<typeof styles>;

interface AddFileUrlState {
  newFiles: FileDefinition[];
  /* When true, will cause error messages to appear for failed validation */
  validateAlert: boolean;
  showURLError: boolean;
}

export class AddFileUrl extends React.Component<
  AddFileUrlProps,
  AddFileUrlState
> {
  constructor(props: AddFileUrlProps) {
    super(props);
    this.state = {
      newFiles: [{ Name: "", Uri: "" }],
      validateAlert: false,
      showURLError: false,
    };
  }

  /* This code is currently commented because we are currently restricting users
   to upload only one file. However, we will need this functionality in the future
   when we intend to allow the uploading of more than one file. */
  // addFile = () => {
  //   const { newFiles } = this.state;
  //   const { files } = this.props;
  //   const filesLength = files
  //     ? files.length + newFiles.length
  //     : newFiles.length;
  //   if (filesLength < 1) {
  //     if (newFiles) {
  //       const updatedNewFiles = newFiles.concat();
  //       updatedNewFiles.push({ Name: "", Uri: "" });
  //       this.setState({ newFiles: updatedNewFiles });
  //     }
  //   }
  // };

  updateFile = (index: number, updatedFile: FileDefinition) => {
    const { newFiles } = this.state;
    if (newFiles) {
      const updatedNewFiles = newFiles.concat();
      updatedNewFiles[index] = updatedFile;
      this.setState({ newFiles: updatedNewFiles, showURLError: false });
    }
  };

  submitFiles = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { onAddFile, onClose, files } = this.props;

    const { newFiles } = this.state;

    if (newFiles) {
      if (
        newFiles.every((file: FileDefinition) => {
          return (
            this.validateName(file) &&
            this.validateUrl(file) &&
            this.validateUrllength(file)
          );
        })
      ) {
        if (files || newFiles) {
          const uniqueUri = checkURIsAreUnique(newFiles);
          const uriExists = files ? checkURIsExist(files, newFiles) : false;

          if (!uriExists && uniqueUri) {
            if (onAddFile) {
              onAddFile(newFiles);
            }
            if (onClose) {
              onClose(event);
            }
            this.setState({
              validateAlert: false,
              showURLError: false,
              newFiles: [{ Name: "", Uri: "" }],
            });
          } else {
            this.setState({ showURLError: true });
          }
        }
      } else {
        if (!this.validateUrllength) {
          this.setState({ validateAlert: true, showURLError: true });
        } else {
          this.setState({ validateAlert: true });
        }
      }
    }
  };

  validateName = (file: FileDefinition) => {
    if (file.Name) {
      const regex = /^\s+$/;
      return !regex.test(file.Name);
    } else {
      return false;
    }
  };

  validateUrl = (file: FileDefinition) => {
    const str = file.Uri as string;
    if (URL_REGEX.test(str)) {
      return true;
    } else {
      return false;
    }
  };

  validateUrllength = (file: FileDefinition) => {
    const link = file.Uri as string;
    if (link.length <= MAX_URL_LENGTH) {
      return true;
    } else {
      return false;
    }
  };

  clearFiles = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { onClose } = this.props;
    this.setState({ newFiles: [{ Name: "", Uri: "" }] });
    if (onClose) {
      onClose(event);
    }
  };

  removeFile = (index: number) => {
    const { newFiles } = this.state;
    if (newFiles && newFiles.length > 1) {
      const updatedNewFiles = newFiles.concat();
      updatedNewFiles.splice(index, 1);
      this.setState({ newFiles: updatedNewFiles });
    }
  };

  public render() {
    const { classes, files } = this.props;
    const { newFiles, validateAlert, showURLError } = this.state;
    const filesLength = files
      ? files.length + newFiles.length
      : newFiles.length;

    const body = (
      <Box className={classes.root}>
        <h5 style={{ fontFamily: "Roboto, sans-serif"}} >Link from web</h5>
        {/* this comented code is required for edit project functionality */}
        {/* {showURLError && (
          <Typography
            variant="subtitle1"
            align="left"
            color="error"
            id="links-error-text"
          >
            {
              "The url you entered has already been added. Please check your entry and try again."
            }
          </Typography>
        )} */}
        <Box className={classes.container}>
          <p className={classes.txt} >
            ⚠️ XStereotype will only store the URL of the third-party file
            instead of uploading a copy to our platform.
          </p>
          <Grid container style={{ padding: 0 }}>
            {newFiles.map((file, index) => {
              const nameValid = this.validateName(file);
              const urlValid = this.validateUrl(file);
              const urlLenghtValid = this.validateUrllength(file);
              return (
                <AddFileInput
                  key={index}
                  name={file.Name}
                  url={file.Uri as string}
                  fileIndex={index}
                  updateFile={this.updateFile}
                  removeFile={this.removeFile}
                  disableRemove={newFiles.length === 1}
                  nameInvalid={validateAlert && !nameValid}
                  urlInvalid={validateAlert && !urlValid}
                  urlLenghtValid={validateAlert && !urlLenghtValid}
                />
              );
            })}
          </Grid>
          {/* This code is currently commented because we are currently restricting
          users to upload only one file. However, we will need this
          functionality in the future when we intend to allow the uploading of
          more than one file. */}
          {/* {filesLength < 1 && (
            <Button
              className={classes.button}
              id="add-more-button"
              onClick={this.addFile}
            >
              Add More
            </Button>
          )} */}
          <Box style={{ width: "100%", padding: 0 }}>
            <Button
              className={classes.button}
              id="cancel-button"
              onClick={this.clearFiles}
              style={{ marginLeft: 0, textTransform: "capitalize" }}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              id="submit-button"
              variant="contained"
              color="primary"
              onClick={this.submitFiles}
            >
              Link file
            </Button>
          </Box>
        </Box>
      </Box>
    );

    return (
      <React.Fragment>
        <Container disableGutters style={{ width: "40%" }}>
          <Paper>{body}</Paper>
        </Container>
      </React.Fragment>
    );
  }
}

export default compose(withStyles(styles))(
  AddFileUrl
) as React.ComponentType<PublicProps>;
