import merge from "lodash/merge";
import { Reducer } from "redux";
import { TwoFactorAction } from "./actions";
import { ActionTypes } from "../enums/ActionTypes";
import { Login2FaModel, TwoFactorModel, TwoFactorModes } from "../../types";
import { isEmpty } from "lodash";

export interface TwoFactorState {
  Code: string;
  Remember: boolean;
  showVerificationForm: boolean;
  Login2FaModel?: Login2FaModel;
  TwoFactorModel?: TwoFactorModel;
  requiresSetup?: boolean;
  showSetup?: boolean;
  showSharedKey?: boolean;
  showRecoveryCodes?: boolean;
  verificationCompleted?: boolean;
  errorMessage?: string;
  showError?: boolean;
  showVerificationCode?: boolean;
  verificationCodeError?: string;
}

// Updates account cache in response to unknown action with response.account.
const reducer: Reducer<TwoFactorState | undefined, TwoFactorAction> = (
  state: TwoFactorState | undefined,
  action: TwoFactorAction
) => {
  let returnState = state;
  const initialState = {
    Code: "",
    Remember: false,
    showVerificationForm: false,
    showVerificationCode: false,
    verificationCodeError: ""
  } as TwoFactorState;
  if (state === undefined) {
    returnState = initialState;
  }

  switch (action.type) {
    case ActionTypes.TwoFactorClear:
      returnState = merge({}, returnState, {
        TwoFactorModel: merge({}, returnState?.TwoFactorModel, {
          SharedKeyUri: "",
          SharedKey: "",
          RecoveryCodes: [],
        }),
        showError: false,
        errorMessage: "",
      });
      break;
    case ActionTypes.TwoFactorRecoveryCodeSuccess: {
      returnState = merge({}, returnState, {
        Login2FaModel: action.response,
        TwoFactorModel: undefined,
        showVerificationForm: false,
        requiresSetup: false,
        verificationCompleted: true,
        showError: false,
        errorMessage: "",
      }) as TwoFactorState;
      break
    }
    case ActionTypes.TwoFactorRecoveryCodeFailure: {
      returnState = merge({}, returnState, {
        showError: true,
        errorMessage: action?.error ? action?.error : "Something has gone wrong",
      }) as TwoFactorState;
      break
    }
    case ActionTypes.Login2FaModelSuccess:
      returnState = merge({}, returnState, {
        Login2FaModel: action.response,
        TwoFactorModel: undefined,
        showVerificationForm: true,
        requiresSetup: false,
        showError: false,
        errorMessage: "",
      }) as TwoFactorState;
      break;
    case ActionTypes.Login2FaSuccess:
      returnState = merge({}, returnState, {
        Login2FaModel: action.response,
        TwoFactorModel: undefined,
        showVerificationForm: false,
        requiresSetup: false,
        verificationCompleted: true,
        showError: false,
        errorMessage: "",
      }) as TwoFactorState;
      break;
    case ActionTypes.Login2FaModelFailure:
      returnState = merge({}, returnState, {
        TwoFactorModel: undefined,
        showVerificationForm: true,
        requiresSetup: false,
        verificationCompleted: false,
        showError: false,
        errorMessage: "",
      }) as TwoFactorState;
      break;
    case ActionTypes.Login2FaFailure:
      returnState = merge({}, returnState, {
        showError: true,
        errorMessage: action.error ? action.error : "Something has gone wrong",
      });
      break;
    case ActionTypes.TwoFactorModelSuccess:
      if (action.response) {
        returnState = merge({}, returnState, {
          Login2FaModel: undefined,
          TwoFactorModel: action.response,
          requiresSetup:
            action.response.Required && !action.response.HasAuthenticator,
          showSetup:
            (action.response.Required && !action.response.HasAuthenticator) ||
            state?.showSetup,
          showVerificationForm: false,
        }) as TwoFactorState;
      }
      break;
    case ActionTypes.TwoFactorEnableSuccess:
      if (!isEmpty(action.response)) {
        returnState = merge({}, returnState, {
          Login2FaModel: undefined,
          TwoFactorModel: action.response,
          requiresSetup: true,
          showSetup: true,
          showVerificationForm: true,
        }) as TwoFactorState;
      } else if (action.payload && action.payload.item) {
        returnState = merge({}, returnState, {
          Login2FaModel: undefined,
          TwoFactorModel: merge({}, state?.TwoFactorModel, {
            Authenticator: action.payload.item.Authenticator,
          }),
          requiresSetup: true,
          showSetup: true,
          showVerificationForm: true,
        }) as TwoFactorState;
      }
      break;
    case ActionTypes.TwoFactorConfirmSuccess:
      if (
        action.response &&
        !isEmpty(action.response) &&
        action.payload &&
        action.payload.item
      ) {
        if (action.payload.item.Authenticator == TwoFactorModes.Authenticator) {
          returnState = merge({}, returnState, {
            Login2FaModel: undefined,
            TwoFactorModel: action.response,
            showVerificationForm: false,
            verificationCompleted: false,
            showSetup: true,
            showRecoveryCodes: true,
          }) as TwoFactorState;
        } else if (action.payload.item.Authenticator == TwoFactorModes.Email) {
          returnState = merge({}, returnState, {
            Login2FaModel: undefined,
            TwoFactorModel: merge({}, state?.TwoFactorModel, {
              Authenticator: action.payload.item.Authenticator,
              HasAuthenticator: true,
            }),
            showVerificationForm: false,
            verificationCompleted: true,
            showSetup: false,
            showRecoveryCodes: false,
          }) as TwoFactorState;
        }
      }
      break;
    case ActionTypes.TwoFactorContinueToApp:
      returnState = merge({}, returnState, {
        verificationCompleted: true,
        showRecoveryCodes: false,
        showSetup: false,
      }) as TwoFactorState;
      break;
    case ActionTypes.TwoFactorToggleSharedKey:
      returnState = merge({}, returnState, {
        showSharedKey: action?.showSharedKey,
      }) as TwoFactorState;
      break;
    case ActionTypes.TwoFactorChangeSetup:
      returnState = merge({}, returnState, {
        showSetup: action?.payload?.item?.enable,
      }) as TwoFactorState;
      break;
    case ActionTypes.TwoFactorToggleShowVerificationForm:
      returnState = merge({}, returnState, {
        showVerificationForm: action?.showVerificationForm,
      }) as TwoFactorState;
      break;
  }

  return returnState;
};

export default reducer;
