// React/Redux Library Imports
import * as React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

// Redux-Store Imports
import { ApplicationState } from "../../state";
import { HasPolicy, hasPolicySelector } from "../../state/account/selectors";
import {
  HasModule,
  hasModuleSelector,
} from "../../state/appsettings/selectors";

interface PublicProps {
  /** string array of required policies */
  requiredPolicies?: string[];
  /** string representing how required policies will be checked
   * AND - all policies passed to required policies are needed,
   * OR - one policy passed to required policies is needed */
  policyComparison?: "AND" | "OR";
  /** string array of require modules, always requires all modules */
  requiredModules?: string[];
}

interface StateProps {
  hasPolicy: HasPolicy;
  hasModule: HasModule;
}

type IRestrictedProps = PublicProps & StateProps;

class Restricted extends React.PureComponent<IRestrictedProps> {
  static defaultProps: PublicProps = {
    requiredPolicies: [],
    requiredModules: [],
    policyComparison: "AND",
  };

  render() {
    const {
      children,
      hasModule,
      hasPolicy,
      requiredPolicies,
      requiredModules,
      policyComparison,
    } = this.props;

    let moduleMatch = true;
    if (requiredModules && !isEmpty(requiredModules)) {
      if (requiredModules.every((module) => hasModule(module))) {
        moduleMatch = true;
      } else {
        moduleMatch = false;
      }
    }

    let policyMatch = true;
    if (requiredPolicies && !isEmpty(requiredPolicies)) {
      if (
        policyComparison === "AND" &&
        requiredPolicies.every((policy) => hasPolicy(policy))
      ) {
        policyMatch = true;
      } else if (
        policyComparison === "OR" &&
        requiredPolicies.some((policy) => hasPolicy(policy))
      ) {
        policyMatch = true;
      } else {
        policyMatch = false;
      }
    }

    if (moduleMatch && policyMatch) {
      return children;
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  hasModule: hasModuleSelector(state),
  hasPolicy: hasPolicySelector(state),
});

export default connect(
  mapStateToProps,
  {}
)(Restricted) as React.ComponentType<PublicProps>;
