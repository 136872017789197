export interface ApiError {
  Keys?: Record<string, string>;
  Values?: Record<string, string>;
  Id?: string;
  Localized?: boolean;
  Message?: string;
  Name?: string;
}

export class RequestFailedError extends Error {
  constructor(
    message?: string,
    options?: {
      cause?: number
    }
  ) {
    super(message);
    if (options && options.cause) {
      this.cause = options.cause;
    }
    return this;
  }
  cause?: number;
}

export enum ErrorStr {
  Default = "Something went wrong",

  AllUserDashboardsUpdateFailure = "We were not able to update your dashboards",
  UserDashboardsUpdateFailure = "We were not able to update your dashboards",
  UserDashboardsDeleteFailure = "We were not able to delete your dashboard",
  UserDashboardsFailure = "We were not able to load your dashboards",
  UserDashboardsCreateFailure = "We were not able to create your dashboard",
  UserDashboardCardFailure = "We were not able to load your dashboard data",
}

export enum IdentityProviders {
  // The default authentication type.
  None = 0,
  // The default authentication type.
  Default = 1 << 0,
  // Authentication by magic link via email.
  Passwordless = 1 << 1,
  // Azure Active Directory SSO authentication.
  AzureAD = 1 << 2,
  // Okta authentication.
  Okta = 1 << 3,
}

export enum AuthStatus {
  Authorized = "AUTHORIZED",
  Unauthorized = "UNAUTHORIZED",
  LoggedOut = "LOGGED_OUT",
  Processing = "PROCESSING",
  RequiresTwoFactor = "REQUIRES_TWO_FACTOR",
  ForceTwoFactor = "FORCE_TWO_FACTOR",
  NotAllowed = "NOT_ALLOWED",
  LockedOut = "LOCKED_OUT",
  PasswordLessAuthorized = "PASSWORDLESS_AUTHORIZED",
}

export const LockedOutLoginError =
  "Your account has been locked. Please contact your administrator to resolve this issue.";

export interface LoginResult extends Record<string, unknown> {
  Succeeded: boolean;
  IsLockedOut: boolean;
  IsNotAllowed: boolean;
  RequiresTwoFactor: boolean;
  ForceTwoFactor: boolean;
  Error?: {
    Message?: string;
    Name?: string;
  };
  Authenticator:string;
}

export const NotAllowedLoginError =
  "You do not have access to this application.";

export enum TwoFactorModes {
  Email = "Email",
  Authenticator = "Authenticator",
}

export interface UserCredentials extends Record<string, unknown> {
  Email: string;
  Password: string;
  Remember: boolean;
}

export enum MultiFactorAuthentication {
  Disabled = "Disabled",
  Optional = "Optional",
  Required = "Required",
}

export enum Policies {
  TenantAdministrators = "TenantAdministrators",
  DashboardAdministrators = "DashboardAdministrators",
  DashboardEditors = "DashboardEditors",
  DocumentAdmininistrators = "DocumentAdmininistrators",
  NetworkAdministrators = "NetworkAdministrators",
  AttributeAdministrators = "AttributeAdministrators",
  GroupAdministrators = "GroupAdministrators",
  GroupManagers = "GroupManagers",
  TeamAdministrators = "TeamAdministrators",
  TeamManagers = "TeamManagers",
  UserAdministrators = "UserAdministrators",
  UserGroupsAdministrators = "UserGroupsAdministrators",
  UserTeamAdministrators = "UserTeamAdministrators",
  RequestAdministrators = "RequestAdministrators",
  RequestEditors = "RequestEditors",
  SubmissionAdministrators = "SubmissionAdministrators",
  SubmissionViewers = "SubmissionViewers",
  PermissionAdministrators = "PermissionAdministrators",
  PermissionManagers = "PermissionManagers",
}

export interface Login2FaModel extends Record<string, unknown> {
  Code: string;
  IsPersistent: boolean;
  Authenticator: TwoFactorModes;
}

export interface TwoFactorModel extends Record<string, unknown> {
  // Gets or sets a value indicating TwoFactorMode of user.
  Authenticator?: TwoFactorModes;
  // Gets or sets the authenticator uri.
  SharedKeyUri?: string;
  // Gets or sets a value indicating whether the user has (or wants) two factor enabled.
  Enabled: boolean;
  // Gets or sets user generated code.
  GeneratedCode: string;
  // Gets or sets if user has authenticator app.
  HasAuthenticator: boolean;
  // Gets or sets the recovery codes.
  RecoveryCodes?: string[];
  // Gets or sets the number of recovery codes the user has.
  RecoveryCodesLeft: number;
  // Gets or sets a value indicating whether the has (or wants) their two factor remembered.
  Remembered: boolean;
  // Gets or sets a value indicating whether the user is required by the tenancy to have 2fa.
  Required: boolean;
  // Gets or sets a value indicating whether the result should return a view or response only.
  ResponseOnly?: boolean;
  // Gets or sets the pre-shared key.
  SharedKey?: string;
}

export enum Modules {
  All = "All",
  Proposal = "Proposal",
  Staffing = "Staffing",
  Survey = "Survey",
  Chemistry = "Chemistry",
  Insights = "Insights",
}
