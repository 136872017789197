import { Action } from "redux";
import { CallApiAction } from "../shared/middleware/api";
import { ActionTypes } from "../enums/ActionTypes";
import { AppThunkAction } from "..";
import { Endpoints } from "../enums/Endpoints";
import { Request } from "../../abstractions/request/Request";
import { requestSchema } from "../shared/schemas/requestSchema";
import { getRequestQuery } from "./selectors";
import { cloneDeep } from "lodash";

export interface InsightRequestAction extends CallApiAction {
  type:
    | typeof ActionTypes.InsightRequestCountRequest
    | typeof ActionTypes.InsightRequestCountSuccess
    | typeof ActionTypes.InsightRequestCountFailure
    | typeof ActionTypes.InsightRequestRequest
    | typeof ActionTypes.InsightRequestSuccess
    | typeof ActionTypes.InsightRequestFailure
    | typeof ActionTypes.InsightRequestSelect
    | typeof ActionTypes.InsightRequestsRequest
    | typeof ActionTypes.InsightRequestsSuccess
    | typeof ActionTypes.InsightRequestsFailure
    | typeof ActionTypes.InsightRequestCloseRequest
    | typeof ActionTypes.InsightRequestCloseSuccess
    | typeof ActionTypes.InsightRequestCloseFailure
    | typeof ActionTypes.InsightRequestCreateRequest
    | typeof ActionTypes.InsightRequestCreateSuccess
    | typeof ActionTypes.InsightRequestCreateFailure
    | typeof ActionTypes.InsightRequestCreateDraftRequest
    | typeof ActionTypes.InsightRequestCreateDraftSuccess
    | typeof ActionTypes.InsightRequestCreateDraftFailure
    | typeof ActionTypes.InsightRequestFinalizeDraftRequest
    | typeof ActionTypes.InsightRequestFinalizeDraftSuccess
    | typeof ActionTypes.InsightRequestFinalizeDraftFailure
    | typeof ActionTypes.InsightRequestEditRequest
    | typeof ActionTypes.InsightRequestEditSuccess
    | typeof ActionTypes.InsightRequestEditFailure
    | typeof ActionTypes.InsightTypesRequest
    | typeof ActionTypes.InsightTypesSuccess
    | typeof ActionTypes.InsightTypesFailure
    | typeof ActionTypes.InsightRequestResetPaymentState
    | typeof ActionTypes.InsightRequestStartChargeRequest
    | typeof ActionTypes.InsightRequestStartChargeSuccess
    | typeof ActionTypes.InsightRequestStartChargeFailure
    | typeof ActionTypes.InsightRequestsStatusUpdate
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.PaymentIntentSuccess
    | typeof ActionTypes.PaymentIntentFailure
    | typeof ActionTypes.InsightRequestResetState
    | typeof ActionTypes.EnterpriseRequest
    | typeof ActionTypes.EnterpriseSuccess
    | typeof ActionTypes.EnterpriseFailure
    | typeof ActionTypes.None;
  response?: {
    entities?: {
      request?: Record<string, Request>;
    };
    [name: string]: unknown;
  };
  refresh?: boolean;
  page?: number;
  error?: string;
  status?: number;
}

export interface InsightRequestCompanyAction extends Action {
  type: typeof ActionTypes.InsightRequestSetCompany | typeof ActionTypes.None;
  companyId: string;
}

export interface InsightRequestClearErrorAction extends Action {
  type: typeof ActionTypes.InsightRequestsClearError | typeof ActionTypes.None;
}

export interface InsightRequestChangePageAction extends Action {
  type: typeof ActionTypes.InsightRequestChangePage | typeof ActionTypes.None;
  page?: number;
  pageSize?: number;
  filterQueryList?: string[];
  sortQueryList?: string[];
  searchModel?: string;
}

// Sets the selected company id
const setInsightRequestCompanyId = (id: string) => ({
  type: ActionTypes.InsightRequestSetCompany,
  companyId: id,
});

export const changeInsightRequestCompany =
  (id: string): AppThunkAction<Action> =>
    (dispatch) => {
      return dispatch(setInsightRequestCompanyId(id));
    };

// Sets the selected InsightRequest
const setSelectedRequestId = (id: string) => ({
  type: ActionTypes.InsightRequestSelect,
  payload: {
    item: {
      id,
    },
  },
});

export const selectInsightRequest =
  (id: string): AppThunkAction<Action> =>
    (dispatch) => {
      return dispatch(setSelectedRequestId(id));
    };

// Clears the InsightRequest errorMessage
const clearErrorMessage = () => ({
  type: ActionTypes.InsightRequestsClearError,
});

export const clearInsightRequestError =
  (): AppThunkAction<Action> => (dispatch) => {
    return dispatch(clearErrorMessage());
  };

// Changes insightRequest page, pageSize, filter query list,
// sort query list, or search model
const setInsightRequestPage = (
  page?: number,
  pageSize?: number,
  filterQueryList?: string[],
  sortQueryList?: string[],
  searchModel?: string
) => ({
  type: ActionTypes.InsightRequestChangePage,
  page,
  pageSize,
  filterQueryList,
  sortQueryList,
  searchModel,
});

export const changeInsightRequestPage =
  (
    page?: number,
    pageSize?: number,
    filterQueryList?: string[],
    sortQueryList?: string[],
    searchModel?: string
  ): AppThunkAction<Action> =>
    (dispatch, getState) => {
      return dispatch(
        setInsightRequestPage(
          page !== undefined ? page : getState().insightRequest?.page,
          pageSize !== undefined ? pageSize : getState().insightRequest?.pageSize,
          filterQueryList !== undefined
            ? filterQueryList
            : getState().insightRequest?.filterQueryList,
          sortQueryList !== undefined
            ? sortQueryList
            : getState().insightRequest?.sortQueryList,
          searchModel !== undefined
            ? searchModel
            : getState().insightRequest?.searchModel
        )
      );
    };

// Fetches InsightTypes from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchInsightTypes = (): InsightRequestAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.InsightTypesRequest,
      ActionTypes.InsightTypesSuccess,
      ActionTypes.InsightTypesFailure,
    ],
    endpoint: `${Endpoints.Insights}/Types`,
  },
  type: ActionTypes.CallAPI,
});

/**
 * @function loadInsightTypes
 * @returns a InsightRequestAction promise to load the insight types into state from the server
 */
export const loadInsightTypes =
  (): AppThunkAction<InsightRequestAction> => (dispatch) => {
    return dispatch(fetchInsightTypes());
  };

// Fetches InsightRequest from API based on Request Id
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchInsightRequest = (requestId: string): InsightRequestAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.InsightRequestRequest,
      ActionTypes.InsightRequestSuccess,
      ActionTypes.InsightRequestFailure,
    ],
    endpoint: `${Endpoints.Requests}/${requestId}?$expand=Attachments($expand=Blob,Uri),creatoruser`,
  },
  type: ActionTypes.CallAPI,
});

/**
 * @function loadInsightRequest
 * @param requestId {string} id of the request to be loaded
 * @returns a InsightRequestAction promise to load the survey request into state from the server
 */
export const loadInsightRequest =
  (requestId: string): AppThunkAction<InsightRequestAction> =>
    (dispatch) => {
      return dispatch(fetchInsightRequest(requestId));
    };

// Fetches InsightRequest count from API based on query
const fetchInsightRequestCount = (): InsightRequestAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.InsightRequestCountRequest,
      ActionTypes.InsightRequestCountSuccess,
      ActionTypes.InsightRequestCountFailure,
    ],
    endpoint: `${Endpoints.Requests}/$count`,
  },
  type: ActionTypes.CallAPI,
});

/**
 * @function loadInsightRequestCount
 * @param query {string} odata query defining request count to be loaded
 * @returns a InsightRequestAction promise to load the request count into state from the server
 */
export const loadInsightRequestCount =
  (
    page?: number,
    pageSize?: number,
    filterQueryList?: string[],
    sortQueryList?: string[],
    searchModel?: string
  ): AppThunkAction<InsightRequestAction> =>
    (dispatch, getState) => {
      const loadFilterQueryList =
      typeof filterQueryList !== "undefined"
        ? filterQueryList
        : getState().insightRequest?.filterQueryList;
      const loadSortQueryList = sortQueryList
        ? sortQueryList
        : getState().insightRequest?.sortQueryList;
      const loadSearchModel =
      typeof searchModel !== "undefined"
        ? searchModel
        : getState().insightRequest?.searchModel;
      const query = getRequestQuery(
        undefined,
        undefined,
        loadFilterQueryList,
        loadSortQueryList,
        loadSearchModel
      );
      return dispatch(fetchInsightRequestCount());
    };

// Fetches InsightRequests from API based on query
const fetchInsightRequests = (
  page?: number,
  query?: string,
  refresh = true
): InsightRequestAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.InsightRequestsRequest,
      ActionTypes.InsightRequestsSuccess,
      ActionTypes.InsightRequestsFailure,
    ],
    endpoint: `${Endpoints.Requests}${query ? query : ""}`,
    schema: [requestSchema],
  },
  refresh,
  page,
  type: ActionTypes.CallAPI,
});

/**
 * @function loadAllInsightRequests
 * @param query {string} odata query defining survey requests to be loaded
 * @returns a InsightRequestAction promise to load the survey requests into state from the server
 */
export const loadAllInsightRequests =
  (
    page?: number,
    pageSize?: number,
    filterQueryList?: string[],
    sortQueryList?: string[],
    searchModel?: string,
    refresh?: boolean,
    attachments?: boolean
  ): AppThunkAction<InsightRequestAction> =>
    (dispatch, getState) => {
      const loadPage = page != undefined ? page : getState().insightRequest?.page;
      const loadPageSize = pageSize
        ? pageSize
        : getState().insightRequest?.pageSize;
      const loadFilterQueryList =
      typeof filterQueryList !== "undefined"
        ? filterQueryList
        : getState().insightRequest?.filterQueryList;
      const loadSortQueryList = sortQueryList
        ? sortQueryList
        : getState().insightRequest?.sortQueryList;
      const loadSearchModel =
      typeof searchModel !== "undefined"
        ? searchModel
        : getState().insightRequest?.searchModel;
      const query = getRequestQuery(
        loadPage,
        loadPageSize,
        loadFilterQueryList,
        loadSortQueryList,
        loadSearchModel,
        attachments
      );
      return dispatch(fetchInsightRequests(loadPage, query, refresh));
    };

// Creates a draft InsightRequest with Status Incomplete
// Relies on the custom API middleware defined in ../middleware/api.js.
const postInsightRequestDraft = (request: Request): InsightRequestAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.InsightRequestCreateDraftRequest,
      ActionTypes.InsightRequestCreateDraftSuccess,
      ActionTypes.InsightRequestCreateDraftFailure,
    ],
    endpoint: `${Endpoints.Requests}/Draft`,
    options: {
      method: "POST",
      body: JSON.stringify(request),
    },
  },
  payload: {
    item: request as unknown as Record<string, unknown>,
  },
  type: ActionTypes.CallAPI,
});

/**
 * @function createInsightRequestDraft
 * @param request {Request} a request to be created on the server as a draft
 * @returns a InsightRequestAction promise to create the survey request draft
 */
export const createInsightRequestDraft =
  (insightRequest: Request): AppThunkAction<InsightRequestAction> =>
    (dispatch) => {
      const request = cloneDeep(insightRequest);
      delete request.CreatorUser;
      return dispatch(postInsightRequestDraft(request));
    };

// Finalizes a Draft Insight Request
// Relies on the custom API middleware defined in ../middleware/api.js.
const patchFinalizeInsightRequestDraft = (
  request: Request
): InsightRequestAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.InsightRequestFinalizeDraftRequest,
      ActionTypes.InsightRequestFinalizeDraftSuccess,
      ActionTypes.InsightRequestFinalizeDraftFailure,
    ],
    endpoint: `${Endpoints.Requests}/${request.Id as string}`,
    options: {
      method: "POST",
    },
  },
  payload: {
    item: request as unknown as Record<string, unknown>,
  },
  type: ActionTypes.CallAPI,
});

/**
 * @function finalizeInsightRequestDraft
 * @param request {Request} a request draft to be finalized
 * @returns a InsightRequestAction promise to finalize the survey request draft
 */
export const finalizeInsightRequestDraft =
  (request: Request): AppThunkAction<InsightRequestAction> =>
    (dispatch) => {
      return dispatch(patchFinalizeInsightRequestDraft(request));
    };

// Creates InsightRequest
// Relies on the custom API middleware defined in ../middleware/api.js.
const postInsightRequest = (request: Request): InsightRequestAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.InsightRequestCreateRequest,
      ActionTypes.InsightRequestCreateSuccess,
      ActionTypes.InsightRequestCreateFailure,
    ],
    endpoint: `${Endpoints.Requests}`,
    options: {
      method: "POST",
      body: JSON.stringify(request),
    },
  },
  payload: {
    item: request as unknown as Record<string, unknown>,
  },
  type: ActionTypes.CallAPI,
});

/**
 * @function createInsightRequest
 * @param request {Request} a request to be created on the server
 * @returns a InsightRequestAction promise to create the survey request
 */
export const createInsightRequest =
  (insightRequest: Request): AppThunkAction<InsightRequestAction> =>
    (dispatch) => {
      const request = cloneDeep(insightRequest);
      delete request.CreatorUser;
      return dispatch(postInsightRequest(request));
    };

// Patches InsightRequest
// Relies on the custom API middleware defined in ../middleware/api.js.
const patchInsightRequest = (
  request: Request,
  draft?: boolean
): InsightRequestAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.InsightRequestEditRequest,
      ActionTypes.InsightRequestEditSuccess,
      ActionTypes.InsightRequestEditFailure,
    ],
    endpoint: `${Endpoints.Requests}/${request.Id as string}${
      draft ? "/Draft" : ""
    }`,
    options: {
      method: "PATCH",
      body: JSON.stringify(request),
    },
  },
  payload: {
    item: request as unknown as Record<string, unknown>,
  },
  type: ActionTypes.CallAPI,
});

/**
 * @function editInsightRequest
 * @param request {Request} a request to be edited on the server
 * @returns a InsightRequestAction promise to patch the survey request
 */
export const editInsightRequest =
  (
    insightRequest: Request,
    draft?: boolean
  ): AppThunkAction<InsightRequestAction> =>
    (dispatch) => {
      const request = cloneDeep(insightRequest);
      delete request.CreatorUser;
      delete request.State;
      delete request.Tags;
      delete request.CreatorUserId;
      delete request.CreationTime;
      return dispatch(patchInsightRequest(request, draft));
    };

// Marks a InsightRequest as closed
// Relies on the custom API middleware defined in ../middleware/api.js.
const postCloseRequest = (requestId: string): InsightRequestAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.InsightRequestCloseRequest,
      ActionTypes.InsightRequestCloseSuccess,
      ActionTypes.InsightRequestCloseFailure,
    ],
    endpoint: `${Endpoints.Requests}/${requestId}/Complete`,
    options: {
      method: "POST",
    },
  },
  payload: {
    item: {
      requestId,
    },
  },
  type: ActionTypes.CallAPI,
});

/**
 * @function closeInsightRequest
 * @param requestId {string} the id of a request to be closed on the server
 * @returns a InsightRequestAction promise to close the request
 */
export const closeInsightRequest =
  (requestId: string): AppThunkAction<InsightRequestAction> =>
    (dispatch) => {
      return dispatch(postCloseRequest(requestId));
    };

// Starts the process of charging for an insight request
// Relies on the custom API middleware defined in ../middleware/api.js.
const postChargeRequest = (requestId: string): InsightRequestAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.InsightRequestStartChargeRequest,
      ActionTypes.InsightRequestStartChargeSuccess,
      ActionTypes.InsightRequestStartChargeFailure,
    ],
    endpoint: `${Endpoints.Requests}/${requestId}/Charge`,
    options: {
      method: "POST",
    },
  },
  payload: {
    item: {
      requestId,
    },
  },
  type: ActionTypes.CallAPI,
});

/**
 * @function startChargeRequest
 * @param requestId {string} the id of a request to start charging process for
 * @returns a InsightRequestAction promise to return charge api info
 */
export const startChargeRequest =
  (requestId: string): AppThunkAction<InsightRequestAction> =>
    (dispatch) => {
      return dispatch(postChargeRequest(requestId));
    };

// Resets request payment state
const resetPaymentState = (): InsightRequestAction => ({
  type: ActionTypes.InsightRequestResetPaymentState,
});

/**
 * @function resetInsightPaymentState
 * @returns a InsightRequestAction promise to reset the payment state
 */
export const resetInsightPaymentState =
  (): AppThunkAction<InsightRequestAction> => (dispatch) => {
    return dispatch(resetPaymentState());
  };

const setUpdateRequestsStatus = (request: Request) => ({
  type: ActionTypes.InsightRequestsStatusUpdate,
  payload: {
    item: {
      request,
    },
  },
});

const setPaymentIntent = (requestId: string): InsightRequestAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.PaymentIntentRequest,
      ActionTypes.PaymentIntentSuccess,
      ActionTypes.PaymentIntentFailure,
    ],
    endpoint: `${Endpoints.Requests}/${requestId}/Charge/internal`,
    options: {
      method: "POST",
    },
  },
  payload: {
    item: {
      requestId,
    },
  },
  type: ActionTypes.CallAPI,
});
export const updateRequestsStatus =
  (insightRequest: Request): AppThunkAction<Action> =>
    (dispatch) => {
      return dispatch(setUpdateRequestsStatus(insightRequest));
    };

export const makeIntent =
  (insightId: string): AppThunkAction<Action> =>
    (dispatch) => {
      return dispatch(setPaymentIntent(insightId));
    };

const resetRequestState = (): InsightRequestAction => ({
  type: ActionTypes.InsightRequestResetState,
});

/**
 * @function resetInsightRequestsState
 * @returns a InsightRequestAction promise to reset the request state
 */
export const resetInsightRequestsState =
  (): AppThunkAction<InsightRequestAction> => (dispatch) => {
    return dispatch(resetRequestState());
  };

// Fetches Enterprise client from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchEnterpriseClient = (): InsightRequestAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.EnterpriseRequest,
      ActionTypes.EnterpriseSuccess,
      ActionTypes.EnterpriseFailure,
    ],
    endpoint: Endpoints.IsEnterpriseClient,
    useMy: true,
  },
  type: ActionTypes.CallAPI,
});

/**
 * @function loadEnterpriseClient
 * @returns a InsightRequestAction promise to load the insight types into state from the server
 */
export const loadEnterpriseClient =
  (): AppThunkAction<InsightRequestAction> => (dispatch) => {
    return dispatch(fetchEnterpriseClient());
  };

export interface InsightRequestActionCreators {
  changeInsightRequestPage: typeof changeInsightRequestPage;
  loadInsightRequest: typeof loadInsightRequest;
  loadInsightRequestCount: typeof loadInsightRequestCount;
  createInsightRequest: typeof createInsightRequest;
  createInsightRequestDraft: typeof createInsightRequestDraft;
  finalizeInsightRequestDraft: typeof finalizeInsightRequestDraft;
  editInsightRequest: typeof editInsightRequest;
  changeInsightRequestCompany: typeof changeInsightRequestCompany;
  selectInsightRequest: typeof selectInsightRequest;
  clearInsightRequestError: typeof clearInsightRequestError;
  closeInsightRequest: typeof closeInsightRequest;
  loadInsightTypes: typeof loadInsightTypes;
  startChargeRequest: typeof startChargeRequest;
  resetInsightPaymentState: typeof resetInsightPaymentState;
  updateRequestsStatus: typeof updateRequestsStatus;
  makeIntent: typeof makeIntent;
  resetInsightRequestsState: typeof resetInsightRequestsState;
  loadEnterpriseClient : typeof loadEnterpriseClient
}
