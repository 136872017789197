import { schema } from "normalizr";

export const group = new schema.Entity(
  "group",
  {
  },
  {
    idAttribute: (group: Record<string, string>) => group.Id,
  }
);

export const userGroupSchema = new schema.Entity(
  "usergroups",
  {
    Group: group
  },
  {
    idAttribute: (userGroup: Record<string, string>) => userGroup.Id,
  }
);
