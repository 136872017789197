export enum RequestStatus {
  Draft = "Draft",
  Created = "Created",
  Validating = "Validating",
  Validated = "Validated",
  Processing = "Processing",
  Processed = "Processed",
  Finalizing = "Finalizing",
  Finalized = "Finalized",
  Open = "Open",
  Closed = "Closed",
  Completing = "Completing",
  Completed = "Completed",
  Canceled = "Canceled",
  Failed = "Failed",
  NeedsIntervention = "NeedsIntervention",
}
