// React/Redux Library Imports
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { ReactCookieProps, withCookies } from "react-cookie";

// Material-UI Imports
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  Link,
  Paper,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { Button } from "@material-ui/core";

// Redux Store Imports
import actions from "../../state/actions";
import { ApplicationState, DispatchThunkAction } from "../../state";
import { AppSettingsState } from "../../state/appsettings";
import { AuthenticationState } from "../../state/authentication";

// Other Imports
import { IdentityProviders, AuthStatus } from "../../types";
import backdrop from "../../assets/images/Hero-Pattern.png";
import { makeLoadingSelector } from "state/loading/selectors";
import { ActionTypes } from "state/enums/ActionTypes";
import "./Logout.css";

// Generating css styles for the component
const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "nowrap",
      margin: `${theme.spacing(0)} auto`,
    },
    logoutBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
      padding: `6px 16px`,
      height: "unset",
      background: "rgb(93,28,212)",
    },
    header: {
      textAlign: "center",
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    card: {
      marginTop: theme.spacing(10),
    },
    root: {
      height: "100vh",
    },
    image: {
      backgroundImage: `url(${backdrop})`,
      backgroundRepeat: "no-repeat",
      backgroundColor: "#210c51",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      padding: `6px 16px`,
      height: "unset",
    },
    loadingCircleContainer: {
      paddingTop: 30,
      paddingBottom: 30,
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
    version: {
      fontSize: 11,
      textAlign: "center",
    },
  });

// Deconstructed actions
const { logoutUser } = actions;

interface DispatchProps {
  logoutUserConnect: DispatchThunkAction<typeof logoutUser>;
}

// StateProps
interface StateProps {
  appsettings?: AppSettingsState;
  authentication?: AuthenticationState;
  loading?: boolean;
}

type LogoutProps = StateProps &
DispatchProps &
ReactCookieProps &
WithStyles<typeof styles>;

interface LogoutState {
  loggedOut: boolean;
  showError: boolean;
  errorMessage: string;
  identityProviders?: IdentityProviders;
}

class Logout extends React.PureComponent<LogoutProps, LogoutState> {
  constructor(props: LogoutProps) {
    super(props);
    this.state = {
      loggedOut: false,
      showError: false,
      errorMessage: "",
    };
  }

  static getDerivedStateFromProps(
    nextProps: LogoutProps,
    prevState: LogoutState
  ) {
    // invoked right before calling the render method, both on the initial mount and on subsequent updates
    // return an object to update the state, or null to update nothing.
    const { loggedOut } = prevState;
    const { appsettings, authentication } = nextProps;
    const identityProviders = appsettings?.IdentityProviders;
    const errorMessage = authentication?.errorMessage;
    const updatedLoggedOut =
      authentication?.authenticationStatus == AuthStatus.Unauthorized &&
      !loggedOut;
    return {
      loggedOut: updatedLoggedOut,
      identityProviders,
      errorMessage,
      showError: !errorMessage ? false : true,
    };
  }

  componentDidMount() {
    void this.props.logoutUserConnect();
  }

  handleCloseClick = () => {
    if (window) {
      window.location.assign(`${process.env.PUBLIC_URL}/login`);
    }
  };

  renderMessage(showError?: boolean, errorMessage?: string) {
    // Render the error message from the server or the success text
    const isManuallyLoggedOut = localStorage.getItem("isManuallyLoggedOut");
    localStorage.removeItem("isManuallyLoggedOut");
    return showError == true ? (
      <Typography
        variant="subtitle1"
        align="center"
        color="error"
        style={{ fontSize: "18px" }}
      >
        {errorMessage}
      </Typography>
    ) : (
      <Typography component="span" variant="body2" style={{ fontSize: "18px" }}>
        {isManuallyLoggedOut === "true"
          ? "You’ve been automatically signed out for security purposes."
          : "You have been signed out."}
      </Typography>
    );
  }

  renderPrivacyPolicy() {
    // Render the privacy policy link
    return (
      <Typography variant="body2" align="center" style={{ marginTop: "20%" }}>
        <Link
          underline="always"
          color="inherit"
          href={`${process.env.PUBLIC_URL}/privacy`}
          variant="body2"
        >
          Privacy Policy
        </Link>{" "}
      </Typography>
    );
  }

  public render() {
    const { classes, loading } = this.props;
    const { errorMessage, showError } = this.state;
    return (
      <React.Fragment>
        {loading && (
          <Box className={classes.loadingCircleContainer}>
            <CircularProgress style={{ width: 150, height: 150 }} />
          </Box>
        )}
        {!loading && (
          <Grid container component="main" className={classes.root} id="root">
            <CssBaseline />
            <img src={backdrop} className="xterioImg" />
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              component={Paper}
              elevation={6}
              square
              id="gridContainer"
            >
              <div className={classes.paper} id="container">
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ color: "rgb(95,47,185)", fontSize: "32px" }}
                >
                  Signed out
                </Typography>
                <React.Fragment>
                  {this.renderMessage(showError, errorMessage)}
                </React.Fragment>
                <Button
                  onClick={this.handleCloseClick}
                  fullWidth
                  variant="contained"
                  id="sign-in-again"
                  color="primary"
                  className={classes.logoutBtn}
                >
                  Sign in again
                </Button>
                {this.renderPrivacyPolicy()}
                <Box mt={1}>
                  <Typography className={classes.version} variant="body2">
                    {this.props?.appsettings?.Version
                      ? `v${this.props?.appsettings.Version}`
                      : ""}
                  </Typography>
                </Box>
              </div>
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  appsettings: state.appsettings,
  authentication: state.authentication,
  loading: makeLoadingSelector([ActionTypes.LogoutRequest])(state),
});

const mapDispatchToProps = {
  logoutUserConnect: logoutUser,
};

export default compose(
  withCookies,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Logout) as React.ComponentType;
