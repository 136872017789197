// React/Redux Library Imports
import { validatePasswordQuality } from "helpers/general";
import * as React from "react";
import { ReactCookieProps, withCookies } from "react-cookie";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { compose } from "redux";

// Material-UI Imports
import {
  Box,
  Button,
  Link,
  TextField,
  Typography,
  WithStyles,
  withStyles,
  Grid,
  CssBaseline,
} from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";

// Redux Store Imports
import { ApplicationState, DispatchThunkAction } from "../../state";
import actions from "../../state/actions";
import { AppSettingsState } from "../../state/appsettings";
import { AuthenticationState } from "../../state/authentication";
import { ActionTypes } from "../../state/enums/ActionTypes";
import { makeLoadingSelector } from "../../state/loading/selectors";

// Other Imports
import backdrop from "../../assets/images/Hero-Pattern.png";
import { RegisterUserAction } from "../../state/authentication/actions";
import {
  StringLocalizer,
  stringLocalizerSelector,
} from "../../state/localization/selectors";
import "components/shared/Authentication.css";

// The email address cannot start with a whitespace character or an @ symbol
// There must be at least one character between the @ symbol and the first period
// There must be at least one character between the first and second periods
// The email address cannot end with a whitespace character
const EMAIL_VALID_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const PHONE_NO_VALID_REGEX = /^[\d()\s+-]+$/;

// Generating css styles for the component
const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: 460,
      margin: `${theme.spacing(0)} auto`,
    },
    loginBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
    },
    header: {
      textAlign: "center",
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    card: {
      marginTop: theme.spacing(10),
    },
    root: {
      height: "100vh",
      alignContent: "flex-start",
    },
    image: {
      backgroundImage: `url(${backdrop})`,
      backgroundRepeat: "no-repeat",
      backgroundColor: "#210c51",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "3%",
      paddingLeft: "5%",
      paddingRight: "5%",
      paddingBottom: "2%",
      height: "100%",
      overFlow: "auto",
    },
    paperCenter: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "3%",
      paddingLeft: "5%",
      paddingRight: "5%",
      paddingBottom: "2%",
      height: "100%",
      overFlow: "auto",
      justifyContent: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    signInBox: {
      width: "100%",
      maxWidth: 480,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      padding: `6px 16px`,
      height: "unset",
     
      fontWeight: 500,
      fontSize: 16,
    },
    footerBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    version: {
      fontSize: 11,
      textAlign: "center",
    },
    organization: {
      height: "4px",
      padding: 0,
      marginBottom: "2%",
    },
    createText: {
      color: "#4710AE",
      fontSize: "32px",
      width: "100%",
    },
    pTag: {
      width: "100%",
    },
    errorMessage: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
    },
    proximaLabelText: {
      
      color: "#505050",
      marginTop: "16px",
      fontSize: "14px",
    },
  });

// Deconstructed actions
const { registerUser, checkAccountInvitation, changePassword } = actions;

interface DispatchProps {
  registerUser: DispatchThunkAction<typeof registerUser>;
  checkAccountInvitation: DispatchThunkAction<typeof checkAccountInvitation>;
  changePassword: DispatchThunkAction<typeof changePassword>;
}

// StateProps
interface StateProps {
  appsettings?: AppSettingsState;
  authentication?: AuthenticationState;
  //errorMessage?: string;
  loading?: boolean;
  stringLocalizer: StringLocalizer;
}

type RegisterProps = StateProps &
DispatchProps &
ReactCookieProps &
RouteComponentProps<{ token?: string }> &
WithStyles<typeof styles>;

interface RegisterState {
  Organization?: string;
  Email?: string;
  FirstName?: string;
  LastName?: string;
  Password?: string;
  ConfirmPassword?: string;
  PhoneNumber?: string;
  token?: string;
  checkEmail?: boolean;
  errorMessage?: string;
  showError?: boolean;
  showMandatoryError?: boolean;
  mandatoryErrorMessage?: string;
  isFirstLoad?: boolean;
  type: string;
}

class Register extends React.PureComponent<RegisterProps, RegisterState> {
  constructor(props: RegisterProps) {
    super(props);
    //debugger;
    this.state = {
      showError: false,
      showMandatoryError: false,
      mandatoryErrorMessage: "",
      isFirstLoad: true,
      errorMessage: "",
      type: "",
      // checkEmail:true,
    };
  }

  static getDerivedStateFromProps(
    nextProps: RegisterProps,
    state: RegisterState
  ) {
    //debugger;
    const { checkAccountInvitation, authentication } = nextProps;
    const errorMessage = state.isFirstLoad ? "" : authentication?.errorMessage;
    if (
      nextProps.match &&
      nextProps.match.params &&
      nextProps.match.params.token &&
      nextProps.match.params.token != state.token
    ) {
      void checkAccountInvitation(nextProps.match.params.token).then(
        (action: RegisterUserAction) => {
          if (action.type === ActionTypes.AccountInvitationSuccess) {
            nextProps.history.push(`${process.env.PUBLIC_URL}/activation`);
          }
        }
      );
      return { token: nextProps.match.params.token, isFirstLoad: false };
    }
    return {
      errorMessage,
      showError: !errorMessage ? false : true,
      isFirstLoad: false,
    };
  }

  handleRegisterSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // Need to do this to stop email and password from
    // showing up as query parameters
    event.preventDefault();

    const { registerUser } = this.props;
    const { FirstName, LastName, Email, PhoneNumber, Password, Organization } =
      this.state;

    const trimmedFirstName = FirstName ? FirstName.trim() : FirstName;
    const trimmedLastName = LastName ? LastName.trim() : LastName;

    this.setState({
      showError: false,
      showMandatoryError: false,
      mandatoryErrorMessage: "",
      type: "submit",
    });

    const valid = this.validateCreateUserForm();
    if (valid) {
      void registerUser({
        FirstName: trimmedFirstName,
        LastName: trimmedLastName,
        Email,
        PhoneNumber,
        Password,
        BusinessName: Organization,
      }).then((action: RegisterUserAction) => {
        if (action.type === ActionTypes.RegisterUserSuccess) {
          this.setState({ checkEmail: true });
        } else {
          const actionErrorMessage = action.error?.toString();
          this.setState({
            showMandatoryError: true,
            mandatoryErrorMessage: actionErrorMessage
              ? actionErrorMessage
              : "Something went wrong.",
          });
        }
      });
    } else if (Email && !EMAIL_VALID_REGEX.test(Email)) {
      this.setState({
        showMandatoryError: true,
        mandatoryErrorMessage: "Invalid email format.",
      });
    } else {
      this.setState({
        showMandatoryError: true,
        mandatoryErrorMessage: "Please fill all the mandatory fields.",
      });
    }
  };

  renderPrivacyPolicy() {
    // Render the privacy policy link
    return (
      <Typography variant="body2" align="center">
        <Link
          color="inherit"
          href={`${process.env.PUBLIC_URL}/privacy`}
          variant="body2"
          underline="always"
        >
          Privacy Policy
        </Link>{" "}
      </Typography>
    );
  }

  renderErrorMessage(showError?: boolean, errorMessage?: string) {
    const { classes } = this.props;
    // Render the error message from the server
    return showError == true && !this.state.token ? (
      <Typography
        id="renderinvalidEmail"
        variant="subtitle1"
        align="center"
        color="error"
        className={classes.errorMessage}
      >
        {errorMessage}
      </Typography>
    ) : (
      <></>
    );
  }

  renderInvalidErrorToken(showError?: boolean) {
    // Render the error message from the server
    return showError == true && this.state.token ? (
      <>
        <Typography variant="body2" align="center">
          <Typography variant="subtitle1" align="center" color="error">
            {this.state.errorMessage}
          </Typography>
          <Typography variant="body2" align="center">
            <Link
              color="inherit"
              href={`${process.env.PUBLIC_URL}/register`}
              variant="body2"
            >
              Back to register
            </Link>{" "}
          </Typography>
        </Typography>
      </>
    ) : (
      <></>
    );
  }

  validatePasswordConfirm() {
    const { Password, ConfirmPassword } = this.state;
    return ConfirmPassword ? Password === ConfirmPassword : false;
  }

  validateCreateUserForm = () => {
    const {
      FirstName,
      LastName,
      Email,
      Organization,
      Password,
      ConfirmPassword,
    } = this.state;

    const isFirstNameValid = FirstName?.trim() && FirstName.length > 0;
    const isLastNameValid = LastName?.trim() && LastName.length > 0;
    const isEmailValid = Email && EMAIL_VALID_REGEX.test(Email);
    const isOrganizationValid = Organization?.trim() && Organization.length > 0;
    const isPasswordValid =
      Password &&
      ConfirmPassword &&
      validatePasswordQuality(Password) &&
      Password === ConfirmPassword;

    return (
      isFirstNameValid &&
      isLastNameValid &&
      isEmailValid &&
      isOrganizationValid &&
      isPasswordValid
    );
  };

  areMandatoryFieldsFilled = () => {
    const { FirstName, LastName, Organization, Password, ConfirmPassword } =
      this.state;

    const isFirstNameValid = FirstName?.trim() && FirstName.length > 0;
    const isLastNameValid = LastName?.trim() && LastName.length > 0;
    const isOrganizationValid = Organization?.trim() && Organization.length > 0;
    const isPasswordValid =
      Password &&
      ConfirmPassword &&
      validatePasswordQuality(Password) &&
      Password === ConfirmPassword;

    return (
      isFirstNameValid &&
      isLastNameValid &&
      isOrganizationValid &&
      isPasswordValid
    );
  };

  // validateInput = (value: string) => {
  //   return value ? value.length > 0 : false;
  // };

  validateFirstName = () => {
    const { FirstName } = this.state;
    return FirstName ? FirstName.length > 0 : false;
  };

  validateLastName = () => {
    const { LastName } = this.state;
    return LastName ? LastName.length > 0 : false;
  };

  // validateOrganization = () => {
  //   const { Organization } = this.state;
  //   return Organization ? Organization.length > 0 : false;
  // };

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      Email: event.target.value,
      showMandatoryError: false,
      mandatoryErrorMessage: "",
    });
  };

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ Password: event.target.value });
  };

  handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ ConfirmPassword: event.target.value });
  };

  handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ FirstName: event.target.value });
  };

  handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ LastName: event.target.value });
  };

  handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.value === "" ||
      PHONE_NO_VALID_REGEX.test(event.target.value)
    ) {
      this.setState({ PhoneNumber: event.target.value });
    }
  };

  handleOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ Organization: event.target.value });
  };

  renderRegister = (
    Organization?: string,
    Email?: string,
    FirstName?: string,
    LastName?: string,
    PhoneNumber?: string,
    Password?: string,
    ConfirmPassword?: string
  ) => {
    const { classes, stringLocalizer, loading } = this.props;
    const {
      checkEmail,
      token,
      errorMessage,
      showError,
      showMandatoryError,
      mandatoryErrorMessage,
    } = this.state;

    return (
      <Box>
        <Box className={classes.signInBox}>
          <Typography
            component="h1"
            variant="h4"
            className={classes.createText}
          >
            Create an account
          </Typography>
          {!checkEmail && !token && (
            <Typography component="p" className={classes.pTag}>
              Enter your details below to get started.
            </Typography>
          )}
          <React.Fragment>
            {this.renderInvalidErrorToken(showError)}
          </React.Fragment>
          <React.Fragment>
            {this.renderErrorMessage(showMandatoryError, mandatoryErrorMessage)}
          </React.Fragment>
          <form
            id="submit-registration-form"
            className={classes.form}
            noValidate
            onSubmit={this.handleRegisterSubmit}
          >
            {checkEmail && (
              <Typography>
                Please check your Inbox/Spam folder for a user invitation link
                in order to complete the registration process.
              </Typography>
            )}
            {checkEmail && (
              <Box mt={1}>
                <Typography style={{ marginTop: "10px" }}>
                  Haven’t received the link yet? Click here to {" "}
                  <Link
                    variant="body2"
                    href={`${process.env.PUBLIC_URL}/register`}
                    underline="always"
                    style={{ cursor: "pointer" }}
                  >
                    re-register.
                  </Link>
                </Typography>
              </Box>
            )}
            {!checkEmail && token && !errorMessage && (
              <Typography>
                Please wait while we activate your account.
              </Typography>
            )}
            {!checkEmail && !token && (
              <React.Fragment>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="organization"
                  label="Organization"
                  name="organization"
                  value={Organization ? Organization : ""}
                  autoComplete="organization"
                  onChange={this.handleOrganizationChange}
                  error={false}
                  autoFocus
                  size="small"
                  style={{ marginBottom: 0 }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="off"
                  value={Email ? Email : ""}
                  onChange={this.handleEmailChange}
                  size="small"
                  style={{ marginBottom: 0 }}
                />
                <div className="nameContainer">
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth={false}
                    id="firstname"
                    label="First name"
                    name="firstname"
                    value={FirstName ? FirstName : ""}
                    autoComplete="firstname"
                    onChange={this.handleFirstNameChange}
                    error={FirstName ? !this.validateFirstName() : false}
                    size="small"
                    style={{
                      marginRight: "16px",
                      marginBottom: 0,
                      width: "49%",
                    }}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth={window.screen.width < 1170 ? true : false}
                    id="lastname"
                    label="Last name"
                    name="lastname"
                    value={LastName ? LastName : ""}
                    autoComplete="lastname"
                    onChange={this.handleLastNameChange}
                    error={LastName ? !this.validateLastName() : false}
                    size="small"
                    style={{ marginBottom: 0, width: "49%" }}
                  />
                </div>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="phonenumber"
                  label="Phone number (e.g: XXX-XXX-XXXX)"
                  name="phonenumber"
                  value={PhoneNumber ? PhoneNumber : ""}
                  inputProps={{ maxLength: 20 }}
                  onChange={this.handlePhoneNumberChange}
                  size="small"
                  style={{ marginBottom: 0 }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  value={Password ? Password : ""}
                  label="Password"
                  type="password"
                  id="password"
                  onChange={this.handlePasswordChange}
                  autoComplete="new-password"
                  error={Password ? !validatePasswordQuality(Password) : false}
                  helperText={
                    (Password ? !validatePasswordQuality(Password) : false)
                      ? "Please use at least 12 characters with at least 7 unique characters. Consider using a Password Manager."
                      : undefined
                  }
                  size="small"
                  style={{ marginBottom: 0 }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmpassword"
                  value={ConfirmPassword}
                  label="Confirm password"
                  type="password"
                  id="confirmpassword"
                  onChange={this.handleConfirmPasswordChange}
                  autoComplete="confirm-password"
                  error={
                    ConfirmPassword ? !this.validatePasswordConfirm() : false
                  }
                  helperText={
                    (ConfirmPassword ? !this.validatePasswordConfirm() : false)
                      ? "The password fields do not match"
                      : undefined
                  }
                  size="small"
                  style={{ marginBottom: 0 }}
                />
                <Typography className={classes.proximaLabelText}>
                  All information is needed, and XStereotype will never share
                  your data without your permission.
                </Typography>
                <Button
                  id="submit-register"
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!this.areMandatoryFieldsFilled() || loading}
                  className={classes.submit}
                >
                  {loading
                    ? stringLocalizer("Processing...")
                    : stringLocalizer("Sign up")}
                </Button>
              </React.Fragment>
            )}
          </form>
        </Box>
      </Box>
    );
  };

  handleScroll = () => {
    window.scrollTo(0, 0);
    this.setState({
      type: "",
    });
  };

  public render() {
    const { classes, appsettings } = this.props;
    const {
      Organization,
      Email,
      FirstName,
      LastName,
      Password,
      ConfirmPassword,
      PhoneNumber,
      mandatoryErrorMessage,
      checkEmail,
      token,
    } = this.state;

    mandatoryErrorMessage?.length !== 0 &&
      this.state?.type === "submit" &&
      this.handleScroll();

    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <img src={backdrop} className="xterioImg" />
        <div
          className={checkEmail || token ? classes.paperCenter : classes.paper}
          id="childComponent"
          style={{ margin: 0, overflow: "auto" }}
        >
          <React.Fragment>
            {this.renderRegister(
              Organization,
              Email,
              FirstName,
              LastName,
              PhoneNumber,
              Password,
              ConfirmPassword
            )}
          </React.Fragment>
          {!token && (
            <Box className={classes.footerBox}>
              <Box mt={1}>{this.renderPrivacyPolicy()}</Box>
              <Box mt={1}>
                <Typography className={classes.version} variant="body2">
                  {appsettings?.Version ? `v${appsettings.Version}` : ""}
                </Typography>
              </Box>
            </Box>
          )}
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  //debugger;
  const stringLocalizer = stringLocalizerSelector(state);
  return {
    appsettings: state.appsettings,
    authentication: state.authentication,
    loading: makeLoadingSelector([
      ActionTypes.RegisterUserRequest,
      ActionTypes.AccountInvitationRequest,
    ])(state),
    stringLocalizer,
    errorMessage: state.authentication?.errorMessage,
  };
};

const mapDispatchToProps = {
  registerUser,
  checkAccountInvitation,
  changePassword,
};

export default compose(
  withCookies,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Register) as React.ComponentType;
