import { createSelector, OutputSelector } from "reselect";
import { ApplicationState } from "../";
import { AppSettingsState } from "./reducers";
import { Modules } from "../../types";

/**
 * Type for hasModule function returned by hasModuleSelector
 * 
 * @interface HasModule
 * @param module {string} module name
 * @returns boolean representing whether the passed module is enabled
 */
export type HasModule = (module: string) => boolean;

const getAppSettings = (state: ApplicationState) => state.appsettings;

/**
 * @function hasModuleSelector
 * @param state {ApplicationState} current state
 * @returns hasModule function to check for enabled modules
 */
export const hasModuleSelector/*: OutputSelector<
  ApplicationState,
  HasModule,
  (appsettings: AppSettingsState) => HasModule
>*/ = createSelector([getAppSettings], (appsettings: AppSettingsState) => {
  return (module: string) => {
    if (!appsettings || !appsettings.Modules) {
      return false;
    }
    return (
      appsettings.Modules.includes(Modules.All) ||
      appsettings.Modules.includes(module)
    );
  };
});
