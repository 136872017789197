import { AccountState } from "./account";
import { UsersState } from "./users";
import { AppSettingsState } from "./appsettings";
import { LoadingState } from "./loading";
import { LocalizationState } from "./localization";
import { DashboardsState } from "./dashboards";
import { NotificationsState } from "./notifications";
import { GroupsState } from "./groups";
import { InsightRequestState } from "./insightRequest";
import { AnyAction, Action } from "redux";
import { AuthenticationState } from "./authentication";
import { TwoFactorState } from "./twofactor";
import { InsightsState } from "./insights";
import importReducers from "./reducers";
import { ProfileType } from "../abstractions/profileType/ProfileType";

// The top-level state Record<string, unknown>
export interface ApplicationState {
  twofactor?: TwoFactorState;
  authentication?: AuthenticationState;
  account?: AccountState;
  appsettings: AppSettingsState;
  dashboards?: DashboardsState;
  loading?: LoadingState;
  localization?: LocalizationState;
  users?: UsersState;
  notifications?: NotificationsState;
  groups?: GroupsState;
  insightRequest?: InsightRequestState;
  insights?: InsightsState;
  profileTypes?: Array<ProfileType>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching Name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  ...importReducers,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (
    dispatch: (action: TAction) => Promise<TAction>,
    getState: () => ApplicationState
  ): Promise<TAction>;
}

// This type is for wrapping AppThunkAction returning ActionCreators after they have been connected to
// dispatch in a component so that their parameters and return type will both be correct
// Needs to use an any type to work correctly, hence the lint disable
export type DispatchThunkAction<
  TThunkAction extends (
    ...a: any /* eslint-disable-line @typescript-eslint/no-explicit-any */
  ) => AppThunkAction<AnyAction>
> = (...a: Parameters<TThunkAction>) => ReturnType<ReturnType<TThunkAction>>;

// This type is for Action payloads, only payloads following this convention can be tracked by
// the analytics middleware
export interface Payload {
  item?: Record<string, unknown>;
  prevItem?: Record<string, unknown>;
}

export interface PayloadAction extends Action {
  payload?: Payload;
}
