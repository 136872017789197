import { forEach } from "lodash";

export const getRequestQuery = (
  currentPage?: number,
  pageSize?: number,
  filterQueryList?: string[],
  sortQueryList?: string[],
  searchModel?: string,
  isExpand?: boolean
): string => {
  let _url = "";

  let filterList: string[] = [];

  let sortList: string[] = [];

  if (sortQueryList && sortQueryList.length) {
    sortList = sortQueryList.concat();
  }

  if (filterQueryList && filterQueryList.length) {
    filterList = filterQueryList.concat();
  }

  // Use the search model to filter requests based on relevant fields
  if (searchModel) {
    const encodedSearchModel = encodeURIComponent(searchModel);
    const requestName = `contains(Name, '${encodedSearchModel}')`;
    const description = `contains(Description, '${encodedSearchModel}')`;
    // const emailSearch = `contains(Email, '${encodedSearchModel}')`;
    const query = `((${requestName}) OR (${description}))`;
    filterList.push(query);
  }

  let skipItems = 0;
  if (currentPage !== undefined && pageSize) {
    skipItems = currentPage * pageSize;
  }

  // update the url with pagination query
  let expandQuery = `?$count=true&${
    pageSize ? `$top=${pageSize}` : ""
  }&$skip=${skipItems}`;
  if (isExpand !== undefined && isExpand) {
    expandQuery = expandQuery + `&$expand=creatoruser,attachments`;
  }

  _url = _url + expandQuery;

  let filterString = "";
  if (filterList.length) {
    // currentPage = 1; // reset the current page to one
    filterString = "&$filter=";
    forEach(filterList, (val, idx) => {
      filterString = filterString + val;
      if (filterList.length > 1 && idx < filterList.length - 1) {
        filterString = filterString + " AND ";
      }
    });
  }
  _url = _url + filterString;

  if (sortList.length) {
    const sortString = `&$orderby=${sortList.join(",")}`;
    _url = _url + sortString;
  }

  return _url;
};
