
// tslint:disable:interface-name
import { createBrowserHistory } from "history";
// import { baseUrl } from "./";

declare global {
  interface Window {
    dataLayer: any; /* eslint-disable-line @typescript-eslint/no-explicit-any */
  }
}

let historyOptions = {};

const baseUrlObj = document.getElementsByTagName("base")[0];
if (typeof baseUrlObj !== "undefined") {
  const baseUrl = baseUrlObj.getAttribute("base") as string;
  historyOptions = { basename: baseUrl };
}
// const _history = createBrowserHistory({ basename: baseUrl });
const _history = createBrowserHistory(historyOptions);

export default _history;