import { Reducer, AnyAction } from "redux";
import { merge } from "lodash";

export interface LoadingState {
  loading: Record<string, number>;
}

// Intercepts all actions
// In response to action types ending in _REQUEST it creates a number
// with a key equal to the action type to the loading state and
// increments it by one
//
// In response to action types ending in _SUCCESS and _FAILURE it
// will decrease the number with the key of the corresponding _REQUEST
// by one
const reducer: Reducer<LoadingState | undefined, AnyAction> = (
  state: LoadingState | undefined,
  action: AnyAction
) => {
  if (state === undefined) {
    return { loading: {} };
  }

  let returnState = state;

  if (typeof action.type === "string") {
    if (RegExp(/(.+)_REQUEST$/g).exec(action.type)) {
      returnState = merge({}, returnState, {
        loading: {
          [action.type]:
            state.loading[action.type] !== undefined
              ? state.loading[action.type] + 1
              : 1,
        },
      });
    }

    const successMatch = RegExp(/(.+)_SUCCESS$/g).exec(action.type);
    if (successMatch !== null) {
      const requestType = `${successMatch[1]}_REQUEST`;
      returnState = merge({}, returnState, {
        loading: {
          [requestType]:
            state.loading[requestType] !== undefined
              ? state.loading[requestType] - 1
              : 0,
        },
      });
    }

    const failMatch = RegExp(/(.+)_FAILURE$/g).exec(action.type);
    if (failMatch !== null) {
      const requestType = `${failMatch[1]}_REQUEST`;
      returnState = merge({}, returnState, {
        loading: {
          [requestType]:
            state.loading[requestType] !== undefined
              ? state.loading[requestType] - 1
              : 0,
        },
      });
    }
  }

  return returnState;
};

export default reducer;
