import { createSelector, OutputSelector } from "reselect";
import { ApplicationState } from "../";
import { LocalizationState } from "./reducers";

/**
 * Type for stringLocalizer function returned by stringLocalizerSelector
 *
 * Will look for a localization string matching the string key passed to
 * it, if there is none in the localization state it will return the
 * original string
 *
 * @interface StringLocalizer
 * @param str {string} string to localize
 * @returns localized string
 */
export type StringLocalizer = (str: string) => string;

const getLocalization = (state: ApplicationState) => state.localization;

/**
 * @function stringLocalizerSelector
 * @param state {ApplicationState} current state
 * @returns stringLocalizer function localize strings
 */
export const stringLocalizerSelector = createSelector(
  [getLocalization],
  (localization?: LocalizationState) => {
    if (!localization) return (str: string) => str;
    return (str: string) => {
      if (typeof localization[str] === "string") {
        return localization[str];
      } else {
        return str;
      }
    };
  }
);
