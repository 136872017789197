// Note: All api actions that are to be tracked by the "loading" state
//  must be named with the _REQUEST, _SUCCESS, and _FAILURE suffixes
export enum ActionTypes {
  None = "None",

  ErrorDetailRequest = "ERROR_DETAIL_REQUEST",
  ErrorDetailSuccess = "ERROR_DETAIL_SUCCESS",
  ErrorDetailFailure = "ERROR_DETAIL_FAILURE",

  // AUTHENTICATION ACTIONS

  LoginRequest = "LOGIN_REQUEST",
  LoginSuccess = "LOGIN_SUCCESS",
  LoginFailure = "LOGIN_FAILURE",

  PasswordLessLoginRequest = "PASSWORDLESS_REQUEST",
  PasswordLessLoginSuccess = "PASSWORDLESS_SUCCESS",
  PasswordLessLoginFailure = "PASSWORDLESS_FAILURE",

  SetCookiesRequest = "SET_COOKIES_SUCCESS",
  SetCookiesSuccess = "SET_COOKIES_SUCCESS",
  SetCookiesFailure = "SET_COOKIES_FAILURE",
  LogoutRequest = "LOGOUT_REQUEST",
  LogoutSuccess = "LOGOUT_SUCCESS",
  LogoutFailure = "LOGOUT_FAILURE",

  AmrRequest = "AMR_REQUEST",
  AmrSuccess = "AMR_SUCCESS",
  AmrFailure = "AMR_FAILURE",

  CheckAuthenticationRequest = "CHECK_AUTHENTICATION_REQUEST",
  CheckAuthenticationSuccess = "CHECK_AUTHENTICATION_SUCCESS",
  CheckAuthenticationFailure = "CHECK_AUTHENTICATION_FAILURE",

  Login2FaModelRequest = "LOGN_2FA_MODEL_REQUEST",
  Login2FaModelSuccess = "LOGN_2FA_MODEL_SUCCESS",
  Login2FaModelFailure = "LOGN_2FA_MODEL_FAILURE",
  Login2FaRequest = "LOGIN_2FA_REQUEST",
  Login2FaSuccess = "LOGIN_2FA_SUCCESS",
  Login2FaFailure = "LOGIN_2FA_FAILURE",
  PaymentIntentRequest = "PAYMENT_INTENT_REQUEST",
  PaymentIntentSuccess = "PAYMENT_INTENT_SUCCESS",
  PaymentIntentFailure = "PAYMENT_INTENT_FAILURE",
  TwoFactorModelRequest = "TWO_FACTOR_MODEL_REQUEST",
  TwoFactorModelSuccess = "TWO_FACTOR_MODEL_SUCCESS",
  TwoFactorModelFailure = "TWO_FACTOR_MODEL_FAILURE",
  TwoFactorConfirmRequest = "TWO_FACTOR_CONFIRM_REQUEST",
  TwoFactorConfirmSuccess = "TWO_FACTOR_CONFIRM_SUCCESS",
  TwoFactorConfirmFailure = "TWO_FACTOR_CONFIRM_FAILURE",
  TwoFactorEnableRequest = "TWO_FACTOR_ENABLE_REQUEST",
  TwoFactorEnableSuccess = "TWO_FACTOR_ENABLE_SUCCESS",
  TwoFactorEnableFailure = "TWO_FACTOR_ENABLE_FAILURE",
  TwoFactorByAppRequest = "TWO_FACTOR_BY_APP_REQUEST",
  TwoFactorByAppSuccess = "TWO_FACTOR_BY_APP_SUCCESS",
  TwoFactorByAppFailure = "TWO_FACTOR_BY_APP_FAILURE",
  TwoFactorAppConfirmRequest = "TWO_FACTOR_APP_CONFIRM_REQUEST",
  TwoFactorAppConfirmSuccess = "TWO_FACTOR_APP_CONFIRM_SUCCESS",
  TwoFactorAppConfirmFailure = "TWO_FACTOR_APP_CONFIRM_FAILURE",
  TwoFactorByEmailRequest = "TWO_FACTOR_BY_EMAIL_REQUEST",
  TwoFactorByEmailSuccess = "TWO_FACTOR_BY_EMAIL_SUCCESS",
  TwoFactorByEmailFailure = "TWO_FACTOR_BY_EMAIL_FAILURE",
  TwoFactorEmailConfirmRequest = "TWO_FACTOR_EMAIL_CONFIRM_REQUEST",
  TwoFactorEmailConfirmSuccess = "TWO_FACTOR_EMAIL_CONFIRM_SUCCESS",
  TwoFactorEmailConfirmFailure = "TWO_FACTOR_EMAIL_CONFIRM_FAILURE",
  TwoFactorRecoveryCodeRequest = "TWO_FACTOR_RECOVERY_CODE_REQUEST",
  TwoFactorRecoveryCodeSuccess = "TWO_FACTOR_RECOVERY_CODE_SUCCESS",
  TwoFactorRecoveryCodeFailure = "TWO_FACTOR_RECOVERY_CODE_FAILURE",
  TwoFactorChangeSetup = "TWO_FACTOR_CHANGE_SETUP",
  TwoFactorToggleShowVerificationForm = "TWO_FACTOR_TOGGLE_VERIFICATION_FORM",

  TwoFactorToggleSharedKey = "TWO_FACTOR_TOGGLE_SHARED_KEY",
  TwoFactorClear = "TWO_FACTOR_CLEAR",
  TwoFactorContinueToApp = "TWO_FACTOR_CONTINUE_TO_APP",

  RequireTwoFactorRequest = "REQUIRES_TWO_FACTOR_REQUEST",
  RequireTwoFactorSuccess = "REQUIRES_TWO_FACTOR_SUCCESS",
  RequireTwoFactorFailure = "REQUIRES_TWO_FACTOR_FAILURE",

  ForgotPasswordRequest = "FORGOT_PASSWORD_REQUEST",
  ForgotPasswordSuccess = "FORGOT_PASSWORD_SUCCESS",
  ForgotPasswordFailure = "FORGOT_PASSWORD_FAILURE",

  AccountRequest = "ACCOUNT_REQUEST",
  AccountSuccess = "ACCOUNT_SUCCESS",
  AccountFailure = "ACCOUNT_FAILURE",
  AccountUpdate = "ACCOUNT_UPDATE",

  AccountInvitationRequest = "ACCOUNT_INVITATION_REQUEST",
  AccountInvitationSuccess = "ACCOUNT_INVITATION_SUCCESS",
  AccountInvitationFailure = "ACCOUNT_INVITATION_FAILURE",

  AllUserDashboardCardsRequest = "ALL_USERDASHBOARDCARDS_REQUEST",
  AllUserDashboardCardsSuccess = "ALL_USERDASHBOARDCARDS_SUCCESS",
  AllUserDashboardCardsFailure = "ALL_USERDASHBOARDCARDS_FAILURE",
  AllUserDashboardsUpdateRequest = "ALL_USERDASHBOARDS_UPDATE_REQUEST",
  AllUserDashboardsUpdateSuccess = "ALL_USERDASHBOARDS_UPDATE_SUCCESS",
  AllUserDashboardsUpdateFailure = "ALL_USERDASHBOARDS_UPDATE_FAILURE",

  AppSettingsRequest = "APPSETTINGS_REQUEST",
  AppSettingsSuccess = "APPSETTINGS_SUCCESS",
  AppSettingsFailure = "APPSETTINGS_FAILURE",

  AuditStatusesRequest = "AUDIT_STATUSES_REQUEST",
  AuditStatusesSuccess = "AUDIT_STATUSES_SUCCESS",
  AuditStatusesFailure = "AUDIT_STATUSES_FAILURE",

  CallAPI = "CALL_API",
  CallODATA = "CALL_ODATA",

  ChangeEmailRequest = "CHANGE_EMAIL_REQUEST",
  ChangeEmailSuccess = "CHANGE_EMAIL_SUCCESS",
  ChangeEmailFailure = "CHANGE_EMAIL_FAILURE",
  ChangeEmailVerificationRequest = "CHANGE_EMAIL_VERIFICATION_REQUEST",
  ChangeEmailVerificationSuccess = "CHANGE_EMAIL_VERIFICATION_SUCCESS",
  ChangeEmailVerificationFailure = "CHANGE_EMAIL_VERIFICATION_FAILURE",
  ChangeUserEmailRequest = "CHANGE_USER_EMAIL_REQUEST",
  ChangeUserEmailSuccess = "CHANGE_USER_EMAIL_SUCCESS",
  ChangeUserEmailFailure = "CHANGE_USER_EMAIL_FAILURE",
  ChangeEmailResultRequest = "CHANGE_EMAIL_RESULT_REQUEST",
  ChangeEmailResultSuccess = "CHANGE_EMAIL_RESULT_SUCCESS",
  ChangeEmailResultFailure = "CHANGE_EMAIL_RESULT_FAILURE",
  ChangePasswordRequest = "CHANGE_PASSWORD_REQUEST",
  ChangePasswordSuccess = "CHANGE_PASSWORD_SUCCESS",
  ChangePasswordFailure = "CHANGE_PASSWORD_FAILURE",

  DashboardTypesRequest = "DASHBOARDTYPES_REQUEST",
  DashboardTypesSuccess = "DASHBOARDTYPES_SUCCESS",
  DashboardTypesFailure = "DASHBOARDTYPES_FAILURE",

  DocumentUploadRequest = "DOCUMENT_UPLOAD_REQUEST",
  DocumentUploadSuccess = "DOCUMENT_UPLOAD_SUCCESS",
  DocumentUploadFailure = "DOCUMENT_UPLOAD_FAILURE",

  DocumentRequest = "DOCUMENT_REQUEST",
  DocumentSuccess = "DOCUMENT_SUCCESS",
  DocumentFailure = "DOCUMENT_FAILURE",

  CompleteChunkRequest = "COMPLETE_CHUNK_REQUEST",
  CompleteChunkSuccess = "COMPLETE_CHUNK_SUCCESS",
  CompleteChunkFailure = "COMPLETE_CHUNK_FAILURE",

  FeedbackCategoriesRequest = "FEEDBACK_CATEGORIES_REQUEST",
  FeedbackCategoriesSuccess = "FEEDBACK_CATEGORIES_SUCCESS",
  FeedbackCategoriesFailure = "FEEDBACK_CATEGORIES_FAILURE",

  GroupsRequest = "GROUPS_REQUEST",
  GroupsSuccess = "GROUPS_SUCCESS",
  GroupsFailure = "GROUPS_FAILURE",
  GroupAddRequest = "GROUPS_ADD_REQUEST",
  GroupAddSuccess = "GROUPS_ADD_SUCCESS",
  GroupAddFailure = "GROUPS_ADD_FAILURE",
  GroupRestrictRequest = "GROUP_RESTRICT_REQUEST",
  GroupRestrictSuccess = "GROUP_RESTRICT_SUCCESS",
  GroupRestrictFailure = "GROUP_RESTRICT_FAILURE",
  GroupUnrestrictRequest = "GROUP_UNRESTRICT_REQUEST",
  GroupUnrestrictSuccess = "GROUP_UNRESTRICT_SUCCESS",
  GroupUnrestrictFailure = "GROUP_UNRESTRICT_FAILURE",
  GroupDataRequest = "GROUP_DATA_REQUEST",
  GroupDataSuccess = "GROUP_DATA_SUCCESS",
  GroupDataFailure = "GROUP_DATA_FAILURE",

  GroupRolesRequest = "GROUP_ROLES_REQUEST",
  GroupRolesSuccess = "GROUP_ROLES_SUCCESS",
  GroupRolesFailure = "GROUP_ROLES_FAILURE",
  GroupRoleAddRequest = "GROUP_ROLE_ADD_REQUEST",
  GroupRoleAddSuccess = "GROUP_ROLE_ADD_SUCCESS",
  GroupRoleAddFailure = "GROUP_ROLE_ADD_FAILURE",
  GroupRoleRemoveRequest = "GROUP_ROLE_REMOVE_REQUEST",
  GroupRoleRemoveSuccess = "GROUP_ROLE_REMOVE_SUCCESS",
  GroupRoleRemoveFailure = "GROUP_ROLE_REMOVE_FAILURE",

  GroupSelect = "GROUP_SELECT",

  InsightsRequest = "INSIGHTS_REQUEST",
  InsightsSuccess = "INSIGHTS_SUCCESS",
  InsightsFailure = "INSIGHTS_FAILURE",
  InsightsAttributeGroupsRequest = "INSIGHTS_ATTRIBUTE_GROUPS_REQUEST",
  InsightsAttributeGroupsSuccess = "INSIGHTS_ATTRIBUTE_GROUPS_SUCCESS",
  InsightsAttributeGroupsFailure = "INSIGHTS_ATTRIBUTE_GROUPS_FAILURE",

  LocalizationRequest = "LOCALIZATION_REQUEST",
  LocalizationSuccess = "LOCALIZATION_SUCCESS",
  LocalizationFailure = "LOCALIZATION_FAILURE",

  NotificationCountRequest = "NOTIFICATION_COUNT_REQUEST",
  NotificationCountSuccess = "NOTIFICATION_COUNT_SUCCESS",
  NotificationCountFailure = "NOTIFICATION_COUNT_FAILURE",

  NotificationDeleteRequest = "NOTIFICATION_DELETE_REQUEST",
  NotificationDeleteSuccess = "NOTIFICATION_DELETE_SUCCESS",
  NotificationDeleteFailure = "NOTIFICATION_DELETE_FAILURE",
  NotificationReadRequest = "NOTIFICATION_READ_REQUEST",
  NotificationReadSuccess = "NOTIFICATION_READ_SUCCESS",
  NotificationReadFailure = "NOTIFICATION_READ_FAILURE",
  NotificationUpdateRequest = "NOTIFICATION_UPDATE_REQUEST",
  NotificationUpdateSuccess = "NOTIFICATION_UPDATE_SUCCESS",
  NotificationUpdateFailure = "NOTIFICATION_UPDATE_FAILURE",
  NotificationsRequest = "NOTIFICATIONS_REQUEST",
  NotificationsSuccess = "NOTIFICATIONS_SUCCESS",
  NotificationsFailure = "NOTIFICATIONS_FAILURE",
  NotificationsSetPage = "NOTIFICATIONS_SET_PAGE",

  PoliciesRequest = "POLICIES_REQUEST",
  PoliciesSuccess = "POLICIES_SUCCESS",
  PoliciesFailure = "POLICIES_FAILURE",

  ProfileModalOpen = "PROFILE_MODAL_OPEN",
  ProfileModalClose = "PROFILE_MODAL_CLOSE",

  ProfileTypesRequest = "PROFILE_TYPES_REQUEST",
  ProfileTypesSuccess = "PROFILE_TYPES_SUCCESS",
  ProfileTypesFailure = "PROFILE_TYPES_FAILURE",

  RolesRequest = "ROLES_REQUEST",
  RolesSuccess = "ROLES_SUCCESS",
  RolesFailure = "ROLES_FAILURE",

  RegisterUserRequest = "REGISTER_USER_REQUEST",
  RegisterUserSuccess = "REGISTER_USER_SUCCESS",
  RegisterUserFailure = "REGISTER_USER_FAILURE",

  ResetPasswordRequest = "RESET_PASSWORD_REQUEST",
  ResetPasswordSuccess = "RESET_PASSWORD_SUCCESS",
  ResetPasswordFailure = "RESET_PASSWORD_FAILURE",

  InsightRequestChangePage = "INSIGHT_REQUEST_CHANGE_PAGE",
  InsightRequestCountRequest = "INSIGHT_REQUEST_COUNT_REQUEST",
  InsightRequestCountSuccess = "INSIGHT_REQUEST_COUNT_SUCCESS",
  InsightRequestCountFailure = "INSIGHT_REQUEST_COUNT_FAILURE",
  InsightRequestRequest = "INSIGHT_REQUEST_REQUEST",
  InsightRequestSuccess = "INSIGHT_REQUEST_SUCCESS",
  InsightRequestFailure = "INSIGHT_REQUEST_FAILURE",
  InsightRequestSelect = "INSIGHT_REQUEST_SELECT",
  InsightRequestsRequest = "INSIGHT_REQUESTS_REQUEST",
  InsightRequestsSuccess = "INSIGHT_REQUESTS_SUCCESS",
  InsightRequestsFailure = "INSIGHT_REQUESTS_FAILURE",
  InsightRequestsClearError = "INSIGHT_REQUESTS_CLEAR_ERROR",
  InsightRequestCloseRequest = "INSIGHT_REQUEST_CLOSE_REQUEST",
  InsightRequestCloseSuccess = "INSIGHT_REQUEST_CLOSE_SUCCESS",
  InsightRequestCloseFailure = "INSIGHT_REQUEST_CLOSE_FAILURE",
  InsightRequestCreateRequest = "INSIGHT_REQUEST_CREATE_REQUEST",
  InsightRequestCreateSuccess = "INSIGHT_REQUEST_CREATE_SUCCESS",
  InsightRequestCreateFailure = "INSIGHT_REQUEST_CREATE_FAILURE",
  InsightRequestCreateDraftRequest = "INSIGHT_REQUEST_CREATE_DRAFT_REQUEST",
  InsightRequestCreateDraftSuccess = "INSIGHT_REQUEST_CREATE_DRAFT_SUCCESS",
  InsightRequestCreateDraftFailure = "INSIGHT_REQUEST_CREATE_DRAFT_FAILURE",
  InsightRequestFinalizeDraftRequest = "INSIGHT_REQUEST_FINALIZE_DRAFT_REQUEST",
  InsightRequestFinalizeDraftSuccess = "INSIGHT_REQUEST_FINALIZE_DRAFT_SUCCESS",
  InsightRequestFinalizeDraftFailure = "INSIGHT_REQUEST_FINALIZE_DRAFT_FAILURE",
  InsightRequestEditRequest = "INSIGHT_REQUEST_EDIT_REQUEST",
  InsightRequestEditSuccess = "INSIGHT_REQUEST_EDIT_SUCCESS",
  InsightRequestEditFailure = "INSIGHT_REQUEST_EDIT_FAILURE",
  InsightRequestSetCompany = "INSIGHT_REQUEST_SET_COMPANY",

  InsightRequestResetPaymentState = "INSIGHT_REQUEST_RESET_PAYMENT_STATE",
  InsightRequestResetState = "INSIGHT_REQUEST_RESET_STATE",
  InsightRequestStartChargeRequest = "INSIGHT_REQUEST_START_CHARGE_REQUEST",
  InsightRequestStartChargeSuccess = "INSIGHT_REQUEST_START_CHARGE_SUCCESS",
  InsightRequestStartChargeFailure = "INSIGHT_REQUEST_START_CHARGE_FAILURE",

  InsightReportRequest = "INSIGHT_REPORT_REQUEST",
  InsightReportSuccess = "INSIGHT_REPORT_SUCCESS",
  InsightReportFailure = "INSIGHT_REPORT_FAILURE",

  InsightTypesRequest = "INSIGHT_TYPES_REQUEST",
  InsightTypesSuccess = "INSIGHT_TYPES_SUCCESS",
  InsightTypesFailure = "INSIGHT_TYPES_FAILURE",

  InsightRequestsStatusUpdate = "INSIGHT_REQUESTS_STATUS_UPDATE",

  UserRequest = "USER_REQUEST",
  UserSuccess = "USER_SUCCESS",
  UserFailure = "USER_FAILURE",
  ProfileRequest = "PROFILE_REQUEST",
  ProfileSuccess = "PROFILE_SUCCESS",
  ProfileFailure = "PROFILE_FAILURE",
  UserUpdateRequest = "USER_UPDATE_REQUEST",
  UserUpdateSuccess = "USER_UPDATE_SUCCESS",
  UserUpdateFailure = "USER_UPDATE_FAILURE",
  UsersCreateRequest = "USERS_CREATE_REQUEST",
  UsersCreateSuccess = "USERS_CREATE_SUCCESS",
  UsersCreateFailure = "USERS_CREATE_FAILURE",
  UsersSetPage = "USERS_SET_PAGE",

  UserDashboardCardRequest = "USERDASHBOARDCARD_REQUEST",
  UserDashboardCardSuccess = "USERDASHBOARDCARD_SUCCESS",
  UserDashboardCardFailure = "USERDASHBOARDCARD_FAILURE",

  UserDashboardsRequest = "USERDASHBOARD_REQUEST",
  UserDashboardsSuccess = "USERDASHBOARD_SUCCESS",
  UserDashboardsFailure = "USERDASHBOARD_FAILURE",
  UserDashboardsCreateRequest = "USERDASHBOARD_CREATE_REQUEST",
  UserDashboardsCreateSuccess = "USERDASHBOARD_CREATE_SUCCESS",
  UserDashboardsCreateFailure = "USERDASHBOARD_CREATE_FAILURE",
  UserDashboardsClearError = "USERDASHBOARD_CLEAR_ERROR",

  UserDashboardsUpdateRequest = "USERDASHBOARD_UPDATE_REQUEST",
  UserDashboardsUpdateSuccess = "USERDASHBOARD_UPDATE_SUCCESS",
  UserDashboardsUpdateFailure = "USERDASHBOARD_UPDATE_FAILURE",
  UserDashboardsDeleteRequest = "USERDASHBOARD_DELETE_REQUEST",
  UserDashboardsDeleteSuccess = "USERDASHBOARD_DELETE_SUCCESS",
  UserDashboardsDeleteFailure = "USERDASHBOARD_DELETE_FAILURE",

  UserGroupRequest = "USERGROUP_REQUEST",
  UserGroupSuccess = "USERGROUP_SUCCESS",
  UserGroupFailure = "USERGROUP_FAILURE",
  UserGroupAddRequest = "USERGROUP_ADD_REQUEST",
  UserGroupAddSuccess = "USERGROUP_ADD_SUCCESS",
  UserGroupAddFailure = "USERGROUP_ADD_FAILURE",
  UserGroupDeleteRequest = "USERGROUP_DELETE_REQUEST",
  UserGroupDeleteSuccess = "USERGROUP_DELETE_SUCCESS",
  UserGroupDeleteFailure = "USERGROUP_DELETE_FAILURE",

  DeleteRememberMeRequest = "DELETE_REMEMBER_REQUEST",
  DeleteRememberMeSuccess = "DELETE_REMEMBER_SUCCESS",
  DeleteRememberMeFailure = "DELETE_REMEMBER_FAILURE",

  LoadCacheAppSettingsSuccess = "LOAD_CACHE_APP_SETTINGS_SUCCESS",
  LoadCacheLocalizationSuccess = "LOAD_CACHE_LOCALIZATION_SUCCESS",

  EnterpriseRequest = "ENTERPRISE_REQUEST",
  EnterpriseSuccess = "ENTERPRISE_SUCCESS",
  EnterpriseFailure = "ENTERPRISE_FAILURE",
}
 