// React/Redux Library Imports
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { ReactCookieProps, withCookies } from "react-cookie";
import { RouteComponentProps, RouterProps } from "react-router";

// Material-UI Imports
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  CssBaseline,
  Grid,
  Paper,
  WithStyles,
  withStyles,
  Button,
  Typography,
  Link
} from "@material-ui/core";
import ErrorIcon from '@mui/icons-material/Error';

// Redux Store Imports
import { ApplicationState, DispatchThunkAction } from "../../state";
import { AppSettingsState } from "../../state/appsettings";

// Other Imports
import backdrop from "../../assets/images/Hero-Pattern.svg";
import {
  stringLocalizerSelector,
  StringLocalizer,
} from "../../state/localization/selectors";
import { makeLoadingSelector } from "../../state/loading/selectors";
import { ActionTypes } from "../../state/enums/ActionTypes";
import { loadErrorDetails } from "../../state/Errors/actions";

// Generating css styles for the component
const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: 460,
      margin: `${theme.spacing(0)} auto`,
    },

    header: {
      textAlign: "center",
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    card: {
      marginTop: theme.spacing(10),
    },
    root: {
      height: "100vh",
    },
    image: {
      backgroundImage: `url(${backdrop})`,
      backgroundRepeat: "no-repeat",
      backgroundColor: "#210c51",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      backgroundColor: "red",
      width: 100,
      height: 100
    },
    errorIcon: {
      fontSize: 100
    },
    signInBox: {
      width: "100%",
      maxWidth: 645,
      alignItems: "center",
    },

    footerBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    version: {
      fontSize: 11,
    },
    content: {
      width: "100%",
      minHeight: 300,
      alignItems: "center",
      padding: theme.spacing(5),
      textAlign: "center"
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      padding: `6px 16px`,
      height: "unset",
    },
    moreInfo: {
      marginTop: theme.spacing(2),
    },
    errorCode: {
      fontSize: 11
    },
  });

interface RouteProps {
  code?: string;
  returnUrl?: string;
  supportUrl?: string
}
interface Location {
  hash?: string;
  key?: string;
  pathname?: string;
  search?: string;
}
interface PublicProps {
  match?: { params: RouteProps };
  location?: Location;
}


// StateProps
interface StateProps {
  appsettings?: AppSettingsState;
  stringLocalizer: StringLocalizer;
}

interface DispatchProps {
  loadErrorDetails: DispatchThunkAction<typeof loadErrorDetails>
}

type ErrorPageProps = StateProps &
ReactCookieProps &
PublicProps &
RouterProps &
DispatchProps &
RouteComponentProps<RouteProps> &
WithStyles<typeof styles>;

interface ErrorDetail {
  Id?: string;
  Name?: string;
  Message?: string;
  Anacdote?: string;
  Localized?: boolean;
  returnUrl?: string;
}

interface ErrorPageState {
  errorDetail?: ErrorDetail
}

class ErrorPage extends React.PureComponent<ErrorPageProps, ErrorPageState> {
  constructor(props: ErrorPageProps) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    const { match, loadErrorDetails } = this.props;
    void loadErrorDetails(match?.params.code as string).then((res) => {
      if (res.type === ActionTypes.ErrorDetailSuccess) {
        const resp = res.response as ErrorDetail;
        this.setState({ errorDetail: resp });
      }
    })
  }

  renderPrivacyPolicy() {
    // Render the privacy policy link
    return (
      <Typography variant="body2" align="center">
        <Link color="inherit" href={`${process.env.PUBLIC_URL}/privacy`} variant="body2">Privacy Policy</Link>{" "}
      </Typography>
    );
  }

  handleClick(returnUrl: string) {
    if (window) {
      const url = (returnUrl && returnUrl.trim().length > 2) ? returnUrl : `${process.env.PUBLIC_URL}/login`;
      window.location.assign(url);
    }
  }

  handleSupport(supportUrl: string) {
    window.location.assign(supportUrl);
  }

  renderDefaultPage() {
    const { classes, stringLocalizer, appsettings } = this.props;
    const { errorDetail } = this.state;
    const search = new URLSearchParams(this.props.location?.search);
    const supportUrl = search.get("supportUrl");
    return (
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <ErrorIcon className={classes.errorIcon} />
        </Avatar>
        <Typography variant="h5">
                    Whoops!!!
        </Typography>
        <Box className={classes.content}>
          <React.Fragment>
            <Typography variant="body1">
              {stringLocalizer(errorDetail?.Message as string)}
            </Typography>
            <Box className={classes.moreInfo}>
              <Typography className={classes.errorCode}>
                                Error Code: {stringLocalizer(errorDetail?.Id as string)}
              </Typography>
            </Box>
          </React.Fragment>
          <React.Fragment>
            <Button
              id="go-home"
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
              onClick={() => this.handleClick(search.get("returnUrl") as string)}
            >
              {stringLocalizer("Go Home")}
            </Button>

            {supportUrl && (<Button
              id="report-error"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => this.handleSupport(supportUrl)}
            >
              {stringLocalizer("Report Error")}
            </Button>)}

          </React.Fragment>
        </Box>
        <Box className={classes.footerBox}>
          <Box>{this.renderPrivacyPolicy()}</Box>
          <Box mt={1}>
            <Typography className={classes.version} variant="body2">
              {appsettings?.Version ? `v${appsettings.Version}` : ""}
            </Typography>
          </Box>
        </Box>
      </div>
    );
  }

  public render() {
    const { classes } = this.props;
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            {this.renderDefaultPage()}
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const stringLocalizer = stringLocalizerSelector(state);
  return {
    loading: makeLoadingSelector([
      ActionTypes.ErrorDetailRequest
    ])(state),
    stringLocalizer,
  };
};

const mapDispatchToProps = {
  loadErrorDetails: loadErrorDetails
}


export default compose(
  withCookies,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ErrorPage) as React.ComponentType<PublicProps>;