// React/Redux Library Imports
import * as React from "react";

// Redux-Store Imports
import { User } from "../../abstractions/user/User";
import { DocumentsAction } from "../../state/documents/actions";

// Material-ui Imports
import {
  Avatar,
  createStyles,
  WithStyles,
  withStyles,
  Button,
} from "@material-ui/core";
import ImageIcon from "@mui/icons-material/Image";
import AccountCircle from "@mui/icons-material/AccountCircleOutlined";

// Component Imports
import FileUpload from "./FileUpload/FileUpload";

// Other Imports
import clsx from "clsx";

const styles = () =>
  createStyles({
    root: {},
    avatar: {
      border: "3px white solid",
      color: "white",
      backgroundColor: "#4a4a57",
      fontSize: "16px",
    },
    uploadButton: {
      color: "white",
      position: "absolute",
      top: 0,
      left: 0,
      borderRadius: "50%",
      width: "100%",
      height: "100%",
      padding: 0,
      zIndex: 2,
      visibility: "hidden",
      border: "3px white solid",
      fontSize: 16,
      fontWeight: "bold",
      textTransform: "none",
    },
    avatarWrapper: {
      position: "relative",
      "&:hover": {
        "& #upload-button": {
          visibility: "visible",
        },
      },
      "& #fileupload-error-snack": {
        visibility: "visible",
      },
    },
  });

interface NexusAvatarProps extends WithStyles<typeof styles> {
  user?: User;
  showUploadButton?: string | undefined | boolean;
  updateProfilePicture?: (action: DocumentsAction) => unknown;
  toggleState?: ()=>void;
}

const NexusAvatar = (props: NexusAvatarProps) => {
  const { classes, user, showUploadButton, toggleState } = props;

  if (user) {
    const initials = user?.Name?.split(/\s/).reduce(
      (response, word) => (response += word.slice(0, 1)),
      ""
    );

    return (
      <div className={clsx(classes.avatarWrapper, classes.root)}>
        {showUploadButton==='false' && (
          <Button component={'div'} className={clsx(classes.uploadButton)} id="upload-button" variant="contained">
            <ImageIcon /> Upload Image
            <FileUpload
              input={true}
              inputProps={{ accept: ".jpg,.png,.bmp" }}
              onDocumentCreated={props.updateProfilePicture}
              MaximumFileSize={2097152}
              customErrorMessage="Please upload a .jpg or .png file only, and file size should not exceed 2 mb."
              toggleState={toggleState}
              uploadProgress={{}}
            />
          </Button>
        )}
        <Avatar
          className={classes.avatar}
          src={
            user.ProfilePictureId ? `/Documents/${user.ProfilePictureId}` : ""
          }
          alt={user.Name}
        >
          {initials}
        </Avatar>
      </div>
    );
  } else {
    return (
      <div className={clsx(classes.avatarWrapper, classes.root)}>
        <AccountCircle />
      </div>
    );
  }
};

export default withStyles(styles)(NexusAvatar);
