// React/Redux Library Imports
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

// Redux-Store Imports
import actions from "../../../state/actions";
import {
  DocumentsAction,
  completeChunkUpload,
} from "../../../state/documents/actions";
import { ApplicationState, DispatchThunkAction } from "../../../state";

// Material-ui Imports
import {
  withStyles,
  WithStyles,
  createStyles,
  Button,
  Input,
  Menu,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import Alert from "@mui/material/Alert";

// Component Imports
import AddFileUrlModal from "./AddFileUrlModal";

// Other Imports
import { forEach, isEmpty, mapValues } from "lodash";
import { FileDrop } from "react-file-drop";
import { FileDefinition } from "../../../abstractions/file/FileDefinition";
import {
  StringLocalizer,
  stringLocalizerSelector,
} from "state/localization/selectors";

import { getChunks } from "helpers/general";
import { cancelable, CancelablePromise } from "cancelable-promise";
import { Sema } from "async-sema";

// const ALLOWED_FILE_TYPES_REGEX = /jpg|jpeg|png|mp4|doc|docx|txt|pdf/i;
const ALLOWED_FILE_TYPES_REGEX = /doc|docx|pdf/i;
const styles = () =>
  createStyles({
    root: {
      position: "absolute",
      width: 200,
      height: 200,
      opacity: 0,
      cursor: "pointer",
    },
  });

const { uploadDocument } = actions;

const getMaxUploadSize = (MaximumFileSize: number | undefined) =>
  MaximumFileSize && !isNaN(MaximumFileSize) ? MaximumFileSize : 1048576;

type getErrorStringParams = {
  blankFiles?: string[];
  overSizedFiles?: string[];
  incorrectFiletypeFiles?: string[];
  infos?: {
    maxUploadSize?: number;
    customErrorMessage?: string;
  };
};

interface StateProps {
  stringLocalizer: StringLocalizer;
}

interface PublicProps {
  input?: boolean;
  inputProps?: Record<string, unknown>;
  onDocumentCreated?: (actions: DocumentsAction) => unknown;
  onUrlAdded?: (files: FileDefinition[]) => void;
  urlLocation?: string;
  fileDrop?: boolean;
  fileDropProps?: Record<string, unknown>;
  menu?: boolean;
  files?: FileDefinition[];
  id?: string;
  MaximumFileSize?: number;
  AllowChunking?: boolean;
  ChunkSize?: number;
  concurrency?: number;
  onUpdateProgress?: (value: { [filename: string]: number }) => void;
  onSaveOperations?: (operations: CancelablePromise<any>[]) => void;
  isUploading?: boolean;
  customErrorMessage?: string;
  error?: boolean;
  toggleState?: (val?: string) => void;
  handelError?: (val?: string) => void;
  uploadProgress?: { [filename: string]: number };
}

interface DispatchProps {
  uploadDocument: DispatchThunkAction<typeof uploadDocument>;
  completeChunkUpload: DispatchThunkAction<typeof completeChunkUpload>;
}

type FileUploadProps = StateProps &
PublicProps &
DispatchProps &
WithStyles<typeof styles>;

interface FileUploadState {
  menuAnchorEl: HTMLElement | null;
  urlModal: boolean;
  overSizedFiles?: string[];
  blankFiles?: string[];
  incorrectFiletypeFiles?: string[];
  uploadFilesLimit: boolean;
}

export class FileUpload extends React.Component<
  FileUploadProps,
  FileUploadState
> {
  constructor(props: FileUploadProps) {
    super(props);

    this.state = {
      menuAnchorEl: null,
      urlModal: false,
      overSizedFiles: undefined,
      blankFiles: undefined,
      incorrectFiletypeFiles: undefined,
      uploadFilesLimit: false,
    };
  }

  uploadDocuments = (selectedFiles: FileList) => {
    const {
      uploadDocument,
      urlLocation,
      onDocumentCreated,
      files,
      MaximumFileSize,
      AllowChunking,
      ChunkSize,
      concurrency,
      completeChunkUpload,
      onUpdateProgress,
      onSaveOperations,
      isUploading,
      inputProps,
    } = this.props;

    const MAX_UPLOAD_SIZE = getMaxUploadSize(MaximumFileSize);
    const metaData = {} as Record<string, unknown>;
    const fileData: File[] = [];
    const overSizedFiles: string[] = [];
    const blankFiles: string[] = [];
    const incorrectFiletypeFiles: string[] = [];
    const CHUNK_SIZE = ChunkSize && !isNaN(ChunkSize) ? ChunkSize : 10485760;
    const CONCURRENCY_LIMIT = concurrency || 5;

    const filesLength = files
      ? files.length + selectedFiles.length
      : selectedFiles.length;

    const { customErrorMessage } = this.props;
    const error = this.getErrorString({
      blankFiles,
      overSizedFiles,
      incorrectFiletypeFiles,
      infos: {
        maxUploadSize: getMaxUploadSize(MaximumFileSize),
        customErrorMessage,
      },
    });
    
    let acceptedFileTypes: string[] = [];

    if (inputProps && Array.isArray(inputProps.accept)) {
      acceptedFileTypes = inputProps.accept;
    } else if (inputProps && typeof inputProps.accept === "string") {
      acceptedFileTypes = inputProps.accept.split(",");
    }

    if (filesLength <= 1 && !isUploading) {
      forEach(selectedFiles, (item) => {
        const info = {
          // File Name
          Name: item.name,
          //File Size
          Size: item.size,
          //File URL to view
          Uri: URL.createObjectURL(item),
          // File Input Value
          _File: item,
        };
        
        if (info.Size == 0) {
          blankFiles.push(info.Name);
        }
        if (info.Size > MAX_UPLOAD_SIZE) {
          overSizedFiles.push(info.Name);
        }
        
        if (
          !ALLOWED_FILE_TYPES_REGEX.test(info.Name) &&
          inputProps === undefined
        ) {
          incorrectFiletypeFiles.push(info.Name);
        }

        if (acceptedFileTypes.length > 0) {
          const fileNameParts = info.Name.toLowerCase().split(".");
          const fileExtension = '.' + fileNameParts[fileNameParts.length - 1]; 
          if (!acceptedFileTypes.includes(fileExtension)) {
            incorrectFiletypeFiles.push(info.Name);
          }
        }

        metaData[item.name] = info;
        fileData.push(info._File);
      });

      if (!isEmpty(metaData)) {
        if (blankFiles && blankFiles.length) {
          this.onBlankFile(blankFiles);
          this.props?.handelError &&
            this.props?.handelError(
              `File size of ${blankFiles[0]} is zero byte.`
            );
        } else if (overSizedFiles && overSizedFiles.length) {
          this.onSizeLimitExceeded(overSizedFiles);
          this.props?.handelError && this.props?.handelError(error);
        } else if (incorrectFiletypeFiles && incorrectFiletypeFiles.length) {
          this.onIncorrectFileTypes(incorrectFiletypeFiles);
          this.props?.handelError &&
            this.props?.handelError(
              `File type of ${incorrectFiletypeFiles[0]} is not supported by our platform`
            );
        } else {
          const sema = new Sema(CONCURRENCY_LIMIT);
          const progresses = {};

          if (AllowChunking) {
            fileData.forEach((file: File) => {
              const chunks = getChunks(file, CHUNK_SIZE);

              progresses[file.name] = 0;

              const chunkedBlobUploadPromises = chunks.map((chunkedBlob) => {
                const formData = new FormData();

                formData.append("file", chunkedBlob, file.name);
                return sema
                  .acquire()
                  .then(() => {
                    const chunkedBlobUploadPromise = cancelable(
                      uploadDocument(formData, urlLocation)
                    );

                    if (onSaveOperations)
                      onSaveOperations([chunkedBlobUploadPromise]);

                    return chunkedBlobUploadPromise.then(
                      (action: DocumentsAction) => {
                        if (onUpdateProgress)
                          onUpdateProgress({
                            [file.name]: 100 / chunks.length,
                          });
                        return action;
                      }
                    );
                  })
                  .finally(() => {
                    sema.release();
                  });
              });

              const chunkingDonePromise = cancelable(
                Promise.all(chunkedBlobUploadPromises)
              );

              if (onSaveOperations) onSaveOperations([chunkingDonePromise]);

              void chunkingDonePromise.then((uploadSuccessActions) => {
                if (uploadSuccessActions.length == 1)
                  return setTimeout(() => {
                    onDocumentCreated?.(uploadSuccessActions[0]);
                  }, 500);

                const completePromise = cancelable(
                  completeChunkUpload(
                    uploadSuccessActions.map((action) => action.response!)
                  )
                );

                if (onSaveOperations) onSaveOperations([completePromise]);

                void completePromise.then((action) =>
                  onDocumentCreated?.(action)
                );
              });
            });

            if (onUpdateProgress) onUpdateProgress(progresses);
          } else {
            const formData = new FormData();
            const progresses = {};

            fileData.forEach((file) => {
              formData.append("files", file);
              progresses[file.name] = 0;
            });

            if (onUpdateProgress) onUpdateProgress(progresses);

            const uploadPromise = cancelable(
              uploadDocument(formData, urlLocation)
            );

            if (onSaveOperations) onSaveOperations([uploadPromise]);

            void uploadPromise.then((action) => {
              if (onUpdateProgress)
                onUpdateProgress(mapValues(progresses, () => 100));
              setTimeout(() => {
                onDocumentCreated?.(action);
              }, 500);
            });
          }
        }
      }
    } else {
      this.setState({ uploadFilesLimit: true });
    }
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props?.toggleState && this.props.toggleState();
    if (event?.target?.files && event.target.files.length > 1) {
      this.props?.handelError &&
        this.props?.handelError("Allowed to upload single file only");
    } else if (event.target.files) {
      this.uploadDocuments(event.target.files);
      // this is a little gross from a React standpoint but it resets the input
      event.target.value = "";
    }
  };

  onFileDrop = (files: FileList | null) => {
    if (
      !this.props.error &&
      this.props.uploadProgress &&
      Object.values(this?.props?.uploadProgress)[0] === undefined
    ) {
      if (files && files.length > 1) {
        this.props?.handelError &&
          this.props?.handelError("Allowed to upload single file only");
      } else if (files) {
        this.uploadDocuments(files);
      }
    }
  };

  onBlankFile = (filesList: string[]) => {
    this.props?.toggleState && this.props.toggleState("false");
    this.setState({ blankFiles: filesList });
  };

  onSizeLimitExceeded = (filesList: string[]) => {
    this.props?.toggleState && this.props.toggleState("false");
    this.setState({ overSizedFiles: filesList });
  };

  onIncorrectFileTypes = (filesList: string[]) => {
    this.props?.toggleState && this.props.toggleState("false");
    this.setState({ incorrectFiletypeFiles: filesList });
  };

  toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    const { menuAnchorEl } = this.state;
    this.setState({
      menuAnchorEl: menuAnchorEl ? null : event.currentTarget,
    });
  };

  toggleUrlModal = (/*event: React.MouseEvent<HTMLElement>*/) => {
    const { urlModal } = this.state;
    this.setState({ urlModal: !urlModal });
  };

  openUrlModal = (event: React.MouseEvent<HTMLElement>) => {
    this.toggleMenu(event);
    this.toggleUrlModal();
  };

  renderMenu = () => {
    const { classes, inputProps } = this.props;
    const { menuAnchorEl } = this.state;
    return (
      <Menu
        anchorEl={menuAnchorEl}
        id={"file-upload-menu"}
        keepMounted
        open={!!menuAnchorEl}
        onClose={this.toggleMenu}
      >
        <MenuItem
          id="from-computer-menu-item"
          onClick={this.toggleMenu}
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          From computer
          <Input
            classes={{ root: classes.root, input: classes.root }}
            type="file"
            id="input-file-from-computer"
            inputProps={{
              multiple: true,
              ...inputProps,
              // accept:
              //   ".mp4, .doc, .docx, .txt, .pdf, .jpg, .png, video/mp4, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain, application/pdf, image/jpeg, image/png",
              accept:
                ".doc, .docx, .pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf",
            }}
            onChange={this.onChange}
          />
        </MenuItem>
        <MenuItem
          onClick={this.openUrlModal}
          style={{ paddingTop: 0, paddingBottom: 0, display: "none" }}
        >
          From web
        </MenuItem>
      </Menu>
    );
  };

  closeErrorSnack = () => {
    this.setState({
      blankFiles: undefined,
      overSizedFiles: undefined,
      incorrectFiletypeFiles: undefined,
      uploadFilesLimit: false,
    });
  };

  getErrorString = (params?: getErrorStringParams) => {
    const { blankFiles, overSizedFiles, incorrectFiletypeFiles, infos } =
      params || {};

    if (infos && infos.customErrorMessage) return infos.customErrorMessage;

    const maxInMb =
      infos && infos.maxUploadSize && !isNaN(infos.maxUploadSize)
        ? infos.maxUploadSize / 1048576
        : 1;
    const suggestion =
      "Please upload the file to a different service such as Google Drive and use 'From Web' to attach the URL instead.";

    if (blankFiles?.length === 1) {
      return `File size of ${blankFiles[0]} is zero byte.`;
    } else if (blankFiles && blankFiles.length > 1) {
      return `File sizes of ${blankFiles.join(", ")} are zero byte.`;
    }

    if (overSizedFiles?.length === 1) {
      return `File size of ${overSizedFiles[0]} exceeds our ${maxInMb} mb size limit. ${suggestion}`;
    } else if (overSizedFiles && overSizedFiles.length > 1) {
      return `File sizes of ${overSizedFiles.join(
        ", "
      )} exceed our ${maxInMb} mb size limit. ${suggestion}`;
    }

    if (incorrectFiletypeFiles?.length === 1) {
      return `File type of ${incorrectFiletypeFiles[0]} is not supported by our platform.`;
    } else if (incorrectFiletypeFiles && incorrectFiletypeFiles.length > 1) {
      return `File types of ${incorrectFiletypeFiles.join(
        ", "
      )} are not supported by our platform.`;
    }

    return "";
  };

  render() {
    const {
      menu,
      input,
      fileDrop,
      fileDropProps,
      inputProps,
      onUrlAdded,
      children,
      classes,
      files,
      MaximumFileSize,
      customErrorMessage,
    } = this.props;

    const {
      urlModal,
      overSizedFiles,
      incorrectFiletypeFiles,
      uploadFilesLimit,
      blankFiles,
    } = this.state;

    const hasError = Boolean(
      blankFiles || overSizedFiles || incorrectFiletypeFiles || uploadFilesLimit
    );

    return (
      <React.Fragment>
        {fileDrop && (
          <FileDrop onDrop={this.onFileDrop} {...fileDropProps}>
            {children}
          </FileDrop>
        )}
        {input && (
          <Input
            classes={{ root: classes.root, input: classes.root }}
            type="file"
            id="input-profile-picture-upload"
            inputProps={inputProps}
            onChange={this.onChange}
          />
        )}
        {menu && !this.props.error && !files?.[0]?.Uri && (
          <React.Fragment>
            <Button
              id="add-files-button"
              onClick={this.toggleMenu}
              style={{
                background: "#5D1CD4",
                color: "white",
                textTransform: "none",
                fontSize: "13px"
              }}
            >
              Add file
            </Button>
            {this.renderMenu()}
            <AddFileUrlModal
              open={urlModal}
              onClose={this.toggleUrlModal}
              onAddFile={onUrlAdded}
              files={files}
            />
          </React.Fragment>
        )}
        {window.location?.href.includes("account") && (
          <Snackbar
            id="fileupload-error-snack"
            open={hasError}
            onClose={this.closeErrorSnack}
          >
            <Alert severity="error" onClose={this.closeErrorSnack}>
              {hasError &&
                (uploadFilesLimit
                  ? "Allowed to upload single file only"
                  : this.getErrorString({
                    blankFiles,
                    overSizedFiles,
                    incorrectFiletypeFiles,
                    infos: {
                      maxUploadSize: getMaxUploadSize(MaximumFileSize),
                      customErrorMessage,
                    },
                  }))}
            </Alert>
          </Snackbar>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const stringLocalizer = stringLocalizerSelector(state);
  return {
    stringLocalizer,
  };
};

const mapDispatchToProps = {
  uploadDocument,
  completeChunkUpload,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(FileUpload) as React.ComponentType<
  PublicProps & { classes?: Record<string, unknown> }
>;
