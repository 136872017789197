import { Reducer } from "redux";
import { Request } from "../../abstractions/request/Request";
import { ActionTypes } from "../enums/ActionTypes";
import {
  InsightRequestAction,
  InsightRequestChangePageAction,
  InsightRequestClearErrorAction,
  InsightRequestCompanyAction,
} from "./actions";
import { cloneDeep, merge, map } from "lodash";
import { RequestStatus } from "../../abstractions/request/RequestStatus";
import { InsightType } from "../../abstractions/insights/InsightType";
import { getUriObjectsToString } from "helpers/general";

export interface InsightRequestState {
  requests: Record<string, Request>;
  currentRequestId: string;
  selectedRequestId: string;
  errorMessage?: string | Record<string, string>;
  page: number;
  pageSize: number;
  filterQueryList: string[];
  sortQueryList: string[];
  searchModel: string;
  count: number;
  insightTypes?: InsightType[];
  requestRequiresPayment?: boolean;
  paymentCost?: string;
  paymentUrl?: string;
  SecretKey?: string;
  PublicKey?: string;
  countries?: string[];
  paymentTypes?: string[];
  newRequest?: Record<string, Request>;
  currentRequest?: Record<string, Request>;
  isEnterPriseClient?: boolean;
}

type InsightRequestActions =
  | InsightRequestAction
  | InsightRequestCompanyAction
  | InsightRequestClearErrorAction
  | InsightRequestChangePageAction;

const reducer: Reducer<
  InsightRequestState | undefined,
  InsightRequestActions
> = (state: InsightRequestState | undefined, action: InsightRequestActions) => {
  if (state === undefined) {
    return {
      requests: {},
      currentRequestId: "",
      selectedRequestId: "",
      page: 0,
      pageSize: 10,
      filterQueryList: [],
      sortQueryList: ["LastModificationTime desc"],
      searchModel: "",
      count: 0,
      SecretKey: "",
      PublicKey: "",
      countries: ["US", "CA"],
      paymentTypes: ["card", "ideal"],
      newRequest: {},
      currentRequest: {},
      insightTypes: []
    };
  }

  let returnState = state;

  switch (action.type) {
    case ActionTypes.InsightRequestSelect: {
      if (action.payload?.item) {
        const selectedId = action.payload.item.id || "";
        returnState = merge({}, state, {
          selectedRequestId: selectedId
        });
      }
      break;
    }

    case ActionTypes.InsightRequestChangePage: {
      const stateCopy = cloneDeep(state);
      if (action.page !== undefined) {
        stateCopy["page"] = action.page;
      }
      if (action.pageSize !== undefined) {
        stateCopy["pageSize"] = action.pageSize;
      }
      if (action.filterQueryList) {
        stateCopy["filterQueryList"] = action.filterQueryList.concat();
      }
      if (action.sortQueryList) {
        stateCopy["sortQueryList"] = action.sortQueryList;
      }
      if (typeof action.searchModel !== "undefined") {
        stateCopy["searchModel"] = action.searchModel;
      }
      returnState = merge({}, stateCopy);
      break;
    }

    case ActionTypes.InsightRequestSuccess: {
      if (action.response) {
        const newRequests = {} as Record<string, Request>;
        const request = action.response as Request;
        // Convert any attachment Uris into strings
        request.Attachments = getUriObjectsToString(request);
        newRequests[request.Id as string] = request;
        returnState = merge({}, state, {
          requests: newRequests,
          currentRequest: request
        });
      }
      break;
    }

    case ActionTypes.InsightRequestsSuccess: {
      if (action.response && action.response.entities) {
        const stateCopy = cloneDeep(state);
        if (action.refresh) {
          stateCopy.requests = {};
        }
        const newRequests = action.response.entities.request as Record<
          string,
          Request
        >;
        returnState = merge({}, stateCopy, {
          requests: newRequests,
          count: action.response["@odata.count"],
          page: action.page,
        });
      }
      break;
    }

    case ActionTypes.InsightRequestCountSuccess: {
      if (action?.response) {
        returnState = merge({}, state, {
          count: action.response,
        });
      }
      break;
    }

    case ActionTypes.InsightRequestEditSuccess:
    case ActionTypes.InsightRequestCreateSuccess: {
      if (action?.response?.Id && action.payload?.item) {
        const request = action.payload.item as Request;
        returnState = merge({}, state, {
          requests: {
            [action.response.Id as string]: request,
          },
          newRequest: request,
        });
      }
      break;
    }

    case ActionTypes.InsightRequestFinalizeDraftSuccess: {
      if (action?.payload?.item?.Id) {
        const request = state.requests[action.payload.item.Id as string];
        request.Status = RequestStatus?.Created;
        returnState = merge({}, state, {
          requests: {
            [request.Id as string]: request,
          },
        });
      }
      break;
    }

    case ActionTypes.InsightRequestFinalizeDraftFailure: {
      if (action?.response) {
        returnState = merge({}, state, {
          errorMessage: action.response,
        });
      } else if (action?.error) {
        if (action.status === 402) {
          // If it comes back 402, the request requires payment
          returnState = merge({}, state, {
            requestRequiresPayment: true,
            paymentCost: action.error,
          });
        } else {
          returnState = merge({}, state, {
            errorMessage: action.error
              ? action.error
              : "There was an error finalizing your draft",
          });
        }
      }
      break;
    }

    case ActionTypes.InsightRequestCreateDraftFailure: {
      if (action?.response) {
        returnState = merge({}, state, {
          errorMessage: action.response,
        });
      } else if (action?.error) {
        if (action.status === 402) {
          // If it comes back 402, the request requires payment
          returnState = merge({}, state, {
            requestRequiresPayment: true,
            paymentCost: action.error,
          });
        } else {
          returnState = merge({}, state, {
            errorMessage: action.error
              ? action.error
              : "There was an error creating your draft",
          });
        }
      }
      break;
    }

    case ActionTypes.InsightRequestEditFailure: {
      if (action?.response) {
        returnState = merge({}, state, {
          errorMessage: action.response,
        });
      } else if (action?.error) {
        returnState = merge({}, state, {
          errorMessage: action.error
            ? action.error
            : "There was an error editing your request",
        });
      }
      break;
    }

    case ActionTypes.InsightRequestCreateFailure: {
      if (action?.response) {
        returnState = merge({}, state, {
          errorMessage: action.response,
        });
      } else if (action?.error) {
        if (action.status === 402) {
          // If it comes back 402, the request requires payment
          returnState = merge({}, state, {
            requestRequiresPayment: true,
            paymentCost: action.error,
          });
        } else {
          // Otherwise it is a standard error
          returnState = merge({}, state, {
            errorMessage: action.error
              ? action.error
              : "There was an error creating your request",
          });
        }
      }
      break;
    }

    case ActionTypes.InsightRequestsClearError: {
      const stateCopy = cloneDeep(state);
      delete stateCopy.errorMessage;
      returnState = stateCopy;
      break;
    }

    case ActionTypes.InsightTypesSuccess: {
      if (action?.response) {
        returnState = merge({}, state, {
          insightTypes: map(action.response, (insightType) => insightType),
        });
      }
      break;
    }

    case ActionTypes.InsightRequestStartChargeSuccess: {
      if (action?.response?.Url) {
        returnState = merge({}, state, {
          paymentUrl: action.response.Url,
        });
      }
      break;
    }

    case ActionTypes.EnterpriseSuccess: {
      if (action?.response) {
        returnState = merge({}, state, {
          isEnterPriseClient: action.response.IsEnterPrise,
        });
      }
      break;
    }
    
    case ActionTypes.PaymentIntentSuccess: {
      if (action?.["response"]) {
        returnState = merge({}, state, {
          SecretKey: action?.response.Secret as string,
          PublicKey: action?.response.PublicKey as string,
          countries: action?.response?.Countries,
          paymentTypes: action?.response?.Types,
        });
      }
      break;
    }

    case ActionTypes.PaymentIntentFailure: {
      returnState = merge({}, state, { paymentError: true });
      break;
    }

    case ActionTypes.InsightRequestResetPaymentState: {
      const stateCopy = cloneDeep(state);
      delete stateCopy.requestRequiresPayment;
      delete stateCopy.paymentCost;
      delete stateCopy.paymentUrl;
      returnState = stateCopy;
      break;
    }

    case ActionTypes.InsightRequestsStatusUpdate: {
      if (action.payload?.item) {
        const stateCopy = cloneDeep(state);
        const updatedRequest = action.payload.item.request as Request;
        const requestToUpdate = stateCopy.requests[updatedRequest.Id!];
        if (requestToUpdate) {
          requestToUpdate.Status = updatedRequest.Status;
        }
        returnState = stateCopy;
      }
      break;
    }

    case ActionTypes.InsightRequestResetState: {
      const stateCopy = cloneDeep(state);
      stateCopy.requests = {};
      stateCopy.page = 0;
      stateCopy.pageSize = 10;
      returnState = stateCopy;
      break;
    }
  }

  return returnState;
};

export default reducer;
