import {
  applyMiddleware,
  combineReducers,
  createStore,
  Middleware,
  Store,
} from "redux";
import thunk from "redux-thunk";
import { routerReducer, routerMiddleware } from "react-router-redux";
import { History } from "history";
import { ApplicationState, reducers } from "../..";
import api from "../middleware/api";
import analytics from "../middleware/analytics";
import analyticsConfig from "../middleware/analyticsConfig";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { ActionTypes } from "../../enums/ActionTypes";

export default function configureStore(
  history: History,
  initialState?: ApplicationState
): Store {
  const middleware = [
    thunk as Middleware,
    api(history),
    analytics(analyticsConfig /*insert AnalyticsService here*/),
    routerMiddleware(history),
  ];

  const appReducer = combineReducers({
    ...reducers,
    router: routerReducer,
  });

  // This reducer runs first and has access to the entire state
  // it should only be used sparingly for actions that affect
  // the ENTIRE state, like clearing state after log out
  const rootReducer: typeof appReducer = (
    ...args: Parameters<typeof appReducer>
  ) => {
    // This is ugly but it fixes the typing without having to declare it explicitly
    const state = args[0];
    const action = args[1];
    let returnState = state;
    if (action && state) {
      // reset redux state except for appsettings on successful log out
      if (action.type === ActionTypes.LogoutSuccess) {
        returnState = {
          appsettings: state.appsettings,
          router: state.router,
        } as ReturnType<typeof appReducer>;
      }
    }
    return appReducer(returnState, action);
  };

  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
}
