import { CallApiAction } from "../shared/middleware/api";
import { ActionTypes } from "../enums/ActionTypes";
import { Endpoints } from "../enums/Endpoints";
import { AppThunkAction } from "..";
import { Login2FaModel, LoginResult, TwoFactorModel } from "../../types";

export interface TwoFactorAction extends CallApiAction {
  type:
    | typeof ActionTypes.Login2FaModelRequest
    | typeof ActionTypes.Login2FaModelSuccess
    | typeof ActionTypes.Login2FaModelFailure
    | typeof ActionTypes.Login2FaRequest
    | typeof ActionTypes.Login2FaSuccess
    | typeof ActionTypes.Login2FaFailure
    | typeof ActionTypes.TwoFactorModelRequest
    | typeof ActionTypes.TwoFactorModelSuccess
    | typeof ActionTypes.TwoFactorModelFailure
    | typeof ActionTypes.TwoFactorEnableRequest
    | typeof ActionTypes.TwoFactorEnableSuccess
    | typeof ActionTypes.TwoFactorEnableFailure
    | typeof ActionTypes.TwoFactorConfirmRequest
    | typeof ActionTypes.TwoFactorConfirmSuccess
    | typeof ActionTypes.TwoFactorConfirmFailure
    | typeof ActionTypes.TwoFactorByAppRequest
    | typeof ActionTypes.TwoFactorByAppSuccess
    | typeof ActionTypes.TwoFactorByAppFailure
    | typeof ActionTypes.TwoFactorAppConfirmRequest
    | typeof ActionTypes.TwoFactorAppConfirmSuccess
    | typeof ActionTypes.TwoFactorAppConfirmFailure
    | typeof ActionTypes.TwoFactorByEmailRequest
    | typeof ActionTypes.TwoFactorByEmailSuccess
    | typeof ActionTypes.TwoFactorByEmailFailure
    | typeof ActionTypes.TwoFactorEmailConfirmRequest
    | typeof ActionTypes.TwoFactorEmailConfirmSuccess
    | typeof ActionTypes.TwoFactorEmailConfirmFailure
    | typeof ActionTypes.TwoFactorRecoveryCodeRequest
    | typeof ActionTypes.TwoFactorRecoveryCodeSuccess
    | typeof ActionTypes.TwoFactorRecoveryCodeFailure
    | typeof ActionTypes.TwoFactorToggleSharedKey
    | typeof ActionTypes.TwoFactorClear
    | typeof ActionTypes.TwoFactorContinueToApp
    | typeof ActionTypes.TwoFactorChangeSetup
    | typeof ActionTypes.TwoFactorToggleShowVerificationForm
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  showSharedKey?: boolean;
  showVerificationForm?: boolean;
  response?: TwoFactorModel | Login2FaModel | LoginResult;
  payload?: {
    item?: TwoFactorModel | Login2FaModel | Record<string, unknown>;
    prevItem?: TwoFactorModel | Login2FaModel;
  };
}

export const setUpTwoFactor = (enable: boolean): TwoFactorAction => ({
  type: ActionTypes.TwoFactorChangeSetup,
  payload: { item: { enable: enable } },
});

export const setVerificationFormVisibility = (showVerificationForm: boolean): TwoFactorAction => ({
  type: ActionTypes.TwoFactorToggleShowVerificationForm,
  showVerificationForm,
});

const proceedToApp = (): TwoFactorAction => ({
  type: ActionTypes.TwoFactorContinueToApp,
});
export const continueToApp =
  (): AppThunkAction<TwoFactorAction> => (dispatch) => {
    return dispatch(proceedToApp());
  };

const clearTwoFactorState = (): TwoFactorAction => ({
  type: ActionTypes.TwoFactorClear,
});
export const clearTwoFactor =
  (): AppThunkAction<TwoFactorAction> => (dispatch) => {
    return dispatch(clearTwoFactorState());
  };

const setSharedKeyVisibility = (showSharedKey: boolean): TwoFactorAction => ({
  type: ActionTypes.TwoFactorToggleSharedKey,
  showSharedKey,
});
export const toggleSharedKey =
  (showSharedKey: boolean): AppThunkAction<TwoFactorAction> =>
    (dispatch) => {
      return dispatch(setSharedKeyVisibility(showSharedKey));
    };

// Fetch Login 2fa Model
const fetchLogin2FaModel = (): TwoFactorAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.Login2FaModelRequest,
      ActionTypes.Login2FaModelSuccess,
      ActionTypes.Login2FaModelFailure,
    ],
    endpoint: Endpoints.Login2Fa,
    useBase: true,
    disableRefresh: true,
    options: {
      acceptType: "application/json",
    },
  },
  type: ActionTypes.CallAPI,
});
export const login2FaModel =
  (): AppThunkAction<TwoFactorAction> => (dispatch) => {
    return dispatch(fetchLogin2FaModel());
  };

// Submit Login 2fa model
const submitLogin2Fa = (payload: Login2FaModel): TwoFactorAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.Login2FaRequest,
      ActionTypes.Login2FaSuccess,
      ActionTypes.Login2FaFailure,
    ],
    endpoint: Endpoints.Login2Fa,
    useBase: true,
    options: {
      method: "POST",
      body: JSON.stringify(payload),
      acceptType: "application/json",
    },
  },
  payload: {
    item: payload,
  },
  type: ActionTypes.CallAPI,
});
export const confirmLogin2FaCode =
  (payload: Login2FaModel): AppThunkAction<TwoFactorAction> =>
    (dispatch) => {
      return dispatch(submitLogin2Fa(payload));
    };

const fetchTwoFactorModel = (): TwoFactorAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.TwoFactorModelRequest,
      ActionTypes.TwoFactorModelSuccess,
      ActionTypes.TwoFactorModelFailure,
    ],
    endpoint: Endpoints.TwoFactor,
    useMy: true,
    disableRefresh: true,
    options: {
      acceptType: "application/json",
    },
  },
  type: ActionTypes.CallAPI,
});
export const getTwoFactorModel =
  (): AppThunkAction<TwoFactorAction> => (dispatch) => {
    return dispatch(fetchTwoFactorModel());
  };

// Set up Two Factor model for users that haven't yet enabled it, Step 1 of 2
const setupTwoFactorModelStep1 = (
  payload: TwoFactorModel
): TwoFactorAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.TwoFactorEnableRequest,
      ActionTypes.TwoFactorEnableSuccess,
      ActionTypes.TwoFactorEnableFailure,
    ],
    endpoint: `${Endpoints.TwoFactor}/Step1`,
    useMy: true,
    options: {
      method: "POST",
      body: JSON.stringify(payload),
      acceptType: "application/json",
    },
  },
  payload: {
    item: payload,
  },
  type: ActionTypes.CallAPI,
});
export const enableTwoFactorModelStep1 =
  (payload: TwoFactorModel): AppThunkAction<TwoFactorAction> =>
    (dispatch) => {
      return dispatch(setupTwoFactorModelStep1(payload));
    };

// Set up Two Factor model for users that haven't yet enabled it, Step 2 of 2
const setupTwoFactorModelStep2 = (
  payload: TwoFactorModel
): TwoFactorAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.TwoFactorConfirmRequest,
      ActionTypes.TwoFactorConfirmSuccess,
      ActionTypes.TwoFactorConfirmFailure,
    ],
    endpoint: `${Endpoints.TwoFactor}/Step2`,
    useMy: true,
    options: {
      method: "POST",
      body: JSON.stringify(payload),
      acceptType: "application/json",
    },
  },
  payload: {
    item: payload,
  },
  type: ActionTypes.CallAPI,
});
export const enableTwoFactorModelStep2 =
  (payload: TwoFactorModel): AppThunkAction<TwoFactorAction> =>
    (dispatch) => {
      return dispatch(setupTwoFactorModelStep2(payload));
    };
  
// Submit twofactor login by recovery code
const postRecoveryCode = (
  payload: Record<string, unknown>
): TwoFactorAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.TwoFactorRecoveryCodeRequest,
      ActionTypes.TwoFactorRecoveryCodeSuccess,
      ActionTypes.TwoFactorRecoveryCodeFailure,
    ],
    endpoint: Endpoints.TwoFactorRecovery,
    useBase: true,
    options: {
      method: "POST",
      body: JSON.stringify(payload),
      acceptType: "application/json",
    },
  },
  payload: {
    item: payload,
  },
  type: ActionTypes.CallAPI,
});
export const confirm2FaRecoveryCode =
  (code: string): AppThunkAction<TwoFactorAction> =>
    (dispatch) => {
      const payload = {
        Code: code,
      };
      return dispatch(postRecoveryCode(payload));
    };

export interface TwoFactorActionCreators {
  continueToApp: typeof continueToApp;
  clearTwoFactor: typeof clearTwoFactor;
  toggleSharedKey: typeof toggleSharedKey;
  login2FaModel: typeof login2FaModel;
  confirmLogin2FaCode: typeof confirmLogin2FaCode;
  getTwoFactorModel: typeof getTwoFactorModel;
  enableTwoFactorModelStep1: typeof enableTwoFactorModelStep1;
  enableTwoFactorModelStep2: typeof enableTwoFactorModelStep2;
  setUpTwoFactor: typeof setUpTwoFactor;
  confirm2FaRecoveryCode: typeof confirm2FaRecoveryCode;
}
