import { AnyAction, Dispatch, Middleware } from "redux";
import { AnalyticsConfig } from "./analyticsConfig";
import { AnalyticsService } from "./AnalyticsService";
import { Payload } from "../../index";
import { pick, omit, merge } from "lodash";

const includeFields = (fields: string[], payload: Payload) => {
  const returnPayload = { item: {}, prevItem: {} };
  if (payload.item) {
    returnPayload.item = pick(payload.item, fields);
  }
  if (payload.prevItem) {
    returnPayload.prevItem = pick(payload.prevItem, fields);
  }
  return returnPayload;
};

const excludeFields = (
  fields: string[],
  payload: {
    item?: Record<string, unknown>;
    prevItem?: Record<string, unknown>;
  }
) => {
  const returnPayload = { item: {}, prevItem: {} };
  if (payload.item) {
    returnPayload.item = omit(payload.item, fields);
  }
  if (payload.prevItem) {
    returnPayload.prevItem = omit(payload.prevItem, fields);
  }
  return returnPayload;
};

// Redux middleware that intercepts actions and then sends them
// to the AnalyticsService, based on settings in analyticsConfig.ts
const analyticsMiddleware = (
  analyticsConfig: AnalyticsConfig,
  analyticsService?: AnalyticsService
): Middleware => () => (next: Dispatch) => (action: AnyAction) => {
  let payload: Payload | undefined = undefined;

  if (
    analyticsConfig.actions &&
    analyticsConfig.actions[action.type as string]
  ) {
    const actionConfig = analyticsConfig.actions[action.type as string];
    if (
      actionConfig.track ||
      (typeof actionConfig.track === "undefined" &&
        analyticsConfig.trackAllByDefault)
    ) {
      if (
        (actionConfig.includePayload ||
          (typeof actionConfig.includePayload === "undefined" &&
            analyticsConfig.defaultIncludePayload)) &&
        action.payload
      ) {
        const actionPayload = action.payload as Payload;
        if (actionConfig.onlyIncludeFields) {
          payload = includeFields(
            actionConfig.onlyIncludeFields,
            actionPayload
          );
        } else if (actionConfig.excludeFields) {
          payload = excludeFields(actionConfig.excludeFields, actionPayload);
        } else if (analyticsConfig.defaultOnlyIncludeFields) {
          payload = includeFields(
            analyticsConfig.defaultOnlyIncludeFields,
            actionPayload
          );
        } else if (analyticsConfig.defaultExcludeFields) {
          payload = excludeFields(
            analyticsConfig.defaultExcludeFields,
            actionPayload
          );
        } else {
          payload = merge({}, actionPayload);
        }
      }
      if (analyticsService !== undefined) {
        analyticsService.trackEvent(action.type as string, payload);
      }
    }
  } else if (analyticsConfig.trackAllByDefault) {
    if (analyticsConfig.defaultIncludePayload && action.payload) {
      const actionPayload = action.payload as Payload;
      if (analyticsConfig.defaultOnlyIncludeFields) {
        payload = includeFields(
          analyticsConfig.defaultOnlyIncludeFields,
          actionPayload
        );
      } else if (analyticsConfig.defaultExcludeFields) {
        payload = excludeFields(
          analyticsConfig.defaultExcludeFields,
          actionPayload
        );
      } else {
        payload = merge({}, actionPayload);
      }
    }
    if (analyticsService !== undefined) {
      analyticsService.trackEvent(action.type as string, payload);
    }
  }

  return next(action);
};
export default analyticsMiddleware;
