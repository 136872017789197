// React/Redux Library Imports
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { ReactCookieProps, withCookies } from "react-cookie";
import { RouterProps } from "react-router";

// Material-UI Imports
import { createStyles, Theme } from "@material-ui/core/styles";
import { Typography, WithStyles, withStyles } from "@material-ui/core";
import { TextField, Button } from "@material-ui/core";

// Redux Store Imports
import actions from "../../state/actions";
import { ActionTypes } from "../../state/enums/ActionTypes";
import { ApplicationState, DispatchThunkAction } from "../../state";

// Other Imports
import AuthenticationLayout from "../shared/AuthenticationLayout";
import { makeLoadingSelector } from "../../state/loading/selectors";
import {
  StringLocalizer,
  stringLocalizerSelector,
} from "../../state/localization/selectors";
import { PasswordResetAction } from "../../state/authentication/actions";
import { Box, Link } from "@mui/material";
import { AppSettingsState } from "state/appsettings";

const EMAIL_VALID_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// Generating css styles for the component
const styles = (theme: Theme) =>
  createStyles({
    submitBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      padding: `6px 16px`,
      height: "unset",
    },
    modeButton: {
      height: "unset",
      margin: `24px 0px 16px`,
      padding: `6px 16px`,
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    version: {
      fontSize: 11,
      textAlign: "center",
    },
    syneHeadersText: {
      fontFamily: "Roboto, sans-serif",
      color: "#4710AE",
      fontWeight: "bold",
    },
  });

// Deconstructed actions
const { forgotPassword } = actions;

interface DispatchProps {
  forgotPassword: DispatchThunkAction<typeof forgotPassword>;
}

// StateProps
interface StateProps {
  appsettings?: AppSettingsState;
  loading: boolean;
  stringLocalizer: StringLocalizer;
}

interface ForgotPasswordState {
  editEmail: string;
  forgotComplete: boolean;
  showError: boolean;
  errorMessage: string;
  isDisable: boolean;
}

type ForgotPasswordProps = DispatchProps &
StateProps &
ReactCookieProps &
RouterProps &
WithStyles<typeof styles>;

class ForgotPassword extends React.PureComponent<
  ForgotPasswordProps,
  ForgotPasswordState
> {
  constructor(props: ForgotPasswordProps) {
    super(props);
    this.state = {
      editEmail: "",
      forgotComplete: false,
      showError: false,
      errorMessage: "",
      isDisable: false,
    };
  }

  // Validation Logic
  validateForm() {
    const { editEmail } = this.state;
    return String(editEmail)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      editEmail: event.target.value,
      showError: false,
      errorMessage: "",
    });
  };

  handleForgetPasswordSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    this.setState({ ...this.state, isDisable: true });
    const { forgotPassword } = this.props;
    const { editEmail } = this.state;

    event.preventDefault();
    if (this.isEmailValid()) {
      void forgotPassword(editEmail).then((action: PasswordResetAction) => {
        if (action.type === ActionTypes.ForgotPasswordSuccess) {
          this.setState({ forgotComplete: true, isDisable: false });
        } else {
          const actionErrorMessage = action.error?.toString();
          this.setState({
            showError: true,
            errorMessage: actionErrorMessage
              ? actionErrorMessage
              : "Something went wrong.",
            isDisable: false,
          });
        }
      });
    } else {
      this.setState({ showError: true, errorMessage: "Invalid email format." });
    }
  };

  renderErrorMessage(showError?: boolean, errorMessage?: string) {
    // Render the error message from the server
    return showError == true ? (
      <Typography variant="subtitle1" color="error">
        {errorMessage}
      </Typography>
    ) : (
      <></>
    );
  }

  isEmailValid() {
    const { editEmail } = this.state;
    return editEmail ? EMAIL_VALID_REGEX.test(editEmail) : false;
  }

  public render() {
    const { classes, loading, stringLocalizer } = this.props;
    const { editEmail, forgotComplete, showError, errorMessage } = this.state;

    return (
      <AuthenticationLayout>
        <div style={{ padding: "10%", width: "100%" }}>
          <Typography
            component="h1"
            variant="h5"
            className={classes.syneHeadersText}
          >
            {stringLocalizer("Forgot password")}
          </Typography>
          {!forgotComplete && (
            <Typography variant="body2">
              {stringLocalizer(
                "Enter your email below to receive a reset password link."
              )}
            </Typography>
          )}
          {forgotComplete && (
            <Typography variant="body2">
              {stringLocalizer(
                `Password reset instructions were sent to ${editEmail}`
              )}
            </Typography>
          )}
          <React.Fragment>
            {this.renderErrorMessage(showError, errorMessage)}
          </React.Fragment>
          <form
            id="submit-forgot-password-form"
            noValidate
            onSubmit={this.handleForgetPasswordSubmit}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              data-testid="email-input"
              label="Email address"
              name="email"
              value={editEmail}
              size="small"
              autoComplete="email"
              onChange={this.handleEmailChange}
              autoFocus
            />
            <Button
              id="submit-forgot-password"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!this.validateForm() || this.state.isDisable}
              className={classes.submit}
            >
              {!loading
                ? stringLocalizer("Reset password")
                : stringLocalizer("Processing...")}
            </Button>
          </form>
          <Typography variant="body2" align="center">
            <Link
              color="inherit"
              href={`${process.env.PUBLIC_URL}/privacy`}
              variant="body2"
              underline="always"
            >
              Privacy Policy
            </Link>{" "}
          </Typography>
          <Box mt={1}>
            <Typography className={classes.version} variant="body2">
              {this.props?.appsettings?.Version
                ? `v${this.props?.appsettings.Version}`
                : ""}
            </Typography>
          </Box>
        </div>
      </AuthenticationLayout>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  appsettings: state.appsettings,
  loading: makeLoadingSelector([ActionTypes.ForgotPasswordRequest])(state),
  stringLocalizer: stringLocalizerSelector(state),
});

const mapDispatchToProps = {
  forgotPassword,
};

export default compose(
  withCookies,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ForgotPassword) as React.ComponentType;
