import { schema } from "normalizr";
import { userGroupSchema } from "./groupSchema";

export const userSchema = new schema.Entity(
  "users",
  {
    Groups: [userGroupSchema]
  },
  {
    idAttribute: (user: Record<string, string>) => user.Id,
  }
);
