// React/Redux library imports
import * as React from "react";
import { Link } from "react-router-dom";

// Material-ui Imports
import {
  createStyles,
  WithStyles,
  withStyles,
  Typography,
  Button,
} from "@material-ui/core";

const styles = () =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: 30,
    },
    text404: {
      fontSize: 200,
    },
    text: {
      marginTop: 20,
    },
    icon: {
      width: 300,
      height: 300,
    },
    button: {
      marginTop: 20,
      "&:hover": {
        textDecoration: "none",
      },
    },
  });

const NotFound = (props: WithStyles<typeof styles>) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.text404}>
        404
      </Typography>
      <Typography className={classes.text}>
        Sorry! The page you&#39;re looking for does not exist.
      </Typography>
      <Link to={`${process.env.PUBLIC_URL}/`}>
        <Button variant="contained" color="primary" className={classes.button}>
          Click here to return home
        </Button>
      </Link>
    </div>
  );
};

export default withStyles(styles)(NotFound);
