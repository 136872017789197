import { CallApiAction } from "../shared/middleware/api";
import { ActionTypes } from "../enums/ActionTypes";
import { Endpoints } from "../enums/Endpoints";
import { Action } from "redux";
import { AppThunkAction } from "../";

export interface ErrorsAction extends Action {
  type:
    | typeof ActionTypes.ErrorDetailRequest
    | typeof ActionTypes.ErrorDetailSuccess
    | typeof ActionTypes.ErrorDetailFailure
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  response: {
    Id: string;
    Name: string;
    Message: string;
    Anacdote: string;
    Localized: boolean;
  }
}

// Fetches Error Details info from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchErrorDetails = (code: string): CallApiAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.ErrorDetailRequest,
      ActionTypes.ErrorDetailSuccess,
      ActionTypes.ErrorDetailFailure,
    ],
    endpoint: `${Endpoints.Errors}/Details/${code}`,
  },
  type: ActionTypes.CallAPI,
});

export const loadErrorDetails = (code: string): AppThunkAction<CallApiAction> => (
  dispatch
) => {
  return dispatch(fetchErrorDetails(code));
};

export interface ErrorsActionCreators {
  loadErrorDetails: typeof loadErrorDetails;
}
