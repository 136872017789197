import merge from "lodash/merge";
import { Reducer } from "redux";
import { AccountAction, PoliciesAction } from "./actions";
import { ActionTypes } from "../enums/ActionTypes";

export interface AccountState {
  FirstName: string;
  LastName: string;
  Name: string;
  Id: string;
  Email: string;
  IsActive: boolean;
  ProfileTypes: string;
  PhoneNumber: string;
  CreationTime: string;
  CreatorUserId: string;
  LastModificationTime: string;
  Groups: [];
  Policies?: Record<string, boolean>;
  Teams?: [];
  ProfilePictureId?: string;
  Amr?: Record<string, boolean>;
}

// Updates account cache in response to unknown action with response.account.
const reducer: Reducer<AccountState | undefined, AccountAction | PoliciesAction> = (
  state: AccountState | undefined,
  action: AccountAction | PoliciesAction
) => {
  if (action.type === ActionTypes.AccountSuccess && action.response) {
    return merge({}, state, action.response) as AccountState;
  }

  if (action.type === ActionTypes.PoliciesSuccess && action.response) {
    return merge({}, state, { Policies: action.response }) as AccountState;
  }

  if (state === undefined) {
    return {
      FirstName: "",
      LastName: "",
      Name: "",
      Id: "",
      Email: "",
      IsActive: false,
      ProfileTypes: "",
      PhoneNumber: "",
      CreationTime: "",
      CreatorUserId: "",
      LastModificationTime: "",
      Groups: [],
      Policies: {},
      Amr: {}
    } as AccountState;
  }


  const returnState = state;

  return returnState;
};

export default reducer;