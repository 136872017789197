import * as React from "react";
import { useLocation } from "react-router";
import NavDrawer from "./NavMenu/NavDrawer/NavDrawer";

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: React.FunctionComponent<LayoutProps> = (props: LayoutProps) => {
  const location = useLocation();
  return (
    <React.Fragment>
      <NavDrawer location={location}>{props.children}</NavDrawer>
    </React.Fragment>
  );
};

export default Layout;
