import { Reducer } from "redux";
import { ActionTypes } from "../enums/ActionTypes";
import { Insight } from "../../abstractions/insights/Insight";
import { InsightGroup } from "../../abstractions/insights/InsightGroup";
import { InsightsAction } from "./actions";
import { merge, filter, map, cloneDeep } from "lodash";

export interface InsightsState {
  insights: Record<string, { Dias: Insight[]; Sentiments: Insight[] }>;
  insightGroups?: InsightGroup[];
}

type InsightsActions = InsightsAction;

const filterAndSortInsights = (insightArray: Insight[]) => {
  // Remove "Other" from insight categories to display
  insightArray = insightArray.filter((insight) => {
    if (insight.Group) {
      return (
        !new RegExp(/Other/).test(insight.Group.Name) &&
        new RegExp(/(Female)|(Male)|(All)/).test(insight.Group.Name)
      );
    } else {
      return true;
    }
  });

  // Sort insights alphabetically, with no Group at the front
  insightArray.sort((a, b) => {
    if (a.Group) {
      if (b.Group) {
        if (a.Group.Ordinal && b.Group.Ordinal) {
          return a.Group.Ordinal - b.Group.Ordinal;
        }
        return a.Group.Name.localeCompare(b.Group.Name);
      }
      return 1;
    }
    return -1;
  });

  return insightArray;
};

const reducer: Reducer<InsightsState | undefined, InsightsActions> = (
  state: InsightsState | undefined,
  action: InsightsActions
) => {
  if (state === undefined) {
    return {
      insights: {},
    };
  }

  let returnState = state;

  switch (action.type) {
    case ActionTypes.InsightsSuccess: {
      if (
        action.response &&
        action.payload &&
        action.payload.item &&
        action.payload.item.insightsType &&
        action.payload.item.requestId
      ) {
        returnState = cloneDeep(state);
        delete returnState.insights[action.payload.item.requestId as string];

        const insightArray = map(action.response, (insight) => {
          const insightWithGroup = cloneDeep(insight) as Insight;
          const insightGroup = filter(
            state.insightGroups,
            (group) => group.Id === insightWithGroup.GroupId
          );
          insightWithGroup.Group = insightGroup.length
            ? insightGroup[0]
            : undefined;
          return insightWithGroup;
        });

        returnState = merge({}, returnState, {
          insights: {
            [action.payload.item.requestId as string]: {
              [action.payload.item.insightsType as string]:
                filterAndSortInsights(insightArray),
            },
          },
        });
      }
      break;
    }

    case ActionTypes.InsightsAttributeGroupsSuccess: {
      if (action.response) {
        returnState = merge({}, returnState, {
          insightGroups: action.response,
        });
      }
    }
  }

  return returnState;
};

export default reducer;
