// React/Redux library imports
import * as React from "react";
// Material-ui Imports
import {
  createStyles,
  WithStyles,
  withStyles,
  Typography,
  Button,
  Theme,
} from "@material-ui/core";
import AuthenticationLayout from "components/shared/AuthenticationLayout";

const styles = (theme: Theme) =>
  createStyles({
    logoutBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
      padding: `6px 16px`,
      height: "unset",
      fontSize:16
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  });

const Activation = (props: WithStyles<typeof styles>) => {
  const { classes } = props;

  const handleLogin = () => {
    if (window) {
      window.location.assign(`${process.env.PUBLIC_URL}/login`);
    }
  };
  return (
    <AuthenticationLayout>
      <div className={classes.paper}>
        <Typography id="activation-message" component="span" variant="body2">
          Your account is acitvated. Please click on login to continue.
        </Typography>
        <Button
          onClick={handleLogin}
          fullWidth
          variant="contained"
          id="sign-in-again"
          color="primary"
          className={classes.logoutBtn}
        >
          Back to sign in
        </Button>
      </div>
    </AuthenticationLayout>
  );
};

export default withStyles(styles)(Activation);
