// React/Redux library imports
import * as React from "react";
import { Link } from "react-router-dom";
import backdrop from "../../assets/images/Hero-Pattern.svg";
// Material-ui Imports
import {
  createStyles,
  WithStyles,
  withStyles,
  Typography,
  Button,
  Theme,
  Grid,
  CssBaseline,
  Paper,
  Box,
} from "@material-ui/core";
import AutoComplete from "material-ui/AutoComplete";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: 460,
      margin: `${theme.spacing(0)} auto`,
    },
    contentPage: {
      marginBottom: theme.spacing(3),
      lineHeight: 1.6,
    },
    contentPageLi: {
      marginBottom: theme.spacing(3),
      lineHeight: 1.8,
    },
    titles: {
      marginBottom: theme.spacing(2),
    },

    image: {
      backgroundImage: `url(${backdrop})`,
      backgroundRepeat: "no-repeat",
      backgroundColor: "#210c51",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    centerContainer: {
      margin: `${theme.spacing(0)} auto`,
      padding: `70px 50px `,
    },
    bullets: {
      paddingLeft: `10px`,
    },
    header: {
      fontSize: `24px`,
    },
  });

const PrivacyPage = (props: WithStyles<typeof styles>) => {
  const { classes } = props;

  return (
    <Box>
      <Grid container component="main" className={classes.image}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          component={Paper}
          elevation={6}
          square
          className={classes.centerContainer}
        >
          <div>
            <p className={classes.header}>
              <strong> Privacy Policy </strong>
            </p>
            <p className={classes.contentPage}>
              Protecting your private information is our priority. This
              Statement of Privacy applies to XStereotype App, and XSTEREOTYPE
              INC. and governs data collection and usage. For the purposes of
              this Privacy Policy, unless otherwise noted, all references to
              XSTEREOTYPE INC. include{" "}
              <a href="//www.xstereotype.com/" target="_blank">
                www.xstereotype.com
              </a>{" "}
              and XSTEREOTYPE. The XSTEREOTYPE application is a software
              application. By using the XSTEREOTYPE application, you consent to
              the data practices described in this statement.
            </p>

            <p className={classes.header}>
              <strong> Collection of your Personal Information </strong>
            </p>
            <p className={classes.contentPage}>
              In order to better provide you with products and services offered,
              XSTEREOTYPE may collect personally identifiable information, such
              as your:
            </p>

            <ul>
              <li className={classes.bullets}>First and Last Name</li>
              <li className={classes.bullets}>E-mail Address</li>
              <li className={classes.bullets}>Employer</li>
              <li className={classes.bullets}>Job Title</li>
            </ul>
            
            <p className={classes.contentPage}>
              If you purchase XSTEREOTYPE's products and services, we collect
              billing and credit card information. This information is used to
              complete the purchase transaction.
            </p>
            
            <p className={classes.contentPage}>
              We do not collect any personal information about you unless you
              voluntarily provide it to us. However, you may be required to
              provide certain personal information to us when you elect to use
              certain products or services. These may include: (a) registering
              for an account; (b) entering a sweepstakes or contest sponsored by
              us or one of our partners; (c) signing up for special offers from
              selected third parties; (d) sending us an email message; (e)
              submitting your credit card or other payment information when
              ordering and purchasing products and services. To wit, we will use
              your information for, but not limited to, communicating with you
              in relation to services and/or products you have requested from
              us. We also may gather additional personal or non-personal
              information in the future.
            </p>

            <p className={classes.header}>
              <strong> Use of your Personal Information </strong>
            </p>
            <p className={classes.contentPage}>
              XSTEREOTYPE collects and uses your personal information to operate
              and deliver the services you have requested.
            </p>
            
            <p className={classes.contentPage}>
              XSTEREOTYPE may also use your personally identifiable information
              to inform you of other products or services available from
              XSTEREOTYPE and its affiliates.
            </p>

            <p className={classes.header}>
              <strong> Sharing Information with Third Parties </strong>
            </p>
            <p className={classes.contentPage}>
              XSTEREOTYPE does not sell, rent or lease its customer lists to
              third parties.
            </p>
            
            <p className={classes.contentPage}>
              XSTEREOTYPE may share data with trusted partners to help perform
              statistical analysis, send you email or postal mail, provide
              customer support, or arrange for deliveries. All such third
              parties are prohibited from using your personal information except
              to provide these services to XSTEREOTYPE, and they are required to
              maintain the confidentiality of your information.
            </p>
            
            <p className={classes.contentPage}>
              XSTEREOTYPE may disclose your personal information, without
              notice, if required to do so by law or in the good faith belief
              that such action is necessary to: (a) conform to the edicts of the
              law or comply with legal process served on XSTEREOTYPE or the
              site; (b) protect and defend the rights or property of
              XSTEREOTYPE; and/or (c) act under exigent circumstances to protect
              the personal safety of users of XSTEREOTYPE, or the public.
            </p>

            <p className={classes.header}>
              <strong> Right to Deletion </strong>
            </p>
            <p className={classes.contentPage}>
              Subject to certain exceptions set out below, on receipt of a
              verifiable request from you, we will:
            </p>
            <ul>
              <li className={classes.bullets}>
                Delete your personal information from our records; and
              </li>
              <li className={classes.bullets}>
                Direct any service providers to delete your personal information
                from their records.
              </li>
            </ul>
            
            <p className={classes.contentPage}>
              Please note that we may not be able to comply with requests to
              delete your personal information if it is necessary to:
            </p>
            <ul>
              <li className={classes.bullets}>
                Complete the transaction for which the personal information was
                collected, fulfill the terms of a written warranty or product
                recall conducted in accordance with federal law, provide a good
                or service requested by you, or reasonably anticipated within
                the context of our ongoing business relationship with you, or
                otherwise perform a contract between you and us;
              </li>
              <li className={classes.bullets}>
                Detect security incidents, protect against malicious, deceptive,
                fraudulent, or illegal activity; or prosecute those responsible
                for that activity;
              </li>
              <li className={classes.bullets}>
                Debug to identify and repair errors that impair existing
                intended functionality;
              </li>
              <li className={classes.bullets}>
                Exercise free speech, ensure the right of another consumer to
                exercise his or her right of free speech, or exercise another
                right provided for by law;
              </li>
              <li className={classes.bullets}>
                Comply with the California Electronic Communications Privacy
                Act;
              </li>
              <li className={classes.bullets}>
                Engage in public or peer-reviewed scientific, historical, or
                statistical research in the public interest that adheres to all
                other applicable ethics and privacy laws, when our deletion of
                the information is likely to render impossible or seriously
                impair the achievement of such research, provided we have
                obtained your informed consent;
              </li>
              <li className={classes.bullets}>
                Enable solely internal uses that are reasonably aligned with
                your expectations based on your relationship with us;
              </li>
              <li className={classes.bullets}>
                Comply with an existing legal obligation; or
              </li>
              <li className={classes.bullets}>
                Otherwise use your personal information, internally, in a lawful
                manner that is compatible with the context in which you provided
                the information.
              </li>
            </ul>

            <p className={classes.header}>
              <strong> Children Under Thirteen </strong>
            </p>
            <p className={classes.contentPage}>
              XSTEREOTYPE does not knowingly collect personally identifiable
              information from children under the age of thirteen. If you are
              under the age of thirteen, you must ask your parent or guardian
              for permission to use this application.
            </p>

            <p className={classes.header}>
              <strong> E-mail Communications </strong>
            </p>
            <p className={classes.contentPage}>
              From time to time, XSTEREOTYPE may contact you via email for the
              purpose of providing announcements, promotional offers, alerts,
              confirmations, surveys, and/or other general communication. In
              order to improve our Services, we may receive a notification when
              you open an email from XSTEREOTYPE or click on a link therein.
            </p>
            
            <p className={classes.contentPage}>
              If you would like to stop receiving marketing or promotional
              communications via email from XSTEREOTYPE, you may opt out of such
              communications by selecting unsubscribe or changing their email
              options.
            </p>

            <p className={classes.header}>
              <strong> External Data Storage Sites </strong>
            </p>
            <p className={classes.contentPage}>
              We may store your data on servers provided by third party hosting
              vendors with whom we have contracted.
            </p>

            <p className={classes.header}>
              <strong> Changes to this Statement </strong>
            </p>
            <p className={classes.contentPage}>
              XSTEREOTYPE reserves the right to change this Privacy Policy from
              time to time. We will notify you about significant changes in the
              way we treat personal information by sending a notice to the
              primary email address specified in your account, by placing a
              prominent notice on our application, and/or by updating any
              privacy information. Your continued use of the application and/or
              Services available after such modifications will constitute your:
              (a) acknowledgment of the modified Privacy Policy; and (b)
              agreement to abide and be bound by that Policy.
            </p>

            <p className={classes.header}>
              <strong> Contact Information </strong>
            </p>
            <p className={classes.contentPage}>
              XSTEREOTYPE welcomes your questions or comments regarding this
              Statement of Privacy. If you believe that XSTEREOTYPE has not
              adhered to this Statement, please contact XSTEREOTYPE at: { " " }
              <a href="mailto:contact@xstereotype.com">
                contact@xstereotype.com
              </a>
            </p>
            <p className={classes.contentPage}>
              Effective as of January 01, 2023&nbsp;
            </p>
            <Link to={`${process.env.PUBLIC_URL}/`}>
              <Button variant="contained" color="primary">
                Click here to return home
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withStyles(styles)(PrivacyPage);
