import { createSelector, OutputSelector } from "reselect";
import { forEach, filter, map } from "lodash";
import { ApplicationState } from "../";
import { UserNotification } from "../../abstractions/userNotification/UserNotification";
import {
  StringLocalizer,
  stringLocalizerSelector,
} from "../localization/selectors";

const getNotificationGroups = (stringLocalizer: StringLocalizer) => ({
  REQUESTS: [
    {
      id: 5,
      displayName: stringLocalizer("NotificationLabel.RequestCreated"),
      value: "RequestCreated",
      enabled: true,
    },
    {
      id: 15,
      displayName: stringLocalizer("NotificationLabel.RequestCompleted"),
      value: "RequestCompleted",
      enabled: true,
    },
  ],
});

export type NotificationCategories = {
  index: number;
  name: string;
  group: string;
  notificationTypes: {
    id: number;
    displayName: string;
    value: string;
    enabled: boolean;
  }[];
}[];

export const getNotificationCategories = (
  stringLocalizer: StringLocalizer
): NotificationCategories => {
  const notificationGroups = getNotificationGroups(stringLocalizer);
  return [
    {
      index: 1,
      name: stringLocalizer("Requests"),
      group: "REQUESTS",
      notificationTypes: [
        {
          id: 0,
          displayName: stringLocalizer("- None -"),
          value: "",
          enabled: true,
        },
      ].concat(notificationGroups.REQUESTS),
    },
  ];
};

export const getNotificationQuery = (
  currentPage?: number,
  pageSize?: number,
  selectedFilter?: Record<string, unknown>,
  notificationTypes?: Record<string, unknown>[],
  sort?: {
    by: string;
    direction: string;
  }
): string => {
  let _url = "";
  const filtersList: string[] = [];

  if (selectedFilter && selectedFilter.value && selectedFilter.filterProp) {
    const query = `${selectedFilter.filterProp as string} eq ${
      selectedFilter.value as string
    }`;
    filtersList.push(query);
  }

  if (notificationTypes && notificationTypes.length && !filtersList.length) {
    let notificationTypesFilter = "";
    forEach(notificationTypes, (item) => {
      if (item.value) {
        if (notificationTypesFilter) {
          notificationTypesFilter = notificationTypesFilter + " OR ";
        }
        notificationTypesFilter =
          notificationTypesFilter +
          `NotificationType eq '${item.value as string}'`;
      }
    });
    if (notificationTypesFilter) {
      filtersList.push(`${notificationTypesFilter}`);
    }
  }

  if (sort && sort.by !== "default") {
    _url = _url + `?$orderby=${sort.by} ${sort.direction}`;
  } else {
    _url = _url + `?$orderby=creationTime desc`;
  }

  if (filtersList.length) {
    _url = _url + `&$filter=`;
    forEach(filtersList, (val: string, index) => {
      if (index > 0) _url = _url + " AND ";
      _url = _url + `(${val})`;
    });
  }

  let skipItems = 0;
  if (currentPage !== undefined && pageSize !== undefined) {
    skipItems = currentPage * pageSize;
  }

  _url =
    _url +
    `&$count=true&$top=${
      pageSize !== undefined ? pageSize : 10
    }&$skip=${skipItems}`;

  return _url;
};

const getCounts = (state: ApplicationState) => state.notifications?.count;
const getStringLocalizer = (state: ApplicationState) =>
  stringLocalizerSelector(state);

export const getCountsByGroup = createSelector(
  [getCounts, getStringLocalizer],
  (counts, stringLocalizer) => {
    const countsByGroup = {} as Record<string, number>;
    if (counts) {
      countsByGroup["ALL"] = counts["ALL"].Count;
      forEach(getNotificationGroups(stringLocalizer), (group, key) => {
        countsByGroup[key] = 0;
        forEach(group, (type) => {
          if (counts[type.value]) {
            countsByGroup[key] += counts[type.value].Count;
          }
        });
      });
    }
    return countsByGroup;
  }
);

const getNotifications = (state: ApplicationState) =>
  state.notifications?.notifications;
const getPage = (state: ApplicationState) => state.notifications?.page;
const getPageSize = (state: ApplicationState) => state.notifications?.pageSize;

export const makeNotificationByGroupSelector = (group: string) => {
  return createSelector(
    [getNotifications, getPage, getPageSize, getStringLocalizer],
    (notifications, page, pageSize, stringLocalizer) => {
      if (group.toUpperCase() === "ALL" && notifications) {
        const allNotifications = map(notifications, (note) => note);
        allNotifications.sort((a, b) => {
          if (a.CreationTime && b.CreationTime) {
            if (a.CreationTime > b.CreationTime) {
              return -1;
            } else if (a.CreationTime < b.CreationTime) {
              return 1;
            }
          }
          return 0;
        });
        if (page != undefined && pageSize) {
          return allNotifications.slice(
            page * pageSize,
            page * pageSize + pageSize
          );
        }
        return allNotifications;
      } else {
        const category = getNotificationCategories(stringLocalizer).find(
          (cat) => cat.group === group.toUpperCase()
        );
        const types = category?.notificationTypes.map((type) => type.value);
        const filteredNotifications = filter(
          notifications,
          (note: UserNotification) => {
            return !!(
              note.NotificationType &&
              types?.indexOf(note.NotificationType) !== -1
            );
          }
        );
        filteredNotifications.sort();
        if (page != undefined && pageSize) {
          return filteredNotifications.slice(
            page * pageSize,
            page * pageSize + pageSize
          );
        }
        return filteredNotifications;
      }
    }
  );
};
