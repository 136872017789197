import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import "./common.css";
import { CircularProgress } from "@mui/material";
import {
  InsightsAction,
  generateInsightReport,
} from "../../state/insights/actions";
import downloadIcon from "../../../src/assets/images/downloadIcon.png";
import MyCarousel from "./Carousel";
import { RequestStatus } from "abstractions/request/RequestStatus";

interface InsightTypes {
  Attachments: unknown[];
  CreationTime: string;
  CreatorUser: unknown;
  CreatorUserId: string;
  Description: string;
  EndDate: string;
  Id: string;
  Name: string;
  StartDate: string;
  State: string;
  Status: string;
  Tags: unknown;
  TypeId: string;
}

const RecentlyCreated = (props: {
  recentlyCreated: InsightTypes[];
  height?: string;
  width?: string;
  generateInsightReport: (requestId: string) => Promise<InsightsAction>;
}) => {
  const { recentlyCreated, height, width } = props;

  const onReportDownload = (requestId: string) => {
    const { generateInsightReport } = props;
    requestId &&
      void generateInsightReport(requestId).then((response) => {
        const b = response.response as unknown as Blob;
        const url = window.URL.createObjectURL(b);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${requestId}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      });
  };

  return (
    <>
      <div className="carousel" id="carouselContainer">
        <MyCarousel
          recentlyCreated={[
            ...recentlyCreated,
            ...recentlyCreated,
            ...recentlyCreated,
          ]}
          height={props.height}
          width={props.width}
          onReportDownload={onReportDownload}
        />
      </div>
      <div className="recentelyCreatedWebView">
        {recentlyCreated.map((ele) => {
          return (
            <div
              style={{
                height: height,
                width: width,
                background: "white",
                borderRadius: "5px",
              }}
              key={ele.Id}
              id="card"
            >
              <div className="recentlyCreatedCards" id="recentlyCreatedCards">
                <div className="downloadCOntainer">
                  {ele.Status === "Completed" ? (
                    <img
                      className="downloadImg"
                      id="download"
                      src={downloadIcon}
                      onClick={() => {
                        onReportDownload(ele.Id);
                      }}
                    />
                  ) : (
                    <CircularProgress
                      style={{ color: "#D9D9D9" }}
                      className="circularLoader"
                    />
                  )}
                </div>
                <div className="tableContent">
                  <p
                    style={{
                      margin: 0,
                      width: "70%",
                      color: "#270566",
                      fontWeight: "600",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {ele?.Name.length > 50
                      ? ele?.Name.substring(0, 50) + "..."
                      : ele?.Name}
                  </p>

                  <p style={{ margin: 0, color: "#5D1CD4", fontWeight: "600" }}>
                    {new Date(ele?.CreationTime).toLocaleDateString("en-US")}
                  </p>
                  <p className="statusContainer">
                    {ele?.Status === RequestStatus.NeedsIntervention
                      ? RequestStatus.Processing
                      : ele?.Status}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
const mapDispatchToProps = {
  generateInsightReport,
};
const mapStateToProps = () => ({});
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RecentlyCreated
);
