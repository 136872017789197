import { CallApiAction } from "../shared/middleware/api";
import { Action } from "redux";
import { AppThunkAction } from "../";
import { ActionTypes } from "../enums/ActionTypes";
import { Endpoints } from "../enums/Endpoints";
import { userSchema } from "../shared/schemas/userSchema";
import { getUserQuery } from "./selectors";
import { UserGroup } from "../../abstractions/user/UserGroup";
import { User } from "../../abstractions/user/User";
import { userGroupSchema } from "../shared/schemas/groupSchema";
import { map } from "lodash";

export interface UserAction extends CallApiAction {
  type:
  | typeof ActionTypes.UserRequest
  | typeof ActionTypes.UserSuccess
  | typeof ActionTypes.UserFailure
  | typeof ActionTypes.ProfileSuccess
  | typeof ActionTypes.UserUpdateRequest
  | typeof ActionTypes.UserUpdateSuccess
  | typeof ActionTypes.UserUpdateFailure
  | typeof ActionTypes.UserGroupDeleteRequest
  | typeof ActionTypes.UserGroupDeleteSuccess
  | typeof ActionTypes.UserGroupDeleteFailure
  | typeof ActionTypes.UserGroupRequest
  | typeof ActionTypes.UserGroupSuccess
  | typeof ActionTypes.UserGroupFailure
  | typeof ActionTypes.UserGroupAddRequest
  | typeof ActionTypes.UserGroupAddSuccess
  | typeof ActionTypes.UserGroupAddFailure
  | typeof ActionTypes.UsersCreateRequest
  | typeof ActionTypes.UsersCreateSuccess
  | typeof ActionTypes.UsersCreateFailure
  | typeof ActionTypes.CallAPI
  | typeof ActionTypes.CallODATA;
  response?: {
    entities?: {
      users?: Record<string, unknown>;
      usergroups?: Record<string, unknown>;
      teams?: Record<string, unknown>;
    };
    value?: Record<string, unknown>;
    [name: string]: unknown;
  };
  payload?: {
    item?: Record<string, unknown>;
    prevItem?: Record<string, unknown>;
  };
  refresh?: boolean;
  page?: number;
}

export interface ProfileTypesAction extends CallApiAction {
  type:
  | typeof ActionTypes.ProfileTypesRequest
  | typeof ActionTypes.ProfileTypesSuccess
  | typeof ActionTypes.ProfileTypesFailure
  | typeof ActionTypes.CallAPI
  | typeof ActionTypes.None;
  response?: Record<string, unknown>;
}

export interface UsersPageAction extends Action {
  type: typeof ActionTypes.UsersSetPage | typeof ActionTypes.None;
  page?: number;
  pageSize?: number;
  filterQueryList?: string[];
  sortQueryList?: string[];
  searchModel?: string;
}

// Changes users page, pageSize, filter query list,
// sort query list, or search model
const setUsersPage = (
  page?: number,
  pageSize?: number,
  filterQueryList?: string[],
  sortQueryList?: string[],
  searchModel?: string
) => ({
  type: ActionTypes.UsersSetPage,
  page,
  pageSize,
  filterQueryList,
  sortQueryList,
  searchModel,
});

export const changeUsersPage =
  (
    page?: number,
    pageSize?: number,
    filterQueryList?: string[],
    sortQueryList?: string[],
    searchModel?: string
  ): AppThunkAction<Action> =>
    (dispatch) => {
      return dispatch(
        setUsersPage(page, pageSize, filterQueryList, sortQueryList, searchModel)
      );
    };

// Fetches profile types enum from API
const fetchProfileTypes = (): ProfileTypesAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.ProfileTypesRequest,
      ActionTypes.ProfileTypesSuccess,
      ActionTypes.ProfileTypesFailure,
    ],
    endpoint: Endpoints.ProfileTypes,
  },
  type: ActionTypes.CallAPI,
});

export const loadProfileTypes =
  (): AppThunkAction<ProfileTypesAction> => (dispatch) => {
    return dispatch(fetchProfileTypes());
  };

// Fetches user profile from API given UserId
const fetchProfile = (UserId: string): UserAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.ProfileRequest,
      ActionTypes.ProfileSuccess,
      ActionTypes.ProfileFailure,
    ],
    endpoint: `${Endpoints.Users}/${UserId}`,
    schema: userSchema,
  },
  [ActionTypes.CallODATA]: {
    query: `?$select=Id,Firstname,Lastname,Name,Email,PhoneNumber,Username,ProfilePictureId,Groups,profileTypes,IsActive&groups($select=groupId;$expand=group($select=name))`,
  },
  type: ActionTypes.CallAPI,
});

export const loadProfile =
  (UserId: string): AppThunkAction<Action> =>
    (dispatch) => {
      return dispatch(fetchProfile(UserId));
    };

// Fetches user info from API given UserId
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchUser = (UserId: string): UserAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.UserRequest,
      ActionTypes.UserSuccess,
      ActionTypes.UserFailure,
    ],
    endpoint: Endpoints.Account,
    schema: userSchema,
    useMy: true,
  },
  [ActionTypes.CallODATA]: {
    query: `?$select=Id,Name,FirstName,LastName,PhoneNumber,Groups,profileTypes,IsActive&groups($select=groupId;$expand=group($select=name))`,
  },
  type: ActionTypes.CallAPI,
});

export const loadUser =
  (UserId: string): AppThunkAction<Action> =>
    (dispatch) => {
      return dispatch(fetchUser(UserId));
    };

// Fetches info for all users from API
const fetchAllUsers = (
  query: string,
  page?: number,
  refresh = true
): UserAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.UserRequest,
      ActionTypes.UserSuccess,
      ActionTypes.UserFailure,
    ],
    endpoint: Endpoints.Users,
    schema: [userSchema],
  },
  [ActionTypes.CallODATA]: {
    query: `${query}`,
  },
  page,
  refresh,
  type: ActionTypes.CallODATA,
});

export const loadAllUsers =
  (
    page?: number,
    pageSize?: number,
    filterQueryList?: string[],
    sortQueryList?: string[],
    searchModel?: string,
    refresh?: boolean
  ): AppThunkAction<UserAction> =>
    (dispatch, getState) => {
      const loadPage = page != undefined ? page : getState().users?.page;
      const loadPageSize = pageSize ? pageSize : getState().users?.pageSize;
      const loadFilterQueryList =
        typeof filterQueryList !== "undefined"
          ? filterQueryList
          : getState().users?.filterQueryList;
      const loadSortQueryList = sortQueryList
        ? sortQueryList
        : getState().users?.sortQueryList;
      const loadSearchModel =
        typeof searchModel !== "undefined"
          ? searchModel
          : getState().users?.searchModel;
      const query = getUserQuery(
        loadPage,
        loadPageSize,
        loadFilterQueryList,
        loadSortQueryList,
        loadSearchModel
      );
      return dispatch(fetchAllUsers(query, loadPage, refresh));
    };

// Fetches users by team
const fetchUsersByTeam = (attributeId: string, query = ""): UserAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.UserRequest,
      ActionTypes.UserSuccess,
      ActionTypes.UserFailure,
    ],
    endpoint: Endpoints.UsersByTeam,
    useMy: true,
    schema: [userSchema],
  },
  [ActionTypes.CallODATA]: {
    query: `/${attributeId}${query}`,
  },
  type: ActionTypes.CallODATA,
});


// Updates user info with a patch call to API
// In progress
const patchUser = (
  userId: string,
  payload: Record<string, unknown>,
  prevItem: Record<string, unknown>
): UserAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.UserUpdateRequest,
      ActionTypes.UserUpdateSuccess,
      ActionTypes.UserUpdateFailure,
    ],
    endpoint: `${Endpoints.Users}/${userId}`,

    options: {
      method: "PATCH",
      body: JSON.stringify(payload),
    },
  },
  payload: {
    item: { Id: userId, ...payload },
    prevItem: { Id: userId, ...prevItem },
  },
  type: ActionTypes.CallAPI,
});

export const updateUser =
  (payload: Record<string, unknown>): AppThunkAction<Action> =>
    (dispatch, getState) => {
      const { users } = getState();
      const userId = payload.Id as string;
      delete payload.Id;
      const prevItem = users?.users ? users.users[userId] : {};

      return dispatch(
        patchUser(userId, payload, prevItem as unknown as Record<string, unknown>)
      );
    };

// Adds a new User with a post call to API
const postUser = (payload: User): UserAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.UsersCreateRequest,
      ActionTypes.UsersCreateSuccess,
      ActionTypes.UsersCreateFailure,
    ],
    endpoint: Endpoints.Users,
    options: {
      method: "POST",
      body: JSON.stringify(payload),
    },
  },
  payload: {
    item: {
      payload,
    },
  },
  type: ActionTypes.CallAPI,
});

export const addUser =
  (payload: User): AppThunkAction<UserAction> =>
    (dispatch) => {
      return dispatch(postUser(payload));
    };

// Adds new Users with a batched post call to API
const postBatchUsers = (payload: User[]): UserAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.UsersCreateRequest,
      ActionTypes.UsersCreateSuccess,
      ActionTypes.UsersCreateFailure,
    ],
    endpoint: Endpoints.Batch,
    options: {
      method: "POST",
      // TODO: Batching
    },
  },
  [ActionTypes.CallODATA]: {
    query: "",
  },
  payload: {
    item: {
      payload,
    },
  },
  type: ActionTypes.CallODATA,
});

export const addUsers =
  (payload: User[]): AppThunkAction<UserAction> =>
    (dispatch) => {
      // TODO: Batching
      return dispatch(postBatchUsers(payload));
    };

// Load UserGroups and their Groups from the api
const getUserGroups = (query: string): UserAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.UserGroupRequest,
      ActionTypes.UserGroupSuccess,
      ActionTypes.UserGroupFailure,
    ],
    endpoint: Endpoints.UserGroups,
    schema: [userGroupSchema],
  },
  [ActionTypes.CallODATA]: {
    query: query,
  },
  type: ActionTypes.CallODATA,
});


// Adds new User Group
const postUserGroup = (userGroup: UserGroup): UserAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.UserGroupAddRequest,
      ActionTypes.UserGroupAddSuccess,
      ActionTypes.UserGroupAddFailure,
    ],
    endpoint: `${Endpoints.Users}/${userGroup.UserId}/Groups/${userGroup.GroupId}`,
    options: {
      method: "POST",
      body: JSON.stringify(userGroup),
    },
  },

  payload: {
    item: userGroup as unknown as Record<string, unknown>,
  },
  type: ActionTypes.CallAPI,
});

export const addUserGroup =
  (userGroup: UserGroup): AppThunkAction<Action> =>
    (dispatch) => {
      return dispatch(postUserGroup(userGroup));
    };

// Deletes a User Group
const deleteUserGroup = (userGroup: UserGroup): UserAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.UserGroupDeleteRequest,
      ActionTypes.UserGroupDeleteSuccess,
      ActionTypes.UserGroupDeleteFailure,
    ],
    endpoint: `${Endpoints.Users}/${userGroup.UserId}/Groups/${userGroup.GroupId}`,

    options: {
      method: "DELETE",
    },
  },
  payload: {
    item: userGroup as unknown as Record<string, unknown>,
    prevItem: userGroup as unknown as Record<string, unknown>,
  },
  type: ActionTypes.CallAPI,
});

export const removeUserGroup =
  (userGroup: UserGroup): AppThunkAction<Action> =>
    (dispatch, getState) => {
      // If the Id is undefined, it selects the first UserGroup Id
      // that is in the user.Groups array
      const users = getState().users?.users || {};

      const selectedUser = users[userGroup.UserId];
      const groups = selectedUser.Groups;

      const afterRemoved = groups?.filter((group) => {
        const curGroup = group as { GroupId?: string };
        return curGroup.GroupId !== userGroup.GroupId;
      });

      const toRemoveArr = groups?.filter((group) => {
        const curGroup = group as { GroupId?: string };
        return curGroup.GroupId === userGroup.GroupId;
      });

      if (
        toRemoveArr !== undefined &&
        toRemoveArr?.length > 0 &&
        afterRemoved !== undefined &&
        afterRemoved?.length >= 0
      ) {
        //selectedUser.Groups = [...afterRemoved]
        const toRemovedGroup = toRemoveArr[0] as unknown as {
          GroupId: string;
          Id?: string;
        };
        return dispatch(
          deleteUserGroup({
            Id: toRemovedGroup.Id,
            UserId: userGroup.UserId,
            GroupId: toRemovedGroup.GroupId,
          })
        );
      } else {
        return Promise.reject();
      }
    };

// Changes user email address
const postChangeEmail = (payload: { Email: string }, userId: string): UserAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.ChangeUserEmailRequest,
      ActionTypes.ChangeUserEmailSuccess,
      ActionTypes.ChangeUserEmailFailure,
    ],
    endpoint: `${Endpoints.Users}/${userId}/email`,
    options: {
      method: "POST",
      body: JSON.stringify(payload),
    },
  },
  payload: {
    item: payload,
  },
  type: ActionTypes.CallAPI,
});

export const changeUserEmail =
  (payload: { Email: string }, userId: string): AppThunkAction<Action> =>
    (dispatch) => {
      return dispatch(postChangeEmail(payload, userId));
    };

export interface UsersActionCreators {
  loadUser: typeof loadUser;
  updateUser: typeof updateUser;
  addUsers: typeof addUsers;
  loadAllUsers: typeof loadAllUsers;
  loadProfileTypes: typeof loadProfileTypes;
  changeUsersPage: typeof changeUsersPage;
  addUserGroup: typeof addUserGroup;
  removeUserGroup: typeof removeUserGroup;
  loadProfile: typeof loadProfile;
}
