import { createSelector } from "reselect";
import { ApplicationState } from "../";
import { AccountState } from "./reducers";
import { isEmpty } from "lodash";

/**
 * Type for hasPolicy function returned by hasPolicySelector
 *
 * @interface HasModule
 * @param policy {string} policy name
 * @returns boolean representing whether the user has the passed policy
 */
export type HasPolicy = (policy: string) => boolean;

const getAccount = (state: ApplicationState) => state.account;

/**
 * @function hasPolicySelector
 * @param state {ApplicationState} current state
 * @returns hasPolicy function to check for user policy
 */
export const hasPolicySelector = createSelector(
  [getAccount],
  (account?: AccountState) => {
    return (policy: string) => {
      if (!account || !account.Policies || isEmpty(account.Policies)) {
        return false;
      }
      return !!account.Policies[policy];
    };
  }
);
