// React Imports
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// Component Imports
import LoadingCircle from "../components/shared/LoadingCircle";
import NotFound from "../components/NotFound";
import Login from "../components/Login/Login";
import Logout from "../components/Logout/Logout";
import Layout from "../components/Layout";
import AuthorizedRoute from "./AuthorizedRoute";
import CreateUser from "../components/User/CreateUser";
import TwoFactor from "../components/TwoFactor/TwoFactor";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import ResetPassword from "../components/ResetPassword/ResetPassword";
import ChangeEmailLanding from "../components/Landing/ChangeEmailLanding";
import ErrorPage from "../components/Error/ErrorPage";
import Register from "../components/Register/Register";
import PrivacyPage from "../components/Privacy/PrivacyPage";
import Activation from "components/Activation/Activation";
import Home from "components/Home/Home";
import UpdateEmailChange from "components/UpdateEmail/UpdateEmailChange";



// Lazy Imports
const Dashboard = React.lazy(() => import("../components/Dashboard/Dashboard"));
const NotificationsLanding = React.lazy(() => import("../components/NotificationsLanding/NotificationsLanding"));
const AdminSettings = React.lazy(() => import("../components/AdminSettings/AdminSettings"));
const Account = React.lazy(() => import("../components/Account/Account"));
const InsightRequest = React.lazy(() => import("../components/InsightRequest/InsightRequest"));
const Faq = React.lazy(()=> import("../components/Faq/Faq"))
const createProject = React.lazy(()=>import("../components/CreateProject/CreateProject"))
const EditProject = React.lazy(()=> import("components/EditProject/EditProject"))

const Pages = () => {
  return (
    <React.Fragment>
      <React.Suspense fallback={<LoadingCircle />}>
        <Switch>
          <Route path={`${process.env.PUBLIC_URL}/register/:token?`} component={Register} />
          <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
          <Route path={`${process.env.PUBLIC_URL}/logout`} component={Logout} />
          <Route path={`${process.env.PUBLIC_URL}/twofactor`} component={TwoFactor} />
          <Route path={`${process.env.PUBLIC_URL}/forgot-password`} component={ForgotPassword} />
          <Route path={`${process.env.PUBLIC_URL}/passwordreset`} component={ResetPassword} />
          <Route path={`${process.env.PUBLIC_URL}/landing/EmailChange/:token`} component={ChangeEmailLanding} />
          <Route path={`${process.env.PUBLIC_URL}/updateEmail/:token`} component={UpdateEmailChange} />
          <Route path={`${process.env.PUBLIC_URL}/error/:code`} component={ErrorPage} />
          <Route path={`${process.env.PUBLIC_URL}/privacy`} component={PrivacyPage}/>  
          <Route path={`${process.env.PUBLIC_URL}/activation`} component={Activation}/>        
          <Route>
            <AuthorizedRoute>
              <Layout>
                <React.Suspense fallback={<LoadingCircle />}>
                  <Switch>
                    <Route path={`${process.env.PUBLIC_URL}/`} exact>
                      <Redirect to={`${process.env.PUBLIC_URL}/home`} />
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/home`} component={Home} />
                    <Route exact path={`${process.env.PUBLIC_URL}/insights`} component={InsightRequest} />
                    <Route path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard} />
                    <Route path={`${process.env.PUBLIC_URL}/notifications`} component={NotificationsLanding} />
                    <Route path={`${process.env.PUBLIC_URL}/admin/:tab?`} component={AdminSettings} />
                    <Route path={`${process.env.PUBLIC_URL}/account/:tab?`} component={Account} />
                    <Route path={`${process.env.PUBLIC_URL}/insights/:id?/:checkout?/:success?`} component={InsightRequest} />
                    <Route exact path={`${process.env.PUBLIC_URL}/insights/:id`} component={InsightRequest} />
                    <Route path={`${process.env.PUBLIC_URL}/user/:id?`} component={CreateUser} />
                    <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={Faq} />
                    <Route exsct path={`${process.env.PUBLIC_URL}/create-project`} component={createProject} />
                    <Route path={`${process.env.PUBLIC_URL}/home`} component={Home} />
                    <Route path={`${process.env.PUBLIC_URL}/edit/:id`} component={EditProject} />                  
                    <Route component={NotFound} />
                  </Switch>
                </React.Suspense>
              </Layout>
            </AuthorizedRoute>
          </Route>
        </Switch>
      </React.Suspense>
    </React.Fragment>
  );
};

export default Pages as React.ComponentType;