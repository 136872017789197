// React/Redux Library Imports
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { ReactCookieProps, withCookies } from "react-cookie";
import { RouteComponentProps } from "react-router";

// Material-UI Imports
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  Link,
  Paper,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

// Redux Store Imports
import { ApplicationState, DispatchThunkAction } from "../../state";
import { AppSettingsState } from "../../state/appsettings";
import { makeLoadingSelector } from "../../state/loading/selectors";
import { ActionTypes } from "../../state/enums/ActionTypes";
import backdrop from "../../assets/images/Hero-Pattern.svg";
import {
  stringLocalizerSelector,
  StringLocalizer,
} from "../../state/localization/selectors";
import { changeEmailVerification } from "../../state/account/actions";
import AuthenticationLayout from "components/shared/AuthenticationLayout";

// Generating css styles for the component
const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: 460,
      margin: `${theme.spacing(0)} auto`,
    },

    header: {
      textAlign: "center",
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    card: {
      marginTop: theme.spacing(10),
    },
    root: {
      height: "100vh",
    },
    image: {
      backgroundImage: `url(${backdrop})`,
      backgroundRepeat: "no-repeat",
      backgroundColor: "#210c51",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    signInBox: {
      width: "100%",
      maxWidth: 645,
      alignItems: "center",
    },

    footerBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    version: {
      fontSize: 11,
      textAlign: "center",
    },
    content: {
      width: "100%",
      minHeight: 300,
      alignItems: "center",
      padding: theme.spacing(10),
    },
    loadingBox: {
      textAlign: "center",
    },
    logoutBtn: {
      margin: theme.spacing(3, 0, 2),
      padding: `6px 16px`,
      height: "unset",
    },
    syneHeadersText: {
      fontFamily: "Roboto, sans-serif",
      color: "#4710AE",
      fontWeight: "bold",
    },
  });

interface DispatchProps {
  changeEmailVerification: DispatchThunkAction<typeof changeEmailVerification>;
}

interface RouteProps {
  token?: string;
}
interface PublicProps {
  match?: { params: { token: string } };
}

// StateProps
interface StateProps {
  appsettings?: AppSettingsState;
  loading?: boolean;
  stringLocalizer: StringLocalizer;
}

type LoginProps = StateProps &
PublicProps &
DispatchProps &
ReactCookieProps &
RouteComponentProps<RouteProps> &
WithStyles<typeof styles>;

interface LandingState {
  message: string;
}

class UpdateEmailChange extends React.PureComponent<LoginProps, LandingState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      message: "",
    };
  }

  componentDidMount(): void {
    const { match, changeEmailVerification, stringLocalizer } = this.props;
    void changeEmailVerification({ token: match?.params?.token }).then(
      (res) => {
        const resp = res as { type: string; error: string };

        if (resp.type === ActionTypes.ChangeEmailVerificationFailure) {
          this.setState({ message: stringLocalizer(resp.error) });
        } else {
          this.setState({
            message: stringLocalizer(
              "Email was successfully changed. Please login with the new email"
            ),
          });
        }
      }
    );
  }

  renderView() {
    const { classes, loading } = this.props;
    return (
      <React.Fragment>
        <Box className={classes.signInBox} mb={5}>
          {loading && (
            <div className={classes.loadingBox}>
              <CircularProgress size={35} />
            </div>
          )}
          {!loading && (
            <Button
              onClick={this.handleCloseClick}
              fullWidth
              variant="contained"
              id="sign-in-again"
              color="primary"
              className={classes.logoutBtn}
            >
              Sign in again
            </Button>
          )}
        </Box>
      </React.Fragment>
    );
  }

  renderMessage(message: string) {
    return (
      <Typography variant="subtitle1" id="message">
        {message}
      </Typography>
    );
  }

  renderPrivacyPolicy() {
    // Render the privacy policy link
    return (
      <Typography variant="body2" align="center">
        <Link
          color="inherit"
          href={`${process.env.PUBLIC_URL}/privacy`}
          variant="body2"
        >
          Privacy Policy
        </Link>{" "}
      </Typography>
    );
  }

  handleCloseClick = () => {
    if (window) {
      window.location.assign(`${process.env.PUBLIC_URL}/login`);
    }
  };

  public render() {
    const { classes, appsettings } = this.props;
    const { message } = this.state;
    return (
      <AuthenticationLayout>
        <Box className={classes.content}>
          <Typography
            component="h1"
            variant="h5"
            className={classes.syneHeadersText}
          >
            Change email
          </Typography>
          <React.Fragment>{this.renderMessage(message)}</React.Fragment>
          <React.Fragment>{this.renderView()}</React.Fragment>
          <Box className={classes.footerBox}>
            <Box>{this.renderPrivacyPolicy()}</Box>
            <Box mt={1}>
              <Typography className={classes.version} variant="body2">
                {appsettings?.Version ? `v${appsettings.Version}` : ""}
              </Typography>
            </Box>
          </Box>
        </Box>
      </AuthenticationLayout>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const stringLocalizer = stringLocalizerSelector(state);
  return {
    appsettings: state.appsettings,
    authentication: state.authentication,
    loading: makeLoadingSelector([ActionTypes.ChangeEmailVerificationRequest])(
      state
    ),
    stringLocalizer,
  };
};

const mapDispatchToProps = {
  changeEmailVerification: changeEmailVerification,
};

export default compose(
  withCookies,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(UpdateEmailChange) as React.ComponentType<PublicProps>;
