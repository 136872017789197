// React/Redux Library Imports
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { ReactCookieProps, withCookies } from "react-cookie";
import { RouterProps } from "react-router";

// Material-UI Imports
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Checkbox,
  CssBaseline,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { TextField, Button } from "@material-ui/core";
// Redux Store Imports
import actions from "../../state/actions";
import { ApplicationState, DispatchThunkAction } from "../../state";
import { AppSettingsState } from "../../state/appsettings";
import { AuthenticationState } from "../../state/authentication";
import { makeLoadingSelector } from "../../state/loading/selectors";
import { ActionTypes } from "../../state/enums/ActionTypes";
// Other Imports
import _history from "../../_history";
import {
  AuthStatus,
  IdentityProviders,
  LoginResult,
  UserCredentials,
} from "../../types";
import backdrop from "../../assets/images/Hero-Pattern.png";
import {
  stringLocalizerSelector,
  StringLocalizer,
} from "../../state/localization/selectors";
import { LoginAction } from "../../state/authentication/actions";
import { googleAnalytics } from "helpers/googleAnalyticsUtils";
import "components/shared/Authentication.css";
import { ConfigKeys } from "state/enums/ConfigKeys";

// Generating css styles for the component
const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: 460,
      margin: `${theme.spacing(0)} auto`,
    },
    loginBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
    },
    header: {
      textAlign: "center",
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    card: {},
    root: {
      height: "100vh",
    },
    image: {
      backgroundImage: `url(${backdrop})`,
      backgroundRepeat: "no-repeat",
      backgroundColor: "#210c51",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      padding: "3%",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    signInBox: {
      width: "100%",
      maxWidth: 400,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      padding: `6px 16px`,
      height: "unset",
      fontWeight: 500,
      fontSize: 16,
    },
    footerBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    version: {
      fontSize: 11,
    },
    proximaNovaText: {
      color: "#0D0124",
    },
    proximaForgotPassword: {
      
      color: "#505050",
    },
    proximaSignUpText: {
      
      color: "#0000F5",
    },
    syneHeadersText: {
      fontFamily: "Roboto, sans-serif",
      color: "#4710AE",
      fontWeight: "bold",
    },
  });

// Deconstructed actions
const { loginUser, loginSuccess, passwordLessLogin, passwordLessLoginSuccess } =
  actions;

interface DispatchProps {
  loginUserConnect: DispatchThunkAction<typeof loginUser>;
  loginSuccessConnect: DispatchThunkAction<typeof loginSuccess>;
  passwordLessConnect: DispatchThunkAction<typeof passwordLessLogin>;
  passwordLessSuccessConnect: DispatchThunkAction<
    typeof passwordLessLoginSuccess
  >;
}
// StateProps
interface StateProps {
  appsettings?: AppSettingsState;
  authentication?: AuthenticationState;
  redirectUrl?: string;
  loading?: boolean;
  stringLocalizer: StringLocalizer;
}

type LoginProps = StateProps &
DispatchProps &
ReactCookieProps &
RouterProps &
WithStyles<typeof styles>;

interface LoginState {
  Email: string;
  Password: string;
  RememberMe?: boolean;
  identityProviders?: IdentityProviders;
  showLoginButtonAlways?: boolean;
  redirectUrl?: string;
  showError?: boolean;
  errorMessage?: string;
  checkEmail?: boolean;
}

class Login extends React.PureComponent<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      Email: "",
      Password: "",
      identityProviders: IdentityProviders.Default,
      showLoginButtonAlways: false,
      redirectUrl: "",
      showError: false,
      errorMessage: "",
      checkEmail: false,
    };
  }

  static getDerivedStateFromProps(
    nextProps: LoginProps,
    prevState: LoginState
  ) {
    // invoked right before calling the render method, both on the initial mount and on subsequent updates
    // return an object to update the state, or null to update nothing.
    const { appsettings, authentication } = nextProps;
    const identityProviders = appsettings?.IdentityProviders;
    const showLoginButtonAlways = appsettings?.ShowLoginButtonAlways;
    const localAuthentication =
      appsettings?.IdentityProviders == IdentityProviders.Default;
    const redirectUrl = !prevState?.redirectUrl && authentication?.redirectUrl;
    const errorMessage = authentication?.errorMessage;
    return {
      localAuthentication,
      identityProviders,
      showLoginButtonAlways,
      redirectUrl,
      errorMessage,
      showError: !errorMessage ? false : true,
    };
  }

  componentDidUpdate(prevProps: LoginProps) {
    // invoked immediately after updating occurs. This method is not called for the initial render.
    // will not be invoked if shouldComponentUpdate() returns false.

    const { appsettings, authentication, redirectUrl } = this.props;
    const currentStatus = authentication?.authenticationStatus;
    const identityProviders = appsettings?.IdentityProviders
      ? appsettings.IdentityProviders
      : 0;
    const showLoginButtonAlways = appsettings?.ShowLoginButtonAlways;

    if (
      ((identityProviders & IdentityProviders.AzureAD) != 0 ||
        (identityProviders & IdentityProviders.Okta) != 0) &&
      !showLoginButtonAlways &&
      currentStatus == AuthStatus.Unauthorized
    ) {
      console.debug("Automatic SSO Authentication");
      _history.push("/Account/Login");
    }

    if (
      currentStatus == AuthStatus.RequiresTwoFactor ||
      currentStatus == AuthStatus.ForceTwoFactor
    ) {
      console.debug("Needs to do 2FA");
      _history.push(`${process.env.PUBLIC_URL}/twofactor`);
    } else if (
      currentStatus == AuthStatus.Authorized &&
      currentStatus != prevProps.authentication?.authenticationStatus
    ) {
      console.debug("No 2FA and Authorized");
      const PresentationId = sessionStorage.getItem(
        ConfigKeys.PresentationId
      );
      if (PresentationId) {
        _history.push(
          `${process.env.PUBLIC_URL}/insights/${PresentationId}`
        );
        sessionStorage.removeItem(ConfigKeys.PresentationId);
      } else {
        _history.push(redirectUrl || process.env.PUBLIC_URL);
      }
    }
  }

  handleLoginSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // Need to do this to stop email and password from
    // showing up as query parameters
    event.preventDefault();

    const { loginUserConnect, loginSuccessConnect, cookies } = this.props;
    const { Email, Password, RememberMe } = this.state;

    if (Email && Password && cookies) {
      const credentials = {
        Email,
        Password,
        Remember: RememberMe,
      } as UserCredentials;

      googleAnalytics.trackEvent(
        "Authentication",
        "Login Click",
        "Login action"
      );
      void loginUserConnect(credentials).then((action: LoginAction) => {
        if (action?.response) {
          const loginResult = action.response as LoginResult;

          if (loginResult.Succeeded) {
            void loginSuccessConnect();
          }
        }
      });
    }
  };

  handlePasswordLessSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // Need to do this to stop email from
    // showing up as query parameters
    event.preventDefault();

    const { passwordLessConnect, cookies } = this.props;
    const { Email } = this.state;

    if (Email && cookies) {
      const credentials = {
        Email,
        Remember: true,
      } as UserCredentials;
      void passwordLessConnect(credentials).then((action: LoginAction) => {
        if (action.type === ActionTypes.PasswordLessLoginSuccess) {
          this.setState({ checkEmail: true });
        }
      });
    }
  };

  handleSSOAzureAd = () => {
    if (window) {
      window.location.assign(`/account/loginexternal?provider=azuread`);
    }
  };

  handleSSOOkta = () => {
    if (window) {
      window.location.assign(`/account/loginexternal?provider=okta`);
    }
  };

  renderPrivacyPolicy() {
    // Render the privacy policy link
    return (
      <Typography variant="body2" align="center">
        <Link
          color="inherit"
          href={`${process.env.PUBLIC_URL}/privacy`}
          variant="body2"
          underline="always"
        >
          Privacy Policy
        </Link>{" "}
      </Typography>
    );
  }

  renderErrorMessage(showError?: boolean, errorMessage?: string) {
    // Render the error message from the server
    return showError == true ? (
      <div
        id="error-continer"
        style={{
          width: "100%",
          maxWidth: "400px",
          
        }}
      >
        <Typography
          id="error-message"
          variant="subtitle1"
          color="error"
          
        >
          {errorMessage}
        </Typography>
      </div>
    ) : (
      <></>
    );
  }

  renderDefaultAuthentication() {
    const { classes, stringLocalizer, loading } = this.props;
    const { Email, Password, RememberMe, errorMessage, showError } = this.state;
    return (
      <React.Fragment>
        <div>
          <Box className={classes.signInBox} mb={5}>
            <Typography
              component="h1"
              variant="h4"
              className={classes.syneHeadersText}
            >
              Sign in
            </Typography>
            <Typography className={classes.proximaNovaText}>
              Welcome back! Please enter your details
            </Typography>
            <React.Fragment>
              {this.renderErrorMessage(showError, errorMessage)}
            </React.Fragment>
            <form
              className={classes.form}
              noValidate
              onSubmit={this.handleLoginSubmit}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email address"
                name="email"
                value={Email}
                size="small"
                autoComplete="email"
                onChange={this.handleEmailChange}
                autoFocus
                InputProps={{
                  style: { backgroundColor: "white" }, // Change 'lightblue' to your desired background color
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                value={Password}
                label="Password"
                size="small"
                type="password"
                id="password"
                onChange={this.handlePasswordChange}
                autoComplete="current-password"
                InputProps={{
                  style: { backgroundColor: "white" }, // Change 'lightblue' to your desired background color
                }}
              />

              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item>
                  <FormControlLabel
                    style={{ marginRight: "11px" }}
                    control={
                      <Checkbox
                        id="rememberme"
                        name="rememberme"
                        value={RememberMe}
                        color="primary"
                        onChange={this.handleCheckboxChange}
                      />
                    }
                    label={
                      <span className={classes.proximaForgotPassword}>
                        Remember me
                      </span>
                    }
                  />
                </Grid>
                <Grid item>
                  <Link
                    href={`${process.env.PUBLIC_URL}/forgot-password`}
                    variant="body2"
                    underline="always" // Add underline style
                    style={{ cursor: "pointer" }}
                    className={classes.proximaForgotPassword}
                  >
                    Forgot your password?
                  </Link>
                </Grid>
              </Grid>
              <Button
                id="submit-login"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!this.validateForm() || loading}
                className={classes.submit}
                style={{ textTransform: "none" }} // Add this line
              >
                {loading
                  ? stringLocalizer("Processing...")
                  : stringLocalizer("Sign in")}
              </Button>
            </form>
            <Divider variant="middle" />
            <Typography
              className={classes.proximaNovaText}
              style={{ marginTop: "10px" }}
            >
              Don’t have an account?{" "}
              <Link
                variant="body2"
                href={`${process.env.PUBLIC_URL}/register`}
                underline="always"
                className={classes.proximaSignUpText}
                style={{ cursor: "pointer" }}
              >
                Sign up
              </Link>
            </Typography>
          </Box>
        </div>
      </React.Fragment>
    );
  }

  renderPasswordLessAuthentication() {
    const { classes, loading } = this.props;
    const { Email, checkEmail, showError } = this.state;
    return (
      <React.Fragment>
        <Box className={classes.signInBox}>
          <Typography
            component="h2"
            variant="h6"
            className={classes.syneHeadersText}
          >
            Passwordless sign in
          </Typography>
          <form
            id="submit-password-less-form"
            className={classes.form}
            noValidate
            onSubmit={this.handlePasswordLessSubmit}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email address"
              name="email"
              value={Email}
              size="small"
              autoComplete="email"
              onChange={this.handleEmailChange}
            />
            <Button
              id="submit-password-less"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!this.validatePasswordLessForm() || loading}
              style={{ marginTop: "10px" }}
              className={classes.submit}
            >
              Get magic link
            </Button>
          </form>
          {checkEmail && !showError && (
            <Typography className={classes.proximaForgotPassword}>
              Please check your email for a passwordless login invitation link
              in order to complete the process.
            </Typography>
          )}
        </Box>
      </React.Fragment>
    );
  }

  renderSSOAuthentication() {
    const { appsettings, classes } = this.props;

    const identityProviders = appsettings?.IdentityProviders;
    const hasOkta = Boolean(
      identityProviders && (identityProviders & IdentityProviders.Okta) != 0
    );
    const hasAzurdAD = Boolean(
      identityProviders && (identityProviders & IdentityProviders.AzureAD) != 0
    );

    return (
      <React.Fragment>
        <Box className={classes.signInBox} mb={5}>
          <Typography component="h2" variant="h6">
            SSO Sign In
          </Typography>
          {hasOkta && (
            <Button
              id="sso-login-okta"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleSSOOkta}
            >
              Sign In Through Okta
            </Button>
          )}
          {hasAzurdAD && (
            <Button
              id="sso-login-azuread"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleSSOAzureAd}
            >
              Sign In Through AzureAD
            </Button>
          )}
        </Box>
      </React.Fragment>
    );
  }

  validateForm() {
    // Ensure that the email and password field has been filled
    const { Email, Password } = this.state;
    return Email.length > 0 && Password.length > 0;
  }

  validatePasswordLessForm() {
    // Ensure that the email and password field has been filled
    const { Email } = this.state;
    return Email.length > 0;
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ Email: event.target.value });
  };

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ Password: event.target.value });
  };

  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ RememberMe: event.target.checked });
  };

  public render() {
    const { classes, appsettings } = this.props;
    const { showLoginButtonAlways } = this.state;
    const identityProviders = appsettings?.IdentityProviders
      ? appsettings.IdentityProviders
      : 0;
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <img src={backdrop} className="xterioImg" />
        <div
          className={classes.paper}
          id="childComponent"
          style={{ margin: 0, overflow: "auto" }}
        >
          <React.Fragment>
            {(identityProviders & IdentityProviders.Default) != 0 &&
              this.renderDefaultAuthentication()}
            {(identityProviders & IdentityProviders.Passwordless) != 0 &&
              this.renderPasswordLessAuthentication()}
            {((identityProviders & IdentityProviders.AzureAD) != 0 ||
              (identityProviders & IdentityProviders.Okta) != 0) &&
              showLoginButtonAlways &&
              this.renderSSOAuthentication()}
          </React.Fragment>
          <Box className={classes.footerBox}>
            <Box>{this.renderPrivacyPolicy()}</Box>
            <Box mt={1}>
              <Typography className={classes.version} variant="body2">
                {appsettings?.Version ? `v${appsettings.Version}` : ""}
              </Typography>
            </Box>
          </Box>
          {/* </div> */}
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const stringLocalizer = stringLocalizerSelector(state);
  return {
    appsettings: state.appsettings,
    authentication: state.authentication,
    loading: makeLoadingSelector([
      ActionTypes.LoginRequest,
      ActionTypes.Login2FaRequest,
      ActionTypes.Login2FaModelRequest,
    ])(state),
    stringLocalizer,
  };
};

const mapDispatchToProps = {
  loginUserConnect: loginUser,
  loginSuccessConnect: loginSuccess,
  passwordLessConnect: passwordLessLogin,
  passwordLessSuccessConnect: passwordLessLoginSuccess,
};

export default compose(
  withCookies,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Login) as React.ComponentType;
