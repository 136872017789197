// React/Redux Library Imports
import * as React from "react";

// Redux-Store Imports
import { FileDefinition } from "../../../abstractions/file/FileDefinition";

// Material-ui Imports
import {
  Modal,
  IconButton,
  createStyles,
  WithStyles,
  withStyles,
  Container,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import AddFileUrl from "./AddFileUrl";

const styles = () =>
  createStyles({
    root: {
      paddingTop: 30,
    },
    modalContainer: {
      position: "relative",
    },
    closeIconButton: {
      position: "absolute",
      right: 0,
      top: 0,
      color: "black",
      zIndex: 1,
    },
  });

interface AddFilesModalProps extends WithStyles<typeof styles> {
  open: boolean;
  onClose: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    reason: "backdropClick" | "escapeKeyDown"
  ) => void;
  onAddFile?: (file: FileDefinition[]) => void;
  files?: FileDefinition[];
}

class AddFilesModal extends React.Component<AddFilesModalProps> {
  handleOnClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { onClose } = this.props;
    onClose(event, "backdropClick");
  };

  render() {
    const { classes, open, onClose, onAddFile, files } = this.props;

    return (
      <Modal className={classes.root} open={open} onClose={onClose}>
        <Container className={classes.modalContainer} disableGutters>
          <IconButton
            className={classes.closeIconButton}
            id="close-modal-button"
            onClick={this.handleOnClose}
          >
            <CloseIcon />
          </IconButton>
          <AddFileUrl
            files={files}
            onClose={this.handleOnClose}
            onAddFile={onAddFile}
          />
        </Container>
      </Modal>
    );
  }
}

export default withStyles(styles)(AddFilesModal);
