import { CallApiAction } from "../shared/middleware/api";
import { ActionTypes } from "../enums/ActionTypes";
import { Endpoints } from "../enums/Endpoints";
import { Action } from "redux";
import { AppThunkAction } from "..";
import { GroupRole } from "../../abstractions/securityGroups/GroupRole";
import { SecurityGroup } from "../../abstractions/securityGroups/SecurityGroup";
import { find } from "lodash";

export interface GroupsAction extends CallApiAction {
  type:
    | typeof ActionTypes.GroupsRequest
    | typeof ActionTypes.GroupsSuccess
    | typeof ActionTypes.GroupsFailure
    | typeof ActionTypes.GroupAddRequest
    | typeof ActionTypes.GroupAddSuccess
    | typeof ActionTypes.GroupAddFailure
    | typeof ActionTypes.GroupRestrictRequest
    | typeof ActionTypes.GroupRestrictSuccess
    | typeof ActionTypes.GroupRestrictFailure
    | typeof ActionTypes.GroupUnrestrictRequest
    | typeof ActionTypes.GroupUnrestrictSuccess
    | typeof ActionTypes.GroupUnrestrictFailure
    | typeof ActionTypes.GroupDataRequest
    | typeof ActionTypes.GroupDataSuccess
    | typeof ActionTypes.GroupDataFailure
    | typeof ActionTypes.GroupRolesRequest
    | typeof ActionTypes.GroupRolesSuccess
    | typeof ActionTypes.GroupRolesFailure
    | typeof ActionTypes.GroupRoleAddRequest
    | typeof ActionTypes.GroupRoleAddSuccess
    | typeof ActionTypes.GroupRoleAddFailure
    | typeof ActionTypes.GroupRoleRemoveRequest
    | typeof ActionTypes.GroupRoleRemoveSuccess
    | typeof ActionTypes.GroupRoleRemoveFailure
    | typeof ActionTypes.RolesRequest
    | typeof ActionTypes.RolesSuccess
    | typeof ActionTypes.RolesFailure
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  response?: Record<string, unknown>;
  payload?: {
    item?: Record<string, unknown>;
    prevItem?: Record<string, unknown>;
  };
}

export interface GroupSelectAction extends Action {
  type: typeof ActionTypes.GroupSelect | typeof ActionTypes.None;
  selectedGroupId: string;
}

// Changes selected group Id
const setSelectedGroup = (selectedGroupId: string): GroupSelectAction => ({
  type: ActionTypes.GroupSelect,
  selectedGroupId: selectedGroupId,
});

export const changeSelectedGroup = (
  selectedGroupId: string
): AppThunkAction<GroupSelectAction> => (dispatch) => {
  return dispatch(setSelectedGroup(selectedGroupId));
};

// Fetches Roles from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchRoles = (): GroupsAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.RolesRequest,
      ActionTypes.RolesSuccess,
      ActionTypes.RolesFailure,
    ],
    endpoint: Endpoints.Roles,
  },
  [ActionTypes.CallODATA]: {
    query: "?$orderby=Priority,Name",
  },
  type: ActionTypes.CallAPI,
});

export const loadRoles = (): AppThunkAction<Action> => (dispatch) => {
  return dispatch(fetchRoles());
};

// Adds new Security Group
const postSecurityGroup = (group: SecurityGroup): GroupsAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.GroupAddRequest,
      ActionTypes.GroupAddSuccess,
      ActionTypes.GroupAddFailure,
    ],
    endpoint: Endpoints.Groups,

    options: {
      method: "POST",
      body: JSON.stringify(group),
    },
  },
  [ActionTypes.CallODATA]: {
    query: ``,
  },
  payload: {
    item: {},
  },
  type: ActionTypes.CallAPI,
});

export const addSecurityGroup = (
  group: SecurityGroup
): AppThunkAction<GroupsAction> => (dispatch) => {
  return dispatch(postSecurityGroup(group));
};

// Restricts a security group
export const postRestrictSecurityGroup = (groupId: string): GroupsAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.GroupRestrictRequest,
      ActionTypes.GroupRestrictSuccess,
      ActionTypes.GroupRestrictFailure,
    ],
    endpoint: `${Endpoints.Groups}/${groupId}/Restrict`,
    options: {
      method: "POST"
    },        
  },
  type: ActionTypes.CallAPI,
  payload: {
    item: { GroupId: groupId }
  }
});

export const restrictSecurityGroup = (
  groupId: string
): AppThunkAction<GroupsAction> => (dispatch) => {
  return dispatch(postRestrictSecurityGroup(groupId));
};

// Unrestricts a security group
export const postUnrestrictSecurityGroup = (groupId: string): GroupsAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.GroupRestrictRequest,
      ActionTypes.GroupRestrictSuccess,
      ActionTypes.GroupRestrictFailure,
    ],
    endpoint: `${Endpoints.Groups}/${groupId}/Release`,
    options: {
      method: "POST"
    }
  },
  type: ActionTypes.CallAPI,
  payload: {
    item: { GroupId: groupId }
  }
});

export const unrestrictSecurityGroup = (
  groupId: string
): AppThunkAction<GroupsAction> => (dispatch) => {
  return dispatch(postUnrestrictSecurityGroup(groupId));
};

// Fetches Security Groups from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchSecurityGroups = (): GroupsAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.GroupsRequest,
      ActionTypes.GroupsSuccess,
      ActionTypes.GroupsFailure,
    ],
    endpoint: Endpoints.Groups,
  },
  type: ActionTypes.CallAPI,
});

export const loadSecurityGroups = (): AppThunkAction<GroupsAction> => (
  dispatch
) => {
  return dispatch(fetchSecurityGroups());
};

// Fetches Security Group roles from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchGroupRoles = (groupId: string): GroupsAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.GroupRolesRequest,
      ActionTypes.GroupRolesSuccess,
      ActionTypes.GroupRolesFailure,
    ],
    endpoint: `${Endpoints.Groups}/${groupId}?$select=GroupRoles&$expand=GroupRoles($select=GroupId,RoleId,Id)`,
  },
  type: ActionTypes.CallAPI,
});

export const loadGroupRoles = (
  groupId: string
): AppThunkAction<GroupsAction> => (dispatch) => {
  return dispatch(fetchGroupRoles(groupId));
};

// Add a role to a group
const postGroupRole = (groupId: string, roleId: string): GroupsAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.GroupRoleAddRequest,
      ActionTypes.GroupRoleAddSuccess,
      ActionTypes.GroupRoleAddFailure,
    ],
    endpoint: `${Endpoints.Groups}/${groupId}/Roles/${roleId}`,

    options: {
      method: "POST",
      body: JSON.stringify({ GroupId: groupId, RoleId: roleId }),
    },
  },
  payload: {
    item: {
      GroupId: groupId,
      RoleId: roleId,
    },
  },
  type: ActionTypes.CallAPI,
});

export const addGroupRole = (
  groupId: string,
  roleId: string
): AppThunkAction<GroupsAction> => (dispatch) => {
  return dispatch(postGroupRole(groupId, roleId));
};

// Delete a role from a group
const deleteGroupRole = (groupRole: GroupRole): GroupsAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.GroupRoleRemoveRequest,
      ActionTypes.GroupRoleRemoveSuccess,
      ActionTypes.GroupRoleRemoveFailure,
    ],
    endpoint: `${Endpoints.Groups}/${groupRole.GroupId}/Roles/${groupRole.Id}`,

    options: {
      method: "DELETE",
    },
  },
  payload: {
    item: { Id: groupRole.Id, GroupId: groupRole.GroupId },
    prevItem: (groupRole as unknown) as Record<string, unknown>,
  },
  type: ActionTypes.CallAPI,
});

export const removeGroupRole = (
  groupId: string,
  roleId: string
): AppThunkAction<GroupsAction> => (dispatch, getState) => {
  const { groups } = getState();
  let groupRole = {} as GroupRole;
  if (groups && groups.groups && groups.groupRoles) {
    groupRole =
      find(
        groups.groupRoles,
        (groupRole) =>
          groupRole.RoleId === roleId && groupRole.GroupId === groupId
      ) || ({} as GroupRole);
  }
  if (!groupRole.Id) {
    return Promise.reject("Temporary GroupRole cannot be deleted yet.");
  }
  return dispatch(deleteGroupRole(groupRole));
};

export interface GroupsActionCreators {
  changeSelectedGroup: typeof changeSelectedGroup;
  loadRoles: typeof loadRoles;
  loadSecurityGroups: typeof loadSecurityGroups;
  loadGroupRoles: typeof loadGroupRoles;
  addGroupRole: typeof addGroupRole;
  removeGroupRole: typeof removeGroupRole;
  addSecurityGroup: typeof addSecurityGroup;
  restrictSecurityGroup: typeof restrictSecurityGroup;
  unrestrictSecurityGroup: typeof unrestrictSecurityGroup;
}
