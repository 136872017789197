import ReactGA from "react-ga4";

class GoogleAnalyticsTracker {
  initialize(trackingId: string) {
    ReactGA.initialize(trackingId);
  }

  trackPageView(pageName: string, title: string) {
    ReactGA.send({ hitType: "pageview", page: pageName, title: title });
  }

  trackEvent(category: string, action: string, label?: string) {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  }
}

export const googleAnalytics = new GoogleAnalyticsTracker();
