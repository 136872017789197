import { CallApiAction } from "../shared/middleware/api";
import { ActionTypes } from "../enums/ActionTypes";
import { Action } from "redux";
import { AppThunkAction } from "../";
import { Endpoints } from "../enums/Endpoints";

export interface DocumentsAction extends Action {
  
  type:
    | typeof ActionTypes.DocumentUploadRequest
    | typeof ActionTypes.DocumentUploadSuccess
    | typeof ActionTypes.DocumentUploadFailure
    | typeof ActionTypes.CompleteChunkRequest
    | typeof ActionTypes.CompleteChunkSuccess
    | typeof ActionTypes.CompleteChunkFailure
    | typeof ActionTypes.DocumentRequest
    | typeof ActionTypes.AccountSuccess
    | typeof ActionTypes.DocumentFailure
    | typeof ActionTypes.None;
  response?: Record<string, unknown>;
  error?: string;
}

// Uploads document to client
// Relies on the custom API middleware defined in ../middleware/api.js.
const postDocument = (
  payload: FormData,
  urlLocation?: string
): CallApiAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.DocumentUploadRequest,
      ActionTypes.DocumentUploadSuccess,
      ActionTypes.DocumentUploadFailure,
    ],
    endpoint: urlLocation ? urlLocation : Endpoints.Documents,
    useBase: true,
    options: {
      method: "POST",
      body: payload,
      contentType: undefined,
    },
  },
  type: ActionTypes.CallAPI,
});

export const uploadDocument = (
  payload: FormData,
  urlLocation?: string
): AppThunkAction<Action> => (dispatch) => {
  return dispatch(postDocument(payload, urlLocation));
};

/**
 * Get a document
 * @param documentId   
 * @returns 
 */

const getDocument = (
  documentId: string
): CallApiAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.DocumentRequest,
      ActionTypes.DocumentSuccess,
      ActionTypes.DocumentFailure,
    ],
    endpoint: `${Endpoints.Documents}/${documentId}`,
    useBase: true,
  },
  type: ActionTypes.CallODATA,
});

export const getDocumentById = (
  documentId: string
): AppThunkAction<Action> => (dispatch) => {
  return dispatch(getDocument(documentId));
};

const completeChunk = (
  chunkedBlobs: Record<string, unknown>[]
): CallApiAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.CompleteChunkRequest,
      ActionTypes.CompleteChunkSuccess,
      ActionTypes.CompleteChunkFailure,
    ],
    endpoint: `${Endpoints.DocumentsUploadChunkComplete}`,
    useBase: true,
    options: {
      method: "POST",
      body: JSON.stringify(chunkedBlobs),
    },
  },
  type: ActionTypes.CallODATA,
});

export const completeChunkUpload = (
  chunkedBlobs: Record<string, unknown>[]
): AppThunkAction<Action> => (dispatch) => {
  return dispatch(completeChunk(chunkedBlobs));
};

export interface DocumentsActionCreators {
  uploadDocument: typeof uploadDocument;
  completeChunkUpload: typeof completeChunkUpload;
}
