import { createSelector } from "reselect";
import { forEach, map } from "lodash";
import { ApplicationState } from "../";

export const getUserQuery = (
  currentPage?: number,
  pageSize?: number,
  filterQueryList?: string[],
  sortQueryList?: string[],
  searchModel?: string
): string => {
  if (currentPage === undefined || !pageSize) {
    return "";
  }

  let _url = "";

  let filterList: string[] = [];

  let sortList: string[] = [];

  if (sortQueryList && sortQueryList.length) {
    sortList = sortQueryList.concat();
  }

  if (filterQueryList && filterQueryList.length) {
    filterList = filterQueryList.concat();
  }

  // Use the search model to filter users based on first name, last name, or email
  if (searchModel) {
    const encodedSearchModel = encodeURIComponent(searchModel);
    const firstLastName = `contains(FirstName, '${encodedSearchModel}') OR contains(LastName, '${encodedSearchModel}')`;
    const fullNameSearch = `contains(Name, '${encodedSearchModel}')`;
    const emailSearch = `contains(Email, '${encodedSearchModel}')`;
    const query = `((${firstLastName}) OR (${fullNameSearch}) OR (${emailSearch}))`;
    filterList.push(query);
  }

  const skipItems = (currentPage) * pageSize;

  // update the url with pagination query
  _url =
    _url +
    `?$count=true&$select=Id,Name,Groups,profileTypes,IsActive&$top=10&$skip=${skipItems}&groups($select=groupId,Id;$expand=group($select=name,Id))`;

  if (filterList.length) {
    // currentPage = 1; // reset the current page to one
    let filterString = "&$filter=";
    forEach(filterList, (val, idx) => {
      filterString = filterString + val;
      if (filterList.length > 1 && idx < filterList.length - 1) {
        filterString = filterString + " AND ";
      }
    });
    _url = _url + filterString;
  }

  if (sortList.length) {
    const sortString = `&$orderby=${sortList.join(",")}`;
    _url = _url + sortString;
  }

  return _url;
};

const getUsers = (state: ApplicationState) => state.users?.users;
const getPage = (state: ApplicationState) => state.users?.page;
const getPageSize = (state: ApplicationState) => state.users?.pageSize;

