// React/Redux Library Imports
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";

// Material-ui Imports
import {
  createStyles,
  WithStyles,
  withStyles,
  WithTheme,
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
  Theme,
  Typography,
} from "@material-ui/core";
import MailIcon from "@mui/icons-material/Mail";

// Redux-Store Imports
import { ApplicationState, DispatchThunkAction } from "../../state";
import actions from "../../state/actions";
import { UserNotification } from "../../abstractions/userNotification/UserNotification";
import { makeNotificationByGroupSelector } from "../../state/notifications/selectors";

// Other Imports
import { map, isEmpty } from "lodash";
import clsx from "clsx";

// Generating css styles for the component
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    popover: {
      width: 500,
    },
    list: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    avatar: {
      display: "flex",
      justifyContent: "center",
    },
    unread: {
      backgroundColor: theme.backgroundStyles.notificationPopOverBackground,
    },
    viewAllButton: {
      width: "100%",
      height: 40,
    },
    noNotificationsIcon: {
      height: 200,
      width: 200,
      color: theme.palette.action.disabled,
    },
    noNotificationsTitle: {
      color: theme.palette.action.disabled,
    },
    noNotificationsDiv: {
      padding: 30,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  });

const { markNotificationAsRead } = actions;

interface PublicProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

interface StateProps {
  notifications: UserNotification[];
}

interface DispatchProps {
  markNotificationAsRead: DispatchThunkAction<typeof markNotificationAsRead>;
}

type INotificationPopoverProps = PublicProps &
StateProps &
DispatchProps &
WithStyles<typeof styles> &
WithTheme;

class NotificationPopover extends React.PureComponent<INotificationPopoverProps> {
  renderNotification = (note: UserNotification) => {
    const { onClose, markNotificationAsRead, classes } = this.props;
    const date = new Date(note.CreationTime ? note.CreationTime : "");
    const dateString =
      date.toLocaleDateString("en-us", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }) +
      " " +
      date.toLocaleTimeString("en-us", {
        hour: "numeric",
        minute: "numeric",
      });

    const action = note.Message?.Action;

    let url = `${process.env.PUBLIC_URL}/notifications`;
    if (action && action.Type === "Insight") {
      url = `${process.env.PUBLIC_URL}/insights/${action.Id}`;
    }

    return (
      <ListItem
        className={clsx({ [classes.unread]: !note.Delivered })}
        key={note.Id}
        component={Link}
        to={url}
        onClick={() => {
          void markNotificationAsRead(note.Id);
          onClose();
        }}
        id={`notification-${note.Id}`}
        button
      >
        <ListItemAvatar className={classes.avatar}>
          <MailIcon />
        </ListItemAvatar>
        <ListItemText
          primary={note.Message ? note.Message.Title : ""}
          secondary={dateString}
        />
      </ListItem>
    );
  };

  render() {
    const { classes, open, notifications, anchorEl, onClose } = this.props;

    return (
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        classes={{ paper: classes.popover }}
        anchorEl={anchorEl}
        onClose={onClose}
      >
        {isEmpty(notifications) && (
          <div className={classes.noNotificationsDiv}>
            <Typography className={classes.noNotificationsTitle} variant="h5">
              You have no current notifications
            </Typography>
            <MailIcon className={classes.noNotificationsIcon} />
          </div>
        )}
        {notifications && notifications.length && (
          <List className={classes.list}>
            {notifications.map((note) => this.renderNotification(note))}
          </List>
        )}

        <Button
          component={Link}
          to={`${process.env.PUBLIC_URL}/notifications`}
          className={classes.viewAllButton}
          onClick={onClose}
          id="view-all-notifications"
        >
          View All
        </Button>
      </Popover>
    );
  }
}

const formatNotifications = (notifications?: UserNotification[]) => {
  if (notifications && !isEmpty(notifications)) {
    return map(notifications, (notification) => notification).slice(0, 5);
  }
};

const mapStateToProps = (state: ApplicationState) => ({
  notifications: formatNotifications(
    makeNotificationByGroupSelector("ALL")(state)
  ),
});

const mapDispatchToProps = {
  markNotificationAsRead,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(NotificationPopover) as React.ComponentType<PublicProps>;
