/** @format */
// React/Redux Library Imports
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { ReactCookieProps, withCookies } from "react-cookie";
import { RouterProps } from "react-router";

// Material-UI Imports
import { createStyles, Theme } from "@material-ui/core/styles";
import {  
  Typography,
  Link,
  WithStyles,
  withStyles,
  FormControlLabel,  
  Snackbar,
  Box,
  Checkbox,
} from "@material-ui/core";
import { TextField, Button } from "@material-ui/core";
import Alert from "@mui/material/Alert";

// Redux Store Imports
import actions from "../../state/actions";
import { ApplicationState, DispatchThunkAction } from "../../state";
import { AppSettingsState } from "../../state/appsettings";
import { AuthenticationState } from "../../state/authentication";
import { TwoFactorState } from "../../state/twofactor";

// Other Imports
import _history from "../../_history";
import {
  TwoFactorModel,
  TwoFactorModes,
  AuthStatus,
  Login2FaModel,
  MultiFactorAuthentication,
} from "../../types";
import AuthenticationLayout from "../shared/AuthenticationLayout";
import TwoFactorSetup from "./TwoFactorSetup";
import { makeLoadingSelector } from "../../state/loading/selectors";
import { ActionTypes } from "../../state/enums/ActionTypes";
import OtpInput from "react-otp-input";
import "./TwoFactor.css";
import { ConfigKeys } from "state/enums/ConfigKeys";

// Generating css styles for the component
const styles = (theme: Theme) =>
  createStyles({
    submitBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      padding: `6px 16px`,
      height: "unset",
      marginTop: "1%",
      width: "100%",
    },
    modeButton: {
      height: "unset",
      margin: `24px 0px 16px`,
      padding: `6px 16px`,
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    loginRedirectButton: {
      marginTop: theme.spacing(1),
    },
    twoFactorText: {
      fontSize: "28px",
      color: "#4710AE",
      fontWeight: 700,
      margin: 0,
    },
  });

// Deconstructed actions
const {
  getAuthenticationStatus,
  toggleSharedKey,
  clearTwoFactor,
  continueToApp,
  login2FaModel,
  confirmLogin2FaCode,
  getTwoFactorModel,
  enableByApp,
  confirmByApp,
  enableByEmail,
  confirmByEmail,
  confirm2FaRecoveryCode,
} = actions;

interface DispatchProps {
  getAuthenticationStatus: DispatchThunkAction<typeof getAuthenticationStatus>;
  toggleSharedKeyConnect: DispatchThunkAction<typeof toggleSharedKey>;
  clearTwoFactorConnect: DispatchThunkAction<typeof clearTwoFactor>;
  continueToAppConnect: DispatchThunkAction<typeof continueToApp>;
  login2FaModelConnect: DispatchThunkAction<typeof login2FaModel>;
  confirmLogin2FaCodeConnect: DispatchThunkAction<typeof confirmLogin2FaCode>;
  getTwoFactorModelConnect: DispatchThunkAction<typeof getTwoFactorModel>;
  enableByAppConnect: DispatchThunkAction<typeof enableByApp>;
  confirmByAppConnect: DispatchThunkAction<typeof confirmByApp>;
  enableByEmailConnect: DispatchThunkAction<typeof enableByEmail>;
  confirmByEmailConnect: DispatchThunkAction<typeof confirmByEmail>;
  confirm2FaRecoveryCode: DispatchThunkAction<typeof confirm2FaRecoveryCode>;
}

// StateProps
interface StateProps {
  appsettings?: AppSettingsState;
  authentication?: AuthenticationState;
  twofactor?: TwoFactorState;
  loading?: boolean;
}

interface PublicProps {
  disbaleLayout?: boolean;
}

interface EditTwoFactorState {
  editCode: string;
  editRemember: boolean;
  showVerificationForm: boolean;
  hasAuthenticator: boolean;
  editLogin2FaModel?: Login2FaModel;
  editTwoFactorModel?: TwoFactorModel;
  requiresSetup?: boolean;
  showSetup?: boolean;
  showSharedKey?: boolean;
  showRecoveryCodes?: boolean;
  verificationCompleted?: boolean;
  errorMessage?: string;
  showError?: boolean;
  editRecoveryCode?: string;
  showRecoveryCodeInput?: boolean;
  disableRemberMeSwitch?: boolean;
}

type TwoFactorProps = PublicProps &
StateProps &
DispatchProps &
ReactCookieProps &
RouterProps &
WithStyles<typeof styles>;

class TwoFactor extends React.PureComponent<
  TwoFactorProps,
  EditTwoFactorState
> {
  constructor(props: TwoFactorProps) {
    super(props);
    this.state = {
      editCode: "",
      editRemember: false,
      hasAuthenticator: false,
      showVerificationForm: true,
      requiresSetup: false,
      showSetup: false,
      showSharedKey: false,
      showRecoveryCodes: false,
      verificationCompleted: false,
      errorMessage: "",
      showError: false,
      disableRemberMeSwitch: false,
    };
  }

  // Component Lifecycle Hooks
  static getDerivedStateFromProps(nextProps: TwoFactorProps) {
    // invoked right before calling the render method, both on the initial mount and on subsequent updates
    // return an object to update the state, or null to update nothing.

    const { twofactor } = nextProps;
    if (twofactor) {
      const {
        TwoFactorModel,
        Login2FaModel,
        showVerificationForm,
        requiresSetup,
        showSetup,
        showSharedKey,
        showRecoveryCodes,
        verificationCompleted,
        errorMessage,
        showError,
      } = twofactor;
      return {
        editTwoFactorModel: TwoFactorModel,
        editLogin2FaModel: Login2FaModel,
        hasAuthenticator:
          Login2FaModel?.Authenticator || TwoFactorModel?.HasAuthenticator,
        showVerificationForm,
        requiresSetup,
        showSetup,
        showSharedKey,
        showRecoveryCodes,
        verificationCompleted,
        errorMessage,
        showError,
      };
    } else {
      return {};
    }
  }

  getModel = () => {
    const {
      authentication,
      appsettings,
      login2FaModelConnect,
      getTwoFactorModelConnect,
    } = this.props;

    if (authentication?.authenticationStatus == AuthStatus.RequiresTwoFactor) {
      void login2FaModelConnect();
      if (authentication?.rememberMeLogin) {
        this.setState({ editRemember: true, disableRemberMeSwitch: true });
      }
    } else if (
      (authentication?.authenticationStatus == AuthStatus.Authorized &&
        appsettings?.MultiFactorAuthentication ==
          MultiFactorAuthentication.Required) ||
      authentication?.authenticationStatus == AuthStatus.ForceTwoFactor
    ) {
      this.setState({
        requiresSetup: true,
        showSetup: true,
        showVerificationForm: false,
      });
      void getTwoFactorModelConnect();
    }
  };

  componentDidMount() {
    this.getModel();
  }

  componentDidUpdate(prevProps: TwoFactorProps, prevState: EditTwoFactorState) {
    // invoked immediately after updating occurs. This method is not called for the initial render.
    // will not be invoked if shouldComponentUpdate() returns false.

    const { authentication } = this.props;
    const { verificationCompleted } = this.state;

    // Ensuring that it runs only on the cycle that updated `verificationCompleted` state
    if (
      verificationCompleted &&
      !prevState.verificationCompleted &&
      !prevProps.twofactor?.verificationCompleted
    ) {
      // This will set the authentication state to AUTHORIZED
      void this.props.getAuthenticationStatus().then(() => {
        // Getting the latest authentication state
        const { authentication } = this.props;
        const currentStatus = authentication?.authenticationStatus;
        const redirectUrl = authentication?.redirectUrl;
        if (currentStatus == AuthStatus.Authorized) {
          const PresentationId = sessionStorage.getItem(
            ConfigKeys.PresentationId
          );
          if (PresentationId) {
            _history.push(
              `${process.env.PUBLIC_URL}/insights/${PresentationId}`
            );
            sessionStorage.removeItem(ConfigKeys.PresentationId);
          } else {
            _history.push(redirectUrl || process.env.PUBLIC_URL);
          }
        }
      });
    }

    if (
      authentication?.authenticationStatus !=
      prevProps.authentication?.authenticationStatus
    ) {
      this.getModel();
    }
  }

  // Validation Logic
  validateForm() {
    // Ensure that the code field has been filled
    const { editCode } = this.state;
    return editCode.length === 6;
  }

  // Event Handlers
  handleCodeChange = (val: string) => {
    this.setState({ editCode: val });
  };

  handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ editRemember: event.target.checked });
  };

  // handleSharedKeyToggle = (event: React.MouseEvent<HTMLElement>) => {
  //   event.preventDefault();
  //   const { showSharedKey } = this.state;
  //   const { toggleSharedKeyConnect } = this.props;
  //   void toggleSharedKeyConnect(!showSharedKey);
  // };

  // handleContinueToApp = (event: React.MouseEvent<HTMLElement>) => {
  //   event.preventDefault();
  //   const { continueToAppConnect } = this.props;
  //   void continueToAppConnect();
  // };

  handleCodeSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { editCode, editRemember, editLogin2FaModel } = this.state;
    const { confirmLogin2FaCodeConnect, authentication } = this.props;
    const payload = Object.assign({}, editLogin2FaModel, {
      Code: editCode,
      RememberClient: editRemember,
      Authenticator: "Authenticator",
      IsPersistent: authentication ? authentication.rememberMeLogin : false,
    }) as Login2FaModel;
    void confirmLogin2FaCodeConnect(payload);
  };

  // Renders
  renderVerificationForm(): React.ReactNode {
    const { classes, loading, authentication } = this.props;
    const { editCode, editRemember, disableRemberMeSwitch } = this.state;

    const usingApp =
      authentication?.authenticator === TwoFactorModes.Authenticator ||
      authentication?.authenticator === TwoFactorModes.Authenticator;

    return (
      <React.Fragment>
        <form
          className={classes.form}
          id="verificationForm"
          noValidate
          onSubmit={this.handleCodeSubmit}
        >
          <Typography
            id="twoFactorInstructions"
            paragraph={true}
            variant="subtitle2"
            style={{ marginBottom: "1%" }}
          >
            {usingApp
              ? "Please enter the security code from your Authenticator app (Microsoft or Google Authenticator, Authy, etc).The code is valid for 30 seconds."
              : "Enter the code that was sent to your email address. The code is valid for 5 minutes."}
          </Typography>
          <OtpInput
            value={editCode}
            onChange={(e) => {
              this.handleCodeChange(e);
            }}
            numInputs={6}
            shouldAutoFocus={true}
            inputStyle={{
              border: "1px solid black",
              borderRadius: "2px",
              width: "100%",
              height: window.screen.width <= 425 ? "34px" : "45px",
              fontSize: "18px",
              color: "#000",
              fontWeight: "400",
              caretColor: "blue",
              marginRight: window.screen.width <= 320 ? "2%" : "6%",
              WebkitAppearance: "none",
              MozAppearance: "textfield",
            }}
            inputType="number"
            renderInput={(props) => <input id="otpInput" {...props} />}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={editRemember}
                onChange={this.handleSwitchChange}
                id="remember"
                name="remember"
                color="primary"
                disabled={disableRemberMeSwitch}
              />
            }
            label="Remember this device"
          />
          <Button
            id="verifyCode"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!this.validateForm()}
            className={classes.submit}
          >
            {!loading ? "Verify" : "Processing..."}
          </Button>
          {/* <h2 className="resendCode" >Resend Code</h2> */}
          {usingApp && (
            <Box>
              <Link
                id="use-recovery-code"
                onClick={this.toggleRecoveryCodeInput}
                variant="body2"
              >
                Use recovery codes
              </Link>
            </Box>
          )}
        </form>
      </React.Fragment>
    );
  }

  toggleRecoveryCodeInput = () => {
    const { showRecoveryCodeInput } = this.state;
    void this.props.clearTwoFactorConnect();
    this.setState({
      showRecoveryCodeInput: !showRecoveryCodeInput,
      editCode: "",
      editRecoveryCode: "",
    });
  };

  recoveryCodeOnChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { editRecoveryCode } = this.state;

    if (editRecoveryCode != event.target.value) {
      this.setState({ editRecoveryCode: event.target.value });
    }
  };

  handleSubmitRecoveryCode = () => {
    const { confirm2FaRecoveryCode } = this.props;
    const { editRecoveryCode } = this.state;

    void confirm2FaRecoveryCode(editRecoveryCode);
  };

  renderRecoveryCodeInput = () => {
    const { classes } = this.props;
    const { editRecoveryCode } = this.state;

    return (
      <React.Fragment>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="recovery-code-input"
          label="Recovery code"
          name="recoveryCode"
          value={editRecoveryCode}
          autoComplete="off"
          onChange={this.recoveryCodeOnChange}
        ></TextField>
        <Button
          onClick={this.handleSubmitRecoveryCode}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          id="recover-code"
        >
          Submit recovery code
        </Button>
        <Button
          onClick={this.toggleRecoveryCodeInput}
          fullWidth
          variant="contained"
          className={classes.submit}
        >
          Cancel
        </Button>
      </React.Fragment>
    );
  };

  renderErrorMessage(
    showError?: boolean,
    errorMessage?: string,
    closeError?: () => Promise<void>
  ): React.ReactNode {
    const { classes } = this.props;
    // Render the error message from the server
    return showError == true ? (
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={closeError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={closeError} severity="error">
          <Box display="flex" flexDirection="column">
            Invalid authenticator code
            <Button
              href={`${process.env.PUBLIC_URL}/login`}
              color="primary"
              className={classes.loginRedirectButton}
            >
              Click here to login
            </Button>
          </Box>
        </Alert>
      </Snackbar>
    ) : (
      <></>
    );
  }

  renderTwoFactorModes(): React.ReactNode {
    return (
      <React.Fragment>
        <TwoFactorSetup />
      </React.Fragment>
    );
  }

  closeErrorMessage = async () => {
    await this.props.clearTwoFactorConnect();
  };

  public render() {
    const { classes, disbaleLayout } = this.props;
    const {
      showVerificationForm,
      requiresSetup,
      showSetup,
      errorMessage,
      showError,
      showRecoveryCodeInput,
    } = this.state;

    const twoFactorContent = (
      <React.Fragment>
        {this.renderErrorMessage(
          showError,
          errorMessage,
          this.closeErrorMessage
        )}
        {requiresSetup && showSetup && this.renderTwoFactorModes()}
        {showVerificationForm &&
          !showSetup &&
          !showRecoveryCodeInput &&
          this.renderVerificationForm()}
        {showRecoveryCodeInput && this.renderRecoveryCodeInput()}
      </React.Fragment>
    );

    if (disbaleLayout) {
      return <React.Fragment>{twoFactorContent}</React.Fragment>;
    } else {
      return (
        <AuthenticationLayout>
          <div className="otpContainer">
            <h5 className={classes.twoFactorText}>Two-factor authentication</h5>
            {twoFactorContent}
          </div>
        </AuthenticationLayout>
      );
    }
  }

  componentWillUnmount() {
    void this.props.clearTwoFactorConnect();
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  appsettings: state.appsettings,
  authentication: state.authentication,
  twofactor: state.twofactor,
  loading: makeLoadingSelector([ActionTypes.Login2FaRequest])(state),
});

const mapDispatchToProps = {
  getAuthenticationStatus: getAuthenticationStatus,
  toggleSharedKeyConnect: toggleSharedKey,
  clearTwoFactorConnect: clearTwoFactor,
  continueToAppConnect: continueToApp,
  login2FaModelConnect: login2FaModel,
  confirmLogin2FaCodeConnect: confirmLogin2FaCode,
  getTwoFactorModelConnect: getTwoFactorModel,
  enableByAppConnect: enableByApp,
  confirmByAppConnect: confirmByApp,
  enableByEmailConnect: enableByEmail,
  confirmByEmailConnect: confirmByEmail,
  confirm2FaRecoveryCode: confirm2FaRecoveryCode,
};

export default compose(
  withCookies,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(TwoFactor) as React.ComponentType;
