import merge from "lodash/merge";
import { Reducer } from "redux";
import { GroupsAction, GroupSelectAction } from "./actions";
import { ActionTypes } from "../enums/ActionTypes";
import { SecurityGroup } from "../../abstractions/securityGroups/SecurityGroup";
import { SecurityRole } from "../../abstractions/securityGroups/SecurityRole";
import { GroupRole } from "../../abstractions/securityGroups/GroupRole";
import { forEach, cloneDeep } from "lodash";
import { UserAction } from "../users/actions";

export interface GroupsState {
  groups: GroupList;
  roles: RoleList;
  groupRoles: GroupRoleList;
  selectedGroupId?: string;
}

export interface GroupList {
  [Id: string]: SecurityGroup;
}

export interface RoleList {
  [Id: string]: SecurityRole;
}

export interface GroupRoleList {
  [Id: string]: GroupRole;
}

// Updates dashboards store in response to successful dashboards request
const reducer: Reducer<
  GroupsState | undefined,
  GroupsAction | GroupSelectAction | UserAction
> = (
  state: GroupsState | undefined,
  action: GroupsAction | GroupSelectAction | UserAction
) => {
  if (state === undefined) {
    return {
      groups: {},
      roles: {},
      groupRoles: {},
      selectedGroupId: "",
    } as GroupsState;
  }

  let returnState = state;
  switch (action.type) {
    case ActionTypes.GroupRestrictRequest: { 
      const groups = { ...state.groups };
      forEach(groups, (group, key) => {
        if (action.payload?.item && group.Id == action.payload.item["GroupId"]) {
          group.Restricted = !group.Restricted;
          groups[key] = group;
        }
      })
      returnState = merge({}, state, { groups });
    }
      break;

    // Should there be API failure, please change to initial state.
    case ActionTypes.GroupRestrictFailure: {
      const groups = { ...state.groups };
      forEach(groups, (group, key) => {
        if (action.payload?.item && group.Id == action.payload.item["GroupId"]) {
          group.Restricted = !group.Restricted;
          groups[key] = group;
        }
      })
      returnState = merge({}, state, { groups });
    }
      break;

      
    case ActionTypes.GroupsSuccess: {
      if (action.response && action.response) {
        const groupsArray = (action.response as unknown) as SecurityGroup[];
        const groups = {} as GroupList;
        forEach(groupsArray, (group) => {
          groups[group.Id as string] = group;
        });
        returnState = merge({}, state, { groups });
      }
      break;
    }

    case ActionTypes.GroupRolesSuccess: {
      if (action.response && action.response?.GroupRoles) {
        const groupRolesArray = action.response?.GroupRoles as GroupRole[];
        if (groupRolesArray.length) {
          const groupId = groupRolesArray[0].GroupId;
          const groups = {
            [groupId]: {
              Roles: groupRolesArray.map((groupRole) => groupRole.Id),
            },
          };
          const groupRoles = {} as RoleList;
          forEach(groupRolesArray, (groupRole) => {
            groupRoles[groupRole.Id] = groupRole;
          });
          returnState = merge({}, state, { groups, groupRoles });
        }
      }
      break;
    }

    case ActionTypes.RolesSuccess: {
      if (action.response && action.response.value) {
        const rolesArray = action.response.value as SecurityRole[];
        const roles = {} as RoleList;
        forEach(rolesArray, (role) => {
          roles[role.Id] = role;
        });
        returnState = merge({}, state, { roles });
      }
      break;
    }

    case ActionTypes.GroupAddSuccess: {
      if (action.response) {
        returnState = merge({}, state, {
          groups: { [action.response.Id as string]: action.response },
        });
      }
      break;
    }

    case ActionTypes.GroupSelect: {
      returnState = merge({}, state, {
        selectedGroupId: action.selectedGroupId,
      });
      break;
    }

    case ActionTypes.GroupRoleRemoveRequest: {
      if (action.payload && action.payload.item) {
        const stateCopy = cloneDeep(state);
        const group = stateCopy.groups[action.payload.item.GroupId as string];
        const groupRoleId = action.payload.item.Id as string;

        if (group.Roles && group.Roles.indexOf(groupRoleId) !== -1) {
          group.Roles.splice(group.Roles.indexOf(groupRoleId), 1);
        }

        delete stateCopy.groupRoles[groupRoleId];
        returnState = merge({}, stateCopy);
      }
      break;
    }

    case ActionTypes.GroupRoleRemoveFailure: {
      if (
        action.payload &&
        action.payload.prevItem &&
        action.payload.prevItem
      ) {
        const stateCopy = cloneDeep(state);
        const group =
          stateCopy.groups[action.payload.prevItem.GroupId as string];
        group.Roles?.push(action.payload.prevItem.Id as string);
        returnState = merge({}, state, {
          groupRoles: {
            [action.payload.prevItem.Id as string]: action.payload.prevItem,
          },
          groups: {
            [action.payload.prevItem.GroupId as string]: group,
          },
        });
      }
      break;
    }

    case ActionTypes.GroupRoleAddRequest: {
    // For optimistic updating it temporarily assigns a GroupRoleId
    // that is the GroupId appended to the RoleId
      if (action.payload && action.payload.item) {
        const stateCopy = cloneDeep(state);
        const group = stateCopy.groups[action.payload.item.GroupId as string];
        const tempId = `${action.payload.item.GroupId as string}${
          action.payload.item.RoleId as string
        }`;
        if (typeof group.Roles === "undefined") {
          group.Roles = [];
        }
        group.Roles?.push(tempId);
        returnState = merge({}, state, {
          groupRoles: {
            [tempId]: action.payload.item,
          },
          groups: {
            [action.payload.item.GroupId as string]: group,
          },
        });
      }
      break;
    }

    case ActionTypes.GroupRoleAddSuccess: {
    // It must delete the temporary GroupRoleId and reference
    // before adding the correct Id from the server
      if (action.response && action.payload && action.payload.item) {
        const tempId = `${action.payload.item.GroupId as string}${
          action.payload.item.RoleId as string
        }`;
        const stateCopy = cloneDeep(state);
        const group = stateCopy.groups[action.response.GroupId as string];
        if (group.Roles && group.Roles.indexOf(tempId) !== -1) {
          group.Roles.splice(group.Roles.indexOf(tempId), 1);
        }
        delete stateCopy.groupRoles[tempId];
        group.Roles?.push(action.response.Id as string);
        returnState = merge({}, stateCopy, {
          groupRoles: {
            [action.response.Id as string]: action.response,
          },
          groups: {
            [action.response.GroupId as string]: group,
          },
        });
      }
    }
  }

  return returnState;
};

export default reducer;
