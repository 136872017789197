import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../state";
import { AuthStatus } from "../../types";
import LoadingCircle from "./LoadingCircle";

interface StateProps {
  authenticationStatus?: AuthStatus;
}

type AuthLoaderProps = StateProps;

const AuthLoader = ({ authenticationStatus }: AuthLoaderProps) => {
  const showLoader = authenticationStatus == AuthStatus.Processing;
  return showLoader ? <LoadingCircle /> : <></>;
};

const mapStateToProps = (state: ApplicationState) => ({
  authenticationStatus: state.authentication?.authenticationStatus,
});

export default connect(mapStateToProps)(AuthLoader);
