import { CallApiAction } from "../shared/middleware/api";
import { ActionTypes } from "../enums/ActionTypes";
import { Endpoints } from "../enums/Endpoints";
import { Action } from "redux";
import { AppThunkAction } from "../";
import { AppSettingsState } from "./reducers";

export interface AppSettingsAction extends Action {
  type:
    | typeof ActionTypes.AppSettingsRequest
    | typeof ActionTypes.AppSettingsSuccess
    | typeof ActionTypes.AppSettingsFailure   
    | typeof ActionTypes.LoadCacheAppSettingsSuccess
    | typeof ActionTypes.None
  response?: {
    [name: string]: unknown;
    HostNames: Record<string, unknown>;
  };
}

// Fetches AppSettings info from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchAppSettings = (): CallApiAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.AppSettingsRequest,
      ActionTypes.AppSettingsSuccess,
      ActionTypes.AppSettingsFailure,
    ],
    endpoint: Endpoints.AppSettings,
    useBase: true,
    
  },
  type: ActionTypes.CallAPI,
});

export const loadAppSettings = (): AppThunkAction<Action> => (
  dispatch
) => {
  return dispatch(fetchAppSettings());
};

function getLoadAppSettingsSuccess(appSettings: AppSettingsState) {
  return {
    type: ActionTypes.LoadCacheAppSettingsSuccess,
    response: appSettings,
  };
}

export const loadAppSettingsCacheSuccess =
  (appSettings: AppSettingsState): AppThunkAction<Action> =>
    (dispatch) => {
      return dispatch(getLoadAppSettingsSuccess(appSettings));
    };

export interface AppSettingsActionCreators {
  loadAppSettings: typeof loadAppSettings;
  loadAppSettingsCacheSuccess: typeof loadAppSettingsCacheSuccess;
}
