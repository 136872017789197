import { AnyAction } from "redux";
import { AppThunkAction } from "./";
import {
  actions as authenticationActions,
  AuthenticationActionCreators,
} from "./authentication";
import { actions as accountActions, AccountActionCreators } from "./account";
import { actions as userActions, UsersActionCreators } from "./users";
import {
  actions as appsettingsActions,
  AppSettingsActionCreators,
} from "./appsettings";
import {
  actions as localizationActions,
  LocalizationActionCreators,
} from "./localization";
import {
  actions as dashboardsActions,
  DashboardsActionCreators,
} from "./dashboards";
import {
  actions as documentsActions,
  DocumentsActionCreators,
} from "./documents";
import {
  actions as notificationActions,
  NotificationsActionCreators,
} from "./notifications";
import { actions as groupsActions, GroupsActionCreators } from "./groups";
import {
  actions as twoFactorActions,
  TwoFactorActionCreators,
} from "./twofactor";
import {
  actions as insightRequestActions,
  InsightRequestActionCreators,
} from "./insightRequest";
import { actions as insightsActions, InsightsActionCreators } from "./insights";

export type ActionCreators = AuthenticationActionCreators &
AccountActionCreators &
UsersActionCreators &
AppSettingsActionCreators &
LocalizationActionCreators &
DashboardsActionCreators &
DocumentsActionCreators &
NotificationsActionCreators &
GroupsActionCreators &
TwoFactorActionCreators &
InsightRequestActionCreators &
InsightsActionCreators;

const actions = Object.assign(
  {},
  authenticationActions,
  accountActions,
  userActions,
  appsettingsActions,
  dashboardsActions,
  documentsActions,
  localizationActions,
  notificationActions,
  groupsActions,
  twoFactorActions,
  insightRequestActions,
  insightsActions
) as Record<string, (...a: unknown[]) => AppThunkAction<AnyAction>>;

export default actions;
