import { CallApiAction } from "../shared/middleware/api";
import { ActionTypes } from "../enums/ActionTypes";
import { AppThunkAction } from "..";
import { Endpoints } from "../enums/Endpoints";
import { Request } from "../../abstractions/request/Request";

export interface InsightsAction extends CallApiAction {
  type:
  | typeof ActionTypes.InsightsRequest
  | typeof ActionTypes.InsightsSuccess
  | typeof ActionTypes.InsightsFailure
  | typeof ActionTypes.InsightsAttributeGroupsRequest
  | typeof ActionTypes.InsightsAttributeGroupsSuccess
  | typeof ActionTypes.InsightsAttributeGroupsFailure
  | typeof ActionTypes.InsightReportRequest
  | typeof ActionTypes.InsightReportSuccess
  | typeof ActionTypes.InsightReportFailure
  | typeof ActionTypes.CallAPI
  | typeof ActionTypes.None;
  response?: {
    entities?: {
      request?: Record<string, Request>;
    };
    [name: string]: unknown;
  };
}

export enum InsightsTypes {
  Dias = "Dias",
  Sentiments = "Sentiments",
}

// Fetches Insights from API based on Request Id
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchInsights = (
  requestId: string,
  insightsType: InsightsTypes
): InsightsAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.InsightsRequest,
      ActionTypes.InsightsSuccess,
      ActionTypes.InsightsFailure,
    ],
    endpoint: `${Endpoints.Requests}/${requestId}/Insights`,
  },
  payload: {
    item: {
      requestId: requestId,
      insightsType: insightsType,
    },
  },
  type: ActionTypes.CallAPI,
});

/**
 * @function loadInsights
 * @param requestId {string} id of the request with insights to be loaded
 * @returns a InsightsAction promise to load the insights associated with the survey request into state from the server
 */
export const loadInsights =
  (
    requestId: string,
    insightsType: InsightsTypes
  ): AppThunkAction<InsightsAction> =>
    (dispatch) => {
      return dispatch(fetchInsights(requestId, insightsType));
    };

// Fetches the  from API based on Request Id
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchInsightGroups = (): InsightsAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.InsightsAttributeGroupsRequest,
      ActionTypes.InsightsAttributeGroupsSuccess,
      ActionTypes.InsightsAttributeGroupsFailure,
    ],
    endpoint: `${Endpoints.Insights}/Groups?$orderby=Ordinal`,
  },
  type: ActionTypes.CallAPI,
});

/**
 * @function loadInsightGroups
 * @param requestId {string} id of the request with insight attribute groups to be loaded
 * @returns a InsightsAction promise to load the attribute groups for insights loaded into state from the server
 */
export const loadInsightGroups =
  (): AppThunkAction<InsightsAction> => (dispatch) => {
    return dispatch(fetchInsightGroups());
  };

const getInsightReport = (requestId: string): InsightsAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.InsightReportRequest,
      ActionTypes.InsightReportSuccess,
      ActionTypes.InsightReportFailure,
    ],
    endpoint: `${Endpoints.Insights}/Export/${requestId}`,
    options: {
      'acceptType': '*',
    }
  },
  type: ActionTypes.CallAPI,
});

export const generateInsightReport =
  (requestId: string): AppThunkAction<InsightsAction> => (dispatch) => {
    return dispatch(getInsightReport(requestId));
  };

export interface InsightsActionCreators {
  loadInsights: typeof loadInsights;
  loadInsightGroups: typeof loadInsightGroups;
  generateInsightReport: typeof generateInsightReport;
}
