import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { CircularProgress } from "@mui/material";
import "./common.css";

interface InsightTypes {
  //   [x: string]: any;
  Attachments: unknown[];
  CreationTime: string;
  CreatorUser: unknown;
  CreatorUserId: string;
  Description: string;
  EndDate: string;
  Id: string;
  Name: string;
  StartDate: string;
  State: string;
  Status: string;
  Tags: unknown;
  TypeId: string;
}

interface types {
  recentlyCreated: InsightTypes[];
  height?: string;
  width?: string;
  onReportDownload: (requestId: string) => void;
}

const MyCarousel = (props: types) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const { recentlyCreated, height, width, onReportDownload } = props;

  return (
    <Carousel>
      {recentlyCreated.map((ele, index) => (
        <Carousel.Item key={index}>
          <div
            style={{
              height: height,
              width: width,
              background: "white",
              borderRadius: "5px",
            }}
            id="card"
          >
            <div className="recentlyCreatedCards" id="recentlyCreatedCards">
              <div className="downloadCOntainer">
                {ele.Status === "Completed" ? (
                  <img
                    className="downloadImg"
                    id="download"
                    src={""}
                    onClick={() => {
                      onReportDownload(ele.Id);
                    }}
                  />
                ) : (
                  <CircularProgress
                    style={{ color: "#D9D9D9" }}
                    className="circularLoader"
                  />
                )}
              </div>
              <div className="tableContent">
                <p
                  style={{
                    margin: 0,
                    width: "70%",
                    color: "#270566",
                    fontWeight: "600",
                  }}
                >
                  {ele?.Name}
                </p>
                <p style={{ margin: 0, color: "#5D1CD4", fontWeight: "600" }}>
                  {new Date(ele?.CreationTime).toLocaleDateString("en-US")}
                </p>
                <p className="statusContainer">{ele?.Status}</p>
              </div>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default MyCarousel;
