import { AppThunkAction } from "..";
import { LoginResult, UserCredentials } from "../../types";
import { ActionTypes } from "../enums/ActionTypes";
import { Endpoints } from "../enums/Endpoints";
import { CallApiAction } from "../shared/middleware/api";

export interface LoginAction extends CallApiAction {
  type:
    | typeof ActionTypes.LoginRequest
    | typeof ActionTypes.LoginSuccess
    | typeof ActionTypes.LoginFailure
    | typeof ActionTypes.SetCookiesSuccess
    | typeof ActionTypes.SetCookiesRequest
    | typeof ActionTypes.SetCookiesFailure
    | typeof ActionTypes.LogoutRequest
    | typeof ActionTypes.LogoutSuccess
    | typeof ActionTypes.LogoutFailure

    | typeof ActionTypes.PasswordLessLoginRequest
    | typeof ActionTypes.PasswordLessLoginSuccess
    | typeof ActionTypes.PasswordLessLoginFailure

    | typeof ActionTypes.DeleteRememberMeRequest
    | typeof ActionTypes.DeleteRememberMeSuccess
    | typeof ActionTypes.DeleteRememberMeFailure

    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  response?: Record<string, LoginResult | unknown>;
  payload?: {
    item?: UserCredentials;
    prevItem?: UserCredentials;
  };
  redirectUrl?: string;
  status?: number;
}

export interface CheckAuthenticationAction extends CallApiAction {
  type:
    | typeof ActionTypes.CheckAuthenticationRequest
    | typeof ActionTypes.CheckAuthenticationSuccess
    | typeof ActionTypes.CheckAuthenticationFailure
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  response?: Record<string, unknown>;
  redirectUrl?: string;
}

export interface RequireTwoFactorAction extends CallApiAction {
  type:
    | typeof ActionTypes.RequireTwoFactorRequest
    | typeof ActionTypes.RequireTwoFactorSuccess
    | typeof ActionTypes.RequireTwoFactorFailure
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  response?: Record<string, unknown>;
  payload?: {
    item?: Record<string, unknown>;
    prevItem?: Record<string, unknown>;
  };
  redirectUrl?: string;
}

export interface PasswordResetAction extends CallApiAction {
  type:
    | typeof ActionTypes.ForgotPasswordRequest
    | typeof ActionTypes.ForgotPasswordSuccess
    | typeof ActionTypes.ForgotPasswordFailure
    | typeof ActionTypes.ResetPasswordRequest
    | typeof ActionTypes.ResetPasswordSuccess
    | typeof ActionTypes.ResetPasswordFailure
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
}

export interface RegisterUserAction extends CallApiAction {
  type:
    | typeof ActionTypes.RegisterUserRequest
    | typeof ActionTypes.RegisterUserSuccess
    | typeof ActionTypes.RegisterUserFailure
    | typeof ActionTypes.AccountInvitationRequest
    | typeof ActionTypes.AccountInvitationSuccess
    | typeof ActionTypes.AccountInvitationFailure
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  status?: number;
}

// Fetches authentication info from server
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchAuthentication = (
  redirectUrl?: string
): CheckAuthenticationAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.CheckAuthenticationRequest,
      ActionTypes.CheckAuthenticationSuccess,
      ActionTypes.CheckAuthenticationFailure,
    ],
    endpoint: Endpoints.Login,
    useBase: true,
    disableRefresh: true,
    options: {
      acceptType: "application/json",
    },
  },
  type: ActionTypes.CallAPI,
  redirectUrl,
});

export const getAuthenticationStatus =
  (redirectUrl?: string): AppThunkAction<CheckAuthenticationAction> =>
    (dispatch) => {
      return dispatch(fetchAuthentication(redirectUrl));
    };

// Updates account info with a patch call to API
// In progress
const submitLogin = (payload: UserCredentials): LoginAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.LoginRequest,
      ActionTypes.LoginSuccess,
      ActionTypes.LoginFailure,
    ],
    endpoint: Endpoints.Login,
    useBase: true,
    disableRefresh: true,
    options: {
      method: "POST",
      body: JSON.stringify(payload),
      acceptType: "application/json",
    },
  },
  payload: {
    item: payload,
  },
  type: ActionTypes.CallAPI,
});

export const loginUser =
  (payload: UserCredentials): AppThunkAction<LoginAction> =>
    (dispatch) => {
      return dispatch(submitLogin(payload));
    };

const submitPasswordLessLogin = (payload: UserCredentials): LoginAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.PasswordLessLoginRequest,
      ActionTypes.PasswordLessLoginSuccess,
      ActionTypes.PasswordLessLoginSuccess,
    ],
    endpoint: Endpoints.PasswordLessLogin,
    useBase: true,
    disableRefresh: true,
    options: {
      method: "POST",
      body: JSON.stringify(payload),
      acceptType: "application/json",
    },
  },
  payload: {
    item: payload,
  },
  type: ActionTypes.CallAPI,
});

export const passwordLessLogin =
  (payload: UserCredentials): AppThunkAction<LoginAction> =>
    (dispatch) => {
      return dispatch(submitPasswordLessLogin(payload));
    };

const setUserCookies = (): LoginAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.SetCookiesRequest,
      ActionTypes.SetCookiesSuccess,
      ActionTypes.SetCookiesFailure,
    ],
    endpoint: Endpoints.Success,
    useBase: true,
    disableRefresh: false,
    options: {
      acceptType: "application/json",
    },
  },
  type: ActionTypes.CallAPI,
});
export const loginSuccess = (): AppThunkAction<LoginAction> => (dispatch) => {
  return dispatch(setUserCookies());
};

export const passwordLessLoginSuccess = (): AppThunkAction<LoginAction> => (dispatch) => {
  return dispatch(setUserCookies());
};

const submitLogout = (): LoginAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.LogoutRequest,
      ActionTypes.LogoutSuccess,
      ActionTypes.LogoutFailure,
    ],
    endpoint: Endpoints.Logout,
    useBase: true,
  },
  type: ActionTypes.CallAPI,
});

export const logoutUser = (): AppThunkAction<LoginAction> => (dispatch) => {
  return dispatch(submitLogout());
};

const postForgotPassword = (
  payload: Record<string, string>
): PasswordResetAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.ForgotPasswordRequest,
      ActionTypes.ForgotPasswordSuccess,
      ActionTypes.ForgotPasswordFailure,
    ],
    endpoint: Endpoints.PasswordResetStep1,
    useBase: true,
    options: {
      method: "POST",
      body: JSON.stringify(payload),
      acceptType: "application/json",
    },
  },
  payload: {
    item: payload,
  },
  type: ActionTypes.CallAPI,
});

export const forgotPassword =
  (email: string): AppThunkAction<PasswordResetAction> =>
    (dispatch) => {
      const payload = {
        Email: email,
      };
      return dispatch(postForgotPassword(payload));
    };

const postResetPassword = (
  payload: Record<string, string>
): PasswordResetAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.ResetPasswordRequest,
      ActionTypes.ResetPasswordSuccess,
      ActionTypes.ResetPasswordFailure,
    ],
    endpoint: Endpoints.PasswordResetStep2,
    useBase: true,
    options: {
      method: "POST",
      body: JSON.stringify(payload),
      acceptType: "application/json",
    },
  },
  payload: {
    item: payload,
  },
  type: ActionTypes.CallAPI,
});

export const resetPassword =
  (password: string, token: string): AppThunkAction<PasswordResetAction> =>
    (dispatch) => {
      const payload = {
        NewPassword: password,
        Token: token,
      };
      return dispatch(postResetPassword(payload));
    };

// Register new user by requesting client create new user
const postCreateUser = (
  payload: Record<string, string>
): RegisterUserAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.RegisterUserRequest,
      ActionTypes.RegisterUserSuccess,
      ActionTypes.RegisterUserFailure,
    ],
    endpoint: Endpoints.CreateUser,
    useBase: true,
    options: {
      method: "POST",
      body: JSON.stringify(payload),
      acceptType: "application/json",
    },
  },
  payload: {
    item: payload,
  },
  type: ActionTypes.CallAPI,
});

export const registerUser =
  (payload: Record<string, string>): AppThunkAction<RegisterUserAction> =>
    (dispatch) => {
      return dispatch(postCreateUser(payload));
    };

// Check user status using user invitation token
const getAccountInvitation = (token: string): RegisterUserAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.AccountInvitationRequest,
      ActionTypes.AccountInvitationSuccess,
      ActionTypes.AccountInvitationFailure,
    ],
    endpoint: Endpoints.AccountInvitationCallback + `?token=${token}`,
    useBase: true,
  },
  payload: {
    item: {
      token,
    },
  },
  type: ActionTypes.CallAPI,
});

export const checkAccountInvitation =
  (token: string): AppThunkAction<RegisterUserAction> =>
    (dispatch) => {
      return dispatch(getAccountInvitation(token));
    };

const delete2FaCookies = (): LoginAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.DeleteRememberMeRequest,
      ActionTypes.DeleteRememberMeSuccess,
      ActionTypes.DeleteRememberMeFailure,
    ],
    endpoint: Endpoints.DeleteRememberMe,
    useBase: true,
    options: {
      method: "DELETE",
    },
  },
  type: ActionTypes.CallAPI,
});

export const deleteRememberMeCookies =
    (): AppThunkAction<LoginAction> => (dispatch) => {
      return dispatch(delete2FaCookies());
    };

export interface AuthenticationActionCreators {
  getAuthenticationStatus: typeof getAuthenticationStatus;
  checkAccountInvitation: typeof checkAccountInvitation;
  loginUser: typeof loginUser;
  loginSuccess: typeof loginSuccess;
  logoutUser: typeof logoutUser;
  passwordLessLogin: typeof passwordLessLogin;
  passwordLessLoginSuccess: typeof passwordLessLoginSuccess;
  forgotPassword: typeof forgotPassword;
  resetPassword: typeof resetPassword;
  registerUser: typeof registerUser;
  deleteRememberMeCookies: typeof deleteRememberMeCookies;
}
