import { CallApiAction } from "../shared/middleware/api";
import { ActionTypes } from "../enums/ActionTypes";
import { Endpoints } from "../enums/Endpoints";
import { Action } from "redux";
import { AppThunkAction } from "../";
import { LocalizationState } from "./reducers";

export interface LocalizationAction extends Action {
  type:
    | typeof ActionTypes.LocalizationRequest
    | typeof ActionTypes.LocalizationSuccess
    | typeof ActionTypes.LocalizationFailure
    | typeof ActionTypes.LoadCacheLocalizationSuccess
    | typeof ActionTypes.None;
  response: Record<string, unknown>;
}

// Fetches Localization info from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchLocalization = (): CallApiAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.LocalizationRequest,
      ActionTypes.LocalizationSuccess,
      ActionTypes.LocalizationFailure,
    ],
    endpoint: Endpoints.Localization,
    useBase: true,
  },
  type: ActionTypes.CallAPI,
});

export const loadLocalization =
  (): AppThunkAction<CallApiAction> => (dispatch) => {
    return dispatch(fetchLocalization());
  };

function getLoadCachedLocalizationSuccess(
  cachedLocalization: LocalizationState
) {
  return {
    type: ActionTypes.LoadCacheLocalizationSuccess,
    response: cachedLocalization,
  };
}

export const loadCachedLocalizationSuccess =
  (cachedLocalization: LocalizationState): AppThunkAction<Action> =>
    (dispatch) => {
      return dispatch(getLoadCachedLocalizationSuccess(cachedLocalization));
    };

export interface LocalizationActionCreators {
  loadLocalization: typeof loadLocalization;
  loadCachedLocalizationSuccess: typeof loadCachedLocalizationSuccess;
}
