import { reducer as errors } from "./Errors";
import { reducer as account } from "./account";
import { reducer as appsettings } from "./appsettings";
import { reducer as authentication } from "./authentication";
import { reducer as dashboards } from "./dashboards";
import { reducer as groups } from "./groups";
import { reducer as insightRequest } from "./insightRequest";
import { reducer as insights } from "./insights";
import { reducer as loading } from "./loading";
import { reducer as localization } from "./localization";
import { reducer as notifications } from "./notifications";
import { reducer as twofactor } from "./twofactor";
import { reducer as users } from "./users";

const reducers = {
  authentication,
  account,
  appsettings,
  dashboards,
  loading,
  localization,
  users,
  notifications,
  groups,
  twofactor,
  insightRequest,
  insights,
  errors
};

export default reducers;