import { History } from "history";
import JSCookies from "js-cookie";
import { cloneDeep, isEmpty, merge } from "lodash";
import { normalize, Schema } from "normalizr";
import { Cookies } from "react-cookie";
import { Dispatch, Middleware, MiddlewareAPI } from "redux";
import { ApiError, ErrorStr, RequestFailedError } from "../../../types/";
import { ActionTypes } from "../../enums/ActionTypes";
import { Endpoints } from "../../enums/Endpoints";
import { ProcessingResult } from "../../Errors";
import { ApplicationState, PayloadAction } from "../../index";

// Takes an ODATA query object and makes it into a valid ODATA query string
// TODO: Currently placeholder, will implement logic along with more complex ODATA queries
const makeODATAQuery = (query: Record<string, unknown>) => {
  let queryString = "";

  if (typeof query["query"] !== "undefined") {
    queryString = query["query"] as string;
  }

  return queryString;
};

// Keeps track of the token refresh API call
let refreshing: Promise<Response | undefined> | null = null;
export const callRefreshToken = (history?: History<unknown>, TokenName?: string) => {
  return fetch(Endpoints.Refresh, {
    method: "post",
    headers: {
      accept: "application/json",
      "x-xsrf-token": JSCookies?.get("XSRF-TOKEN") as string,
    },
  }).then(
    (response) => {
      refreshing = null;
      // Check to see if the token has actually been added
      if (
        response.status >= 200 &&
        response.status < 300 &&
        TokenName &&
        JSCookies.get(TokenName)
      ) {
        return response;
      } else {
        if (
          window &&
          history &&
          history.location.pathname != `${process.env.PUBLIC_URL}/login`
        ) {
          if (response.status === 500) {
            window.location.assign(`${process.env.PUBLIC_URL}/logout`);
          } else {
            window.location.assign(`${process.env.PUBLIC_URL}/login`);
          }
        }
        throw new RequestFailedError(
          `Could not refresh authentication token, status code ${response.status}`
        );
      }
    },
    (error) => {
      refreshing = null;
      if (
        window &&
        history &&
        history.location.pathname != `${process.env.PUBLIC_URL}/login`
      ) {
        window.location.assign(`${process.env.PUBLIC_URL}/login`);
      }
      throw error;
    }
  );
};

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = async (
  history?: History<unknown>,
  endpoint?: string,
  TokenName?: string,
  options: Record<string, unknown> = {}, // This will most probably always be <string, string>
  schema?: Schema,
  disableRefresh?: boolean
): Promise<unknown> => {
  // Deal with Content-Type being set to undefined
  let includeContentType = true;
  if ("contentType" in options) {
    includeContentType = !(typeof options.contentType === "undefined");
  }

  // Configure fetchOptions object
  const fetchOptions = cloneDeep(options);
  delete fetchOptions.contentType;
  delete fetchOptions.acceptType;
  const updatedOptions = {
    ...fetchOptions,
    headers: {
      ...(includeContentType && {
        "Content-Type": options.contentType
          ? (options.contentType as string)
          : "application/json",
      }),
      Accept: options.acceptType
        ? (options.acceptType as string)
        : "application/json",
      ...(JSCookies && {
        Authorization: `Bearer ${JSCookies.get(TokenName as string) as string}`,
      }),
      "x-xsrf-token": JSCookies?.get("XSRF-TOKEN") as string,
      credentials: "include",
    },
  };

  return fetch(endpoint as string, updatedOptions).then(
    async (response) => {
      // If the response is 401 the first time around, refresh the API token and try it again
      if (response.status === 401 && !disableRefresh) {
        if (!refreshing) {
          refreshing = callRefreshToken(history, TokenName);
        }
        await refreshing;
        // Tries to refresh and then retries the call once, disableRefresh is set to true for subsequent calls
        return callApi(history, endpoint, TokenName, options, schema, true);
      } else if (response.status === 402) {
        const contentType = response.headers.get("content-type");
        if (
          contentType &&
          (contentType.indexOf("application/problem+json") > -1 ||
            contentType.indexOf("application/json") > -1)
        ) {
          // Return a rejected Promise with the error as the reason
          return Promise.reject(
            await response
              .json()
              .then((parsedError: ProcessingResult | string) => {
                const errorMessage =
                  typeof parsedError === "string"
                    ? parsedError
                    : parsedError?.Error?.Message ||
                      parsedError?.Message ||
                      parsedError?.Result?.toString();

                return new RequestFailedError(errorMessage, {
                  cause: response.status,
                });
              })
          );
        }
      } else if (response.status >= 200 && response.status < 400) {
        const contentType = response.headers.get("content-type");
        // If content is json, then parse it
        // if there is a schema, then normalize the json
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then(
            (json: Record<string, unknown>) => {
              let value: Record<string, unknown>[] | Record<string, unknown> =
                [];
              if (json && json.value && Array.isArray(json.value)) {
                value = json.value as Record<string, unknown>[];
              } else if (json && json.value && typeof json.value === "object") {
                value = json.value as Record<string, unknown>;
              } else if (json && typeof json === "number") {
                return json;
              }

              if (schema) {
                return merge(
                  {},
                  json,
                  normalize(!isEmpty(value) ? value : json, schema)
                );
              }
              return merge({}, json);
            },
            (parseError: unknown) => {
              if (response.status >= 200 && response.status < 300) {
                console.warn(
                  "Error parsing json response, but the result was OK",
                  response
                );
                return { status: response.status, error: parseError };
              }
            }
          );
        } else if (contentType && contentType.indexOf("text/plain") !== -1) {
          // If content is text, return text
          return response.text();
        } else if (contentType && contentType.indexOf("text/csv") !== -1) {
          // If content is text/csv - readable stream, return blob
          return response.blob();
        } else {
          // If content is not json or text, then just return the response as-is
          return Promise.resolve(response);
        }
      } else if (response.status >= 400) {
        const contentType = response.headers.get("content-type");
        if (
          contentType &&
          (contentType.indexOf("application/problem+json") > -1 ||
            contentType.indexOf("application/json") > -1)
        ) {
          const returnError = await response
            .json()
            .then((parsedError: ProcessingResult | string) => {
              const errorMessage =
                typeof parsedError === "string"
                  ? parsedError
                  : parsedError?.Error?.Message ||
                    parsedError?.Message ||
                    parsedError?.Result?.toString();

              return new RequestFailedError(errorMessage, {
                cause: response.status,
              });
            });
          throw returnError;
        } else if (contentType && contentType.indexOf("text/plain") !== -1) {
          const responseText = await response.text();
          throw new RequestFailedError(
            responseText
              ? responseText
              : `Fetch failed with status code ${response.status}`
          );
        } else {
          throw new RequestFailedError(
            `Fetch failed with status code ${response.status}`
          );
        }
      }
    },
    (error: Error) => {
      //console.error(error);
      throw error;
    }
  );
};

export interface CallApiAction extends PayloadAction {
  [ActionTypes.CallAPI]?: {
    types: [ActionTypes, ActionTypes, ActionTypes];
    endpoint: string | ((state: ApplicationState) => string);
    cookies?: Cookies;
    options?: Record<string, unknown>;
    useBase?: boolean;
    useMy?: boolean;
    schema?: Schema;
    disableRefresh?: boolean;
  };
  [ActionTypes.CallODATA]?: Record<string, unknown>;
  response?: Record<string, unknown> | Record<string, unknown>[];
  error?:
    | {
      Error: ApiError;
    }
    | string;
}

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
const apiMiddleware =
  (history?: History<unknown>): Middleware =>
    (store: MiddlewareAPI) =>
      (next: Dispatch) =>
        (action: CallApiAction) => {
          const callAPI = action[ActionTypes.CallAPI];
          const callODATA = action[ActionTypes.CallODATA];

          if (typeof callAPI === "undefined") {
            return next(action);
          }

          let { endpoint } = callAPI;
          const { schema, types, options, useBase, useMy, disableRefresh } = callAPI;

          if (typeof endpoint === "function") {
            endpoint = endpoint(store.getState() as ApplicationState);
          }

          if (typeof endpoint !== "string") {
            throw new RequestFailedError("Specify a string endpoint URL.");
          }

          const { apiurl, oDataurl, myurl, TokenName } = (
            store.getState() as ApplicationState
          ).appsettings;

          // Construct full endpoint url based on whether it is an api or odata call
          // Will skip if the endpoint string passed already has the baseurl in it
          // If useMy is true will use /my/ url
          if (!useBase) {
            if (useMy) {
              endpoint = endpoint.indexOf(myurl) === -1 ? myurl + endpoint : endpoint;
              if (callODATA) {
                endpoint = endpoint + makeODATAQuery(callODATA);
              }
            } else if (typeof callODATA === "undefined" || callODATA === null) {
              endpoint =
          endpoint.indexOf(apiurl) === -1 ? apiurl + endpoint : endpoint;
            } else {
              endpoint =
          endpoint.indexOf(oDataurl) === -1
            ? oDataurl + endpoint + makeODATAQuery(callODATA)
            : endpoint + makeODATAQuery(callODATA);
            }
          }

          if (!Array.isArray(types) || types.length !== 3) {
            throw new RequestFailedError("Expected an array of three action types.");
          }
          if (!types.every((type) => typeof type === "string")) {
            throw new RequestFailedError("Expected action types to be strings.");
          }

          const actionWith = (body: Record<string, unknown>) => {
            const finalAction = Object.assign({}, action, body);
            delete finalAction[ActionTypes.CallAPI];
            delete finalAction[ActionTypes.CallODATA];
            return finalAction;
          };

          const [requestType, successType, failureType] = types;
          next(actionWith({ type: requestType }));

          return callApi(
            history,
            endpoint,
            TokenName,
            options,
            schema,
            disableRefresh
          ).then(
            (response) =>
              next(
                actionWith({
                  response,
                  type: successType,
                })
              ),
            (error: RequestFailedError) =>
              next(
                actionWith({
                  type: failureType,
                  error: error.message || ErrorStr.Default,
                  status: error.cause,
                })
              )
          );
        };
export default apiMiddleware;
