import merge from "lodash/merge";
import { Reducer } from "redux";
import { AppSettingsAction } from "./actions";
import { ActionTypes } from "../enums/ActionTypes";
import { DashboardSettings } from "../../abstractions/dashboardSettings/DashboardSettings";
import { IdentityProviders, MultiFactorAuthentication } from "../../types";

export interface AppSettingsState {
  baseurl: string;
  apiurl: string;
  oDataurl: string;
  signalRurl: string;
  myurl: string;
  TokenName: string;
  TokenTimeout: number;
  Modules: string[];
  ApplicationTitle: string;
  HostNames: {
    [name: string]: string;
  };
  Meta: Record<string, unknown>;
  CassPaths: string[];
  Version?: string;
  globalDashboardSettings?: DashboardSettings;
  LocalAuthentication?: boolean;
  IdentityProviders?: IdentityProviders;
  ShowLoginButtonAlways?: boolean;
  MultiFactorAuthentication?: MultiFactorAuthentication;
  AppInsightsInstrumentKey?: string;
  GoogleAnalyticsTagId?: string;
  FileOptions?: {
    MaximumFileSize?: number;
    ChunkSize?: number;
  }
}

// Updates appsettings store in response to successful appsettings request
const reducer: Reducer<AppSettingsState | undefined, AppSettingsAction> = (
  state: AppSettingsState | undefined,
  action: AppSettingsAction
) => {
  if (action.type === ActionTypes.AppSettingsSuccess && action.response) {
    const apiurl = `${action.response.HostNames.Server as string}api`;
    const oDataurl = `${action.response.HostNames.Server as string}odata`;
    const signalRurl = `${action.response.HostNames.SignalR as string}hubs`;
    const myurl = `${action.response.HostNames.Server as string}my`;
    return merge({}, state, action.response, {
      apiurl,
      oDataurl,
      signalRurl,
      myurl,
    }) as AppSettingsState;
  }

  if (
    action.type === ActionTypes.LoadCacheAppSettingsSuccess &&
    action.response
  ) {
    const apiurl = `${action.response.HostNames.Server as string}api`;
    const oDataurl = `${action.response.HostNames.Server as string}odata`;
    const signalRurl = `${action.response.HostNames.SignalR as string}hubs`;
    const myurl = `${action.response.HostNames.Server as string}my`;
    return merge({}, state, action.response, {
      apiurl,
      oDataurl,
      signalRurl,
      myurl,
    }) as AppSettingsState;
  }
  if (state === undefined) {
    return {
      baseurl: "",
      apiurl: "",
      oDataurl: "",
      signalRurl: "",
      myurl: "",
      TokenName: "",
      TokenTimeout: 15,
      Modules: [],
      ApplicationTitle: "XStereotype",
      HostNames: {
        Server: "",
        SignalR: "",
      },
      Meta: {},
      CassPaths: [],
      globalDashboardSettings: {
        showGridLines: true,
      },
      LocalAuthentication: true,
      IdentityProviders: IdentityProviders.Default,
      AppInsightsInstrumentKey: "",
      GoogleAnalyticsTagId: "",
      FileOptions: {
        MaximumFileSize: 0,
        ChunkSize: 0
      }
    } as AppSettingsState;
  }

  return state;
};

export default reducer;
