import { createTheme } from "@material-ui/core";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

interface TabPalette {
  text: string;
  icon: string;
  selectedText: string;
  selectedIcon: string;
}

declare module "@material-ui/core/styles/createPalette" {
  export interface Palette {
    toolbar: Palette["primary"];
    toolbarTabs: TabPalette;
    legend1: Palette["primary"];
    legend2: Palette["primary"];
    legend3: Palette["primary"];
    legend4: Palette["primary"];
    dashboard1: Palette["primary"];
    dashboard2: Palette["primary"];
    dashboard3: Palette["primary"];
    dashboard4: Palette["primary"];
    dashboard5: Palette["primary"];
    dashboard6: Palette["primary"];
    dashboard7: Palette["primary"];
  }
  export interface PaletteOptions {
    toolbar: PaletteOptions["primary"];
    toolbarTabs: Partial<TabPalette>;
    legend1: PaletteOptions["primary"];
    legend2: PaletteOptions["primary"];
    legend3: PaletteOptions["primary"];
    legend4: PaletteOptions["primary"];
    dashboard1: PaletteOptions["primary"];
    dashboard2: PaletteOptions["primary"];
    dashboard3: PaletteOptions["primary"];
    dashboard4: PaletteOptions["primary"];
    dashboard5: PaletteOptions["primary"];
    dashboard6: PaletteOptions["primary"];
    dashboard7: PaletteOptions["primary"];
  }
}

declare module "@material-ui/core/styles" {
  interface Theme {
    requestInbox: {
      width: number;
    };
    header: {
      height: number;
    };
    backgroundStyles: {
      linearGradient: string;
      notificationPopOverBackground:string;
    };
    chartStyles: {
      negativeRGB: number[];
      positiveRGB: number[];
    };
  }
  interface ThemeOptions {
    requestInbox: {
      width: number;
    };
    header: {
      height: number;
    };
    backgroundStyles: {
      linearGradient: string;
      notificationPopOverBackground: string;
    };
    chartStyles: {
      negativeRGB: number[];
      positiveRGB: number[];
    };
  }
}

const palette = {
  primary: {
    main: "#5D1CD4",
    dark: "#270566",
    light: "#6D2CE4",
  },
  secondary: {
    main: "#6456bb",
  },
  info: {
    main: "#8e49f1",
    light: "#d8eaf2",
  },
  background: {
    default: "#fff",
  },
  toolbar: {
    main: "#2e1a48",
    dark: "#080925",
    light: "#000",
  },
  toolbarTabs: {
    text: "#4d7787",
    icon: "#dddddd",
    selectedText: "white",
    selectedIcon: "white",
  },
  text: {
    primary: "#210c51",
    fontFamily:"Roboto, sans-serif",
    // fontFamily:"Poppins, sans-serif",    
  },
  legend1: {
    main: "#e94470",
  },
  legend2: {
    main: "#11ab7a",
  },
  legend3: {
    main: "#df6f5d",
  },
  legend4: {
    main: "#f7b567",
  },
  dashboard1: {
    main: "#083d77",
    light: "rgba(8, 61, 119, 0.5)",
  },
  dashboard2: {
    main: "#f54129",
    light: "rgba(245, 65, 41, 0.5)",
  },
  dashboard3: {
    main: "#f3cc61",
    light: "rgba(243, 204, 97, 0.5)",
  },
  dashboard4: {
    main: "#99b2dd",
    light: "rgba(153, 178, 221, 0.5)",
  },
  dashboard5: {
    main: "#3f1d3e",
    light: "rgba(63, 29, 62, 0.5)",
  },
  dashboard6: {
    main: "#8d6346",
    light: "rgba(141, 99, 70, 0.5)",
  },
  dashboard7: {
    main: "#474b24",
    light: "rgba(71, 75, 36, 0.5)",
  },
};

export const theme = createTheme({
  palette: palette as PaletteOptions,
  typography:{
    fontFamily: "Roboto, sans-serif",
    // fontFamily: "Proxima Nova, sans-serif",
    // fontFamily:"Syne, sans-serif", 
  },
  requestInbox: {
    width: 300,
  },
  header: {
    height: 70,
  },
  backgroundStyles: {
    linearGradient: "linear-gradient(256.59deg,rgba(169,158,237,0.12) -74.18%,#4c328f 99.94%)" /*#8271b*/,
    notificationPopOverBackground: "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))"
  },
  chartStyles: {
    negativeRGB: [255, 0, 0],
    positiveRGB: [22, 55, 178],
  },
  overrides: {
    MuiTab: {
      textColorPrimary: {
        color: "white",
        "&$selected": {
          color: "white",
        },
      },
    },
    MuiTabs: {
      root: {
        "& button:focus": {
          outline: "none",
        },
      },
      scrollButtons: {
        width: 0,
      },
    },
    MuiListItem: {
      button: {
        "&:hover": {
          color: "inherit",
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: 11,
        height: 24,
        padding: `4px 15px 5px 18px`,
        borderRadius: 4,
      },
      outlined: {
        border: `1px solid ${palette.secondary.main}`,
        color: `white`,
      },
      containedPrimary: {
        border: `1px solid ${palette.secondary.main}`,
      },
      contained: {
        "&.Mui-disabled": {
          color: `rgba(155, 155, 155, 0.8)`,
          backgroundColor: `rgba(155, 155, 155, 0.12)`,
        },
        textTransform:"none"
      },
      text: {
        "&:hover": {
          color: "inherit",
        },
      },
    },
  },
});
