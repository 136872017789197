import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import history from "_history";

export const reactPlugin = new ReactPlugin();

export const createAppInsights = (instrumentationKey: string) => {
  const insights = new ApplicationInsights({
    config: {
      instrumentationKey,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history },
      },
    },
  });
  insights.loadAppInsights();
  return insights;
}