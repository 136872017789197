import merge from "lodash/merge";
import { Reducer } from "redux";
import { LocalizationAction } from "./actions";
import { ActionTypes } from "../enums/ActionTypes";

export interface LocalizationState {
  [key: string]: string;
}

// Updates account cache in response to unknown action with response.account.
const reducer: Reducer<LocalizationState | undefined, LocalizationAction> = (
  state: LocalizationState | undefined,
  action: LocalizationAction
) => {
  if (action.type === ActionTypes.LocalizationSuccess && action.response) {
    return merge({}, state, action.response) as LocalizationState;
  }

  if (
    action.type === ActionTypes.LoadCacheLocalizationSuccess &&
    action.response
  ) {
    return merge({}, state, action.response);
  }

  if (state === undefined) {
    return {} as LocalizationState;
  }

  return state;
};

export default reducer;
