import * as React from "react";
import { CircularProgress, withTheme, WithTheme } from "@material-ui/core";

const LoadingCircle = (props: WithTheme): React.ReactElement => {
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        size={200}
        style={{ color: props.theme.palette.primary.main }}
      />
    </div>
  );
};

export default withTheme(LoadingCircle);
