import { CallApiAction } from "../shared/middleware/api";
import { ActionTypes } from "../enums/ActionTypes";
import { Action } from "redux";
import { AppThunkAction } from "../";
import { Endpoints } from "../enums/Endpoints";

export interface AccountAction extends CallApiAction {
  type:
    | typeof ActionTypes.AccountRequest
    | typeof ActionTypes.AccountSuccess
    | typeof ActionTypes.AccountFailure
    | typeof ActionTypes.AccountUpdate
    | typeof ActionTypes.AmrRequest
    | typeof ActionTypes.AmrSuccess
    | typeof ActionTypes.AmrFailure
    | typeof ActionTypes.ChangePasswordRequest
    | typeof ActionTypes.ChangePasswordSuccess
    | typeof ActionTypes.ChangePasswordFailure
    | typeof ActionTypes.ChangeEmailRequest
    | typeof ActionTypes.ChangeEmailSuccess
    | typeof ActionTypes.ChangeEmailFailure
    | typeof ActionTypes.ChangeEmailVerificationRequest
    | typeof ActionTypes.ChangeEmailVerificationFailure
    | typeof ActionTypes.ChangeEmailVerificationSuccess
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  payload?: {
    item?: Record<string, unknown>;
    prevItem?: Record<string, unknown>;
  };
}

export interface PoliciesAction extends CallApiAction {
  type:
    | typeof ActionTypes.PoliciesRequest
    | typeof ActionTypes.PoliciesSuccess
    | typeof ActionTypes.PoliciesFailure
    | typeof ActionTypes.UserUpdateSuccess
    | typeof ActionTypes.UserUpdateRequest
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  response?: Record<string, unknown>;
}

// Fetches Amr from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchAmr = (): AccountAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.AmrRequest,
      ActionTypes.AmrSuccess,
      ActionTypes.AmrFailure,
    ],
    endpoint: `${Endpoints.Account}/Amr`,
    useMy: true,
  },
  type: ActionTypes.CallAPI,
});

export const loadAmr = (): AppThunkAction<AccountAction> => (dispatch) => {
  return dispatch(fetchAmr());
};

// Fetches account info from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchAccount = (): AccountAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.AccountRequest,
      ActionTypes.AccountSuccess,
      ActionTypes.AccountFailure,
    ],
    endpoint: Endpoints.Account,
    useMy: true,
  },
  type: ActionTypes.CallAPI,
});

export const loadAccount = (): AppThunkAction<AccountAction> => (dispatch) => {
  return dispatch(fetchAccount());
};

// Updates account info with a patch call to API
// In progress
const patchAccount = (payload: Record<string, unknown>): AccountAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.AccountUpdate,
      ActionTypes.AccountSuccess,
      ActionTypes.AccountFailure,
    ],
    endpoint: Endpoints.Account,
    useMy: true,
    options: {
      method: "PATCH",
      body: JSON.stringify(payload),
    },
  },
  payload: {
    item: payload,
  },
  type: ActionTypes.CallAPI,
});

export const updateAccount =
  (payload: Record<string, unknown>): AppThunkAction<Action> =>
    (dispatch) => {
      return dispatch(patchAccount(payload));
    };

// Changes password
const patchChangePassword = (
  payload: Record<string, unknown>
): AccountAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.ChangePasswordRequest,
      ActionTypes.ChangePasswordSuccess,
      ActionTypes.ChangePasswordFailure,
    ],
    endpoint: Endpoints.ChangePassword,
    useMy: true,
    options: {
      method: "PATCH",
      body: JSON.stringify(payload),
    },
  },
  payload: {
    item: payload,
  },
  type: ActionTypes.CallAPI,
});

export const changePassword =
  (payload: Record<string, unknown>): AppThunkAction<AccountAction> =>
    (dispatch) => {
      return dispatch(patchChangePassword(payload));
    };

// Changes email address
const postChangeEmail = (payload: Record<string, unknown>): AccountAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.ChangeEmailRequest,
      ActionTypes.ChangeEmailSuccess,
      ActionTypes.ChangeEmailFailure,
    ],
    endpoint: Endpoints.ChangeEmail,
    useMy: true,
    options: {
      method: "PATCH",
      body: JSON.stringify(payload),
    },
  },
  payload: {
    item: payload,
  },
  type: ActionTypes.CallAPI,
});

export const changeEmail =
  (payload: Record<string, unknown>): AppThunkAction<AccountAction> =>
    (dispatch) => {
      return dispatch(postChangeEmail(payload));
    };

// Changes email address
const postChangeEmailVerification = (payload: {
  token: string;
}): AccountAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.ChangeEmailVerificationRequest,
      ActionTypes.ChangeEmailVerificationSuccess,
      ActionTypes.ChangeEmailVerificationFailure,
    ],
    endpoint: `${Endpoints.Account}/ChangeEmailVerification`,
    useBase: true,
    options: {
      method: "PATCH",
      body: JSON.stringify(payload),
    },
  },
  payload: {
    item: payload,
  },
  type: ActionTypes.CallAPI,
});

export const changeEmailVerification =
  (payload: { token: string }): AppThunkAction<AccountAction> =>
    (dispatch) => {
      return dispatch(postChangeEmailVerification(payload));
    };

// Fetches policies from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchPolicies = (): PoliciesAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.PoliciesRequest,
      ActionTypes.PoliciesSuccess,
      ActionTypes.PoliciesFailure,
    ],
    endpoint: Endpoints.Policies,
    useMy: true,
  },
  type: ActionTypes.CallAPI,
});

export const loadPolicies =
  (): AppThunkAction<PoliciesAction> => (dispatch) => {
    return dispatch(fetchPolicies());
  };

export interface AccountActionCreators {
  loadAmr: typeof loadAmr;
  loadAccount: typeof loadAccount;
  patchAccount: typeof patchAccount;
  updateAccount: typeof updateAccount;
  changePassword: typeof changePassword;
  changeEmail: typeof changeEmail;
  loadPolicies: typeof loadPolicies;
}
