/** @format */
// React/Redux Library Imports
import QRCode from "qrcode.react";
import * as React from "react";
import { ReactCookieProps, withCookies } from "react-cookie";
import { connect } from "react-redux";
import { RouterProps } from "react-router";
import { compose } from "redux";

// Material-UI Imports
import {
  Box,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
  WithStyles,
  withStyles,
  FormControlLabel,
  Switch,
  Snackbar,
} from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import EmailIcon from "@mui/icons-material/Email";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import { Alert } from "@mui/material";

// Redux Store Imports
import { ApplicationState, DispatchThunkAction } from "../../state";
import actions from "../../state/actions";
import { AppSettingsState } from "../../state/appsettings";
import { AuthenticationState } from "../../state/authentication";
import { ActionTypes } from "../../state/enums/ActionTypes";
import { TwoFactorState } from "../../state/twofactor";

// Other Imports
import { makeLoadingSelector } from "../../state/loading/selectors";
import {
  Login2FaModel,
  MultiFactorAuthentication,
  TwoFactorModel,
  TwoFactorModes,
} from "../../types";
import { TwoFactorAction } from "state/twofactor/actions";
import OtpInput from "react-otp-input";
// Generating css styles for the component
const styles = (theme: Theme) =>
  createStyles({
    submitBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      fontSize:'14px',
      height:'35px'
    },
    modeButton: {
      height: "unset",
      margin: `16px 0px 16px`,
      padding: `6px 16px`,
      fontSize: '14px'
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    hideRemember: {
      display: "none",
    },
    securityRedirectButton: {
      marginTop: theme.spacing(1),
    },
  });

// Deconstructed actions
const {
  getAuthenticationStatus,
  toggleSharedKey,
  clearTwoFactor,
  continueToApp,
  login2FaModel,
  confirmLogin2FaCode,
  getTwoFactorModel,
  enableTwoFactorModelStep1,
  enableTwoFactorModelStep2,
  confirmByApp,
  confirmByEmail,
  deleteRememberMeCookies,
  setUpTwoFactor,
  setVerificationFormVisibility,
} = actions;

interface DispatchProps {
  getAuthenticationStatus: DispatchThunkAction<typeof getAuthenticationStatus>;
  toggleSharedKeyConnect: DispatchThunkAction<typeof toggleSharedKey>;
  clearTwoFactorConnect: DispatchThunkAction<typeof clearTwoFactor>;
  continueToAppConnect: DispatchThunkAction<typeof continueToApp>;
  login2FaModelConnect: DispatchThunkAction<typeof login2FaModel>;
  confirmLogin2FaCodeConnect: DispatchThunkAction<typeof confirmLogin2FaCode>;
  getTwoFactorModelConnect: DispatchThunkAction<typeof getTwoFactorModel>;
  enableTwoFactorModelStep1Connect: DispatchThunkAction<
    typeof enableTwoFactorModelStep1
  >;
  enableTwoFactorModelStep2Connect: DispatchThunkAction<
    typeof enableTwoFactorModelStep2
  >;
  confirmByAppConnect: DispatchThunkAction<typeof confirmByApp>;
  confirmByEmailConnect: DispatchThunkAction<typeof confirmByEmail>;

  deleteRememberMeCookiesConnect: DispatchThunkAction<
    typeof deleteRememberMeCookies
  >;
  setUpTwoFactorConnect: (enable: boolean) => TwoFactorAction;
  setVerificationFormVisibilityConnect: (enable: boolean) => TwoFactorAction;
}

// StateProps
interface StateProps {
  appsettings?: AppSettingsState;
  authentication?: AuthenticationState;
  twofactor?: TwoFactorState;
  loading?: boolean;
}

interface PublicProps {
  disbaleLayout?: boolean;
}

interface EditTwoFactorSetupState {
  editCode: string;
  editRemember: boolean;
  showVerificationForm: boolean;
  hasAuthenticator: boolean;
  editLogin2FaModel?: Login2FaModel;
  editTwoFactorModel?: TwoFactorModel;
  requiresSetup?: boolean;
  showSetup?: boolean;
  showSharedKey?: boolean;
  showRecoveryCodes?: boolean;
  verificationCompleted?: boolean;
  errorMessage?: string;
  showError?: boolean;
  showVerificationCode?: boolean;
  verificationCodeError?: string;
}

type TwoFactorSetupProps = PublicProps &
StateProps &
DispatchProps &
ReactCookieProps &
RouterProps &
WithStyles<typeof styles>;

export const twofactorCodeExpiredMessage =
  "Second factor authentication for user has expired.";

class TwoFactorSetup extends React.PureComponent<
  TwoFactorSetupProps,
  EditTwoFactorSetupState
> {
  constructor(props: TwoFactorSetupProps) {
    super(props);
    this.state = {
      editCode: "",
      editRemember: false,
      hasAuthenticator: false,
      showVerificationForm: true,
      requiresSetup: false,
      showSetup: false,
      showSharedKey: false,
      showRecoveryCodes: false,
      verificationCompleted: false,
      errorMessage: "",
      showError: false,
      showVerificationCode: props.twofactor?.showVerificationCode,
      verificationCodeError: props.twofactor?.verificationCodeError,
    };
  }

  // Component Lifecycle Hooks
  static getDerivedStateFromProps(nextProps: TwoFactorSetupProps) {
    // invoked right before calling the render method, both on the initial mount and on subsequent updates
    // return an object to update the state, or null to update nothing.

    const { twofactor } = nextProps;
    if (twofactor) {
      const {
        TwoFactorModel,
        Login2FaModel,
        showVerificationForm,
        requiresSetup,
        showSetup,
        showSharedKey,
        showRecoveryCodes,
        verificationCompleted,
        errorMessage,
        showError,
      } = twofactor;
      return {
        editTwoFactorModel: TwoFactorModel,
        editLogin2FaModel: Login2FaModel,
        hasAuthenticator:
          Login2FaModel?.Authenticator || TwoFactorModel?.HasAuthenticator,
        showVerificationForm,
        requiresSetup,
        showSetup,
        showSharedKey,
        showRecoveryCodes,
        verificationCompleted,
        errorMessage,
        showError,
      };
    } else {
      return {};
    }
  }

  getModel = () => {
    const { getTwoFactorModelConnect } = this.props;

    this.setState({
      requiresSetup: true,
      showSetup: true,
      showVerificationForm: false,
    });
    void getTwoFactorModelConnect();
  };

  componentDidMount() {
    this.getModel();
  }

  // Validation Logic
  validateForm() {
    // Ensure that the code field has been filled
    const { editCode } = this.state;
    return editCode.length === 6;
  }

  // Actions Helpers
  setTwoFactorMode = (type: TwoFactorModes) => {
    const { enableTwoFactorModelStep1Connect } = this.props;
    type == TwoFactorModes.Authenticator
      ? void enableTwoFactorModelStep1Connect({
        Authenticator: "Authenticator",
      })
      : void enableTwoFactorModelStep1Connect({ Authenticator: "Email" });
  };

  handleCodeChange = (
    eOrCode: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string
  ) => {
    let code = "";
    if (typeof eOrCode === "string") {
      code = eOrCode;
    } else {
      code = eOrCode.target.value;
    }

    this.setState({
      editCode: code,
      showVerificationForm: false,
      verificationCodeError: "",
    });
  };

  handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ editRemember: event.target.checked });
  };

  handleSharedKeyToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { showSharedKey } = this.state;
    const { toggleSharedKeyConnect } = this.props;
    void toggleSharedKeyConnect(!showSharedKey);
  };

  handleContinueToApp = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { continueToAppConnect } = this.props;
    void continueToAppConnect();
  };

  handleTwoFactorByApp = () => {
    this.setTwoFactorMode(TwoFactorModes.Authenticator);
  };

  handleTwoFactorByEmail = () => {
    this.setTwoFactorMode(TwoFactorModes.Email);
  };

  handleCodeSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const {
      editCode,
      editRemember,
      requiresSetup,
      editLogin2FaModel,
      editTwoFactorModel,
    } = this.state;
    const {
      confirmLogin2FaCodeConnect,
      enableTwoFactorModelStep2Connect,
      deleteRememberMeCookiesConnect,
    } = this.props;
    if (requiresSetup) {
      // Get the values from the input form and assign them to the setup model for payload
      const payload = Object.assign({}, editTwoFactorModel, {
        GeneratedCode: editCode,
        // Remembered: editRemember,
      }) as TwoFactorModel;
      void enableTwoFactorModelStep2Connect(payload).then((res) => {
        const resp = res as { type: string; error: string };
        if (resp.type === ActionTypes.TwoFactorConfirmFailure) {
          this.setState({
            showVerificationCode: true,
            verificationCodeError: resp.error,
          });
        } else {
          void deleteRememberMeCookiesConnect().then((res) => {
            console.debug(res);
          });
        }
      });
    } else {
      const payload = Object.assign({}, editLogin2FaModel, {
        Code: editCode,
        Remember: editRemember,
        Authenticator: "Authenticator",
      }) as Login2FaModel;
      void confirmLogin2FaCodeConnect(payload);
    }
  };

  returnToModeSelect = () => {
    this.setState({
      showSetup: true,
      showVerificationForm: false,
      editCode: "",
      showVerificationCode: false,
      verificationCodeError: "",
    });
    this.getModel();
  };

  closeError = () =>
    this.setState({ showVerificationCode: false, verificationCodeError: "" });

  redirectToSecurity = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const {
      setUpTwoFactorConnect,
      setVerificationFormVisibilityConnect,
      getTwoFactorModelConnect,
    } = this.props;

    if (
      this.props.twofactor?.verificationCodeError == twofactorCodeExpiredMessage
    ) {
      void setUpTwoFactorConnect(false);
    } else {
      this.setState({
        editCode: "",
        editRemember: false,
        hasAuthenticator: false,
        showVerificationForm: true,
        requiresSetup: false,
        showSetup: false,
        showSharedKey: false,
        showRecoveryCodes: false,
        verificationCompleted: false,
        errorMessage: "",
        showError: false,
        showVerificationCode: false,
        verificationCodeError: "",
      });
    }

    void setVerificationFormVisibilityConnect(false);
    void getTwoFactorModelConnect();
  };

  // Renders
  renderVerificationForm(): React.ReactNode {
    const { classes, loading } = this.props;
    const {
      editCode,
      editRemember,
      editLogin2FaModel,
      editTwoFactorModel,
      showSetup,
      showVerificationCode,
      verificationCodeError,
    } = this.state;

    const usingApp =
      (!showSetup &&
        editLogin2FaModel?.Authenticator === TwoFactorModes.Authenticator) ||
      (showSetup &&
        editTwoFactorModel?.Authenticator === TwoFactorModes.Authenticator);

    return (
      <React.Fragment>
        <form
          className={classes.form}
          id="verificationForm"
          noValidate
          onSubmit={this.handleCodeSubmit}
        >
          <Typography id="testMe">Enter security code</Typography>
          <Typography
            id="twoFactorInstructions"
            paragraph={true}
            variant="subtitle2"
          >
            {usingApp
              ? "Please enter the security code from your Authenticator app (Microsoft or Google authenticator, Authy, etc).The code is valid for 30 seconds."
              : "Please enter the security code sent to your email address. The code is valid for 5 minutes."}
          </Typography>
          {usingApp && (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="securityCode"
              label="Security code"
              name="securityCode"
              value={editCode}
              autoComplete="off"
              onChange={(e) => {
                this.handleCodeChange(e);
              }}
              autoFocus
              InputProps={{ inputProps: { minLength: 6, maxLength: 6 } }}
            />
          )}
          {!usingApp && (
            <OtpInput
              value={editCode}
              onChange={(code) => {
                this.handleCodeChange(code);
              }}
              numInputs={6}
              shouldAutoFocus={true}
              inputStyle={{
                border: "1px solid black",
                borderRadius: "2px",
                width: "3rem",
                height: "3rem",
                fontSize: "20px",
                color: "#000",
                fontWeight: "400",
                caretColor: "blue",
                marginRight: window.screen.width <= 320 ? "2%" : "6%",
                WebkitAppearance: "none",
                MozAppearance: "textfield",
              }}
              inputType="number"
              renderInput={(props) => <input id="otpInput" {...props} />}
            />
          )}
          <FormControlLabel
            className={classes.hideRemember}
            control={
              <Switch
                checked={editRemember}
                onChange={this.handleSwitchChange}
                id="remember"
                name="remember"
                color="primary"
              />
            }
            label="Remember this device"
          />
          <Button
            id="verifyCode"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!this.validateForm() || loading}
            className={classes.submit}
          >
            {loading ? "Processing..." : "Verify"}
          </Button>
          {usingApp && !showSetup && (
            <Box>
              <Link variant="body2">use recovery code</Link>
            </Box>
          )}
          {showSetup && (
            <Button
              fullWidth
              id="btn-use-other-method"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.returnToModeSelect}
            >
              Use other method
            </Button>
          )}
          {showVerificationCode && (
            <Snackbar
              open={showVerificationCode}
              autoHideDuration={6000}
              onClose={this.closeError}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert onClose={this.closeError} severity="error">
                <Box display="flex" flexDirection="column">
                  {verificationCodeError}
                  <Button
                    href={`${process.env.PUBLIC_URL}/account/security`}
                    color="primary"
                    className={classes.securityRedirectButton}
                    id="security-redirection"
                    onClick={this.redirectToSecurity}
                  >
                    {verificationCodeError == twofactorCodeExpiredMessage
                      ? "Click here to security"
                      : "Try again"}
                  </Button>
                </Box>
              </Alert>
            </Snackbar>
          )}
        </form>
      </React.Fragment>
    );
  }

  renderRecoveryCodes(recoveryCodes: string[]): React.ReactNode {
    return (
      <React.Fragment>
        <Typography variant="subtitle1" paragraph display="block">
          Please save these recovery codes somewhere safe. You will not be able
          to access them again. You can use these codes to recover your account
          in case you lose access to your device with the Authenticator app.
        </Typography>
        <Typography variant="overline" id="recovery-codes" display="block">
          {recoveryCodes.join(", ")}
        </Typography>
        <Box m={1} textAlign="center">
          <Button
            id="continueToApp"
            variant="contained"
            color="primary"
            arial-label="continue to app"
            onClick={this.handleContinueToApp}
          >
            Continue
          </Button>
        </Box>
      </React.Fragment>
    );
  }

  renderErrorMessage(
    showError?: boolean,
    errorMessage?: string
  ): React.ReactNode {
    // Render the error message from the server
    return showError == true ? (
      <Typography variant="subtitle1" align="center" color="error">
        {errorMessage}
      </Typography>
    ) : (
      <></>
    );
  }

  renderTwoFactorModes(): React.ReactNode {
    const { appsettings, classes } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12}>
            {appsettings?.MultiFactorAuthentication ==
              MultiFactorAuthentication.Required && (
              <Box mt={2}>
                <Typography
                  id="requirement-message"
                  variant="body1"
                  display="block"
                >
                  Please set up Two-factor authentication using one of the
                  options below.
                </Typography>
              </Box>
            )}
            <Box mt={2}>
              <Typography variant="subtitle2" display="block">
                Use an app on your mobile device like Microsoft or Google
                authenticator, Authy, etc. (Recommended)
              </Typography>
              <Button
                id="enableByApp"
                variant="contained"
                color="primary"
                arial-label="two factor by app"
                onClick={this.handleTwoFactorByApp}
                startIcon={<SmartphoneIcon />}
                className={classes.modeButton}
              >
                Authenticator app
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography variant="body1">Or</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography variant="subtitle2" display="block">
                Send a security code to your email address
              </Typography>
              <Button
                id="enableByEmail"
                variant="contained"
                color="primary"
                arial-label="two factor by email"
                onClick={this.handleTwoFactorByEmail}
                startIcon={<EmailIcon />}
                className={classes.modeButton}
              >
                Email
              </Button>
            </Box>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  renderQRCode(value?: string): React.ReactNode {
    const { showSharedKey, editTwoFactorModel, showSetup, editLogin2FaModel } =
      this.state;
    const usingApp =
      (!showSetup &&
        editLogin2FaModel?.Authenticator === TwoFactorModes.Authenticator) ||
      (showSetup &&
        editTwoFactorModel?.Authenticator === TwoFactorModes.Authenticator);
    return value && usingApp ? (
      <React.Fragment>
        <Box m={1} textAlign="center">
          <Typography variant="body1" paragraph>
            Scan the QR code in the authenticator app on your mobile device.
          </Typography>
          <QRCode id="qr-code" value={value} />
        </Box>
        <Box m={1} textAlign="center">
          <Typography variant="caption" component="p" paragraph>
            Unable to scan the QR Code above? Enter the key manually in your
            authenticator app.
          </Typography>
          <Button
            id="showSharedKey"
            onClick={this.handleSharedKeyToggle}
            color="secondary"
            style={{ textTransform: "none"}}
          >
            {showSharedKey ? "Hide" : "Show"} key
          </Button>
          {showSharedKey && (
            <Typography variant="overline" component="p" paragraph>
              {editTwoFactorModel?.SharedKey}
            </Typography>
          )}
        </Box>
      </React.Fragment>
    ) : (
      <></>
    );
  }

  public render() {
    const {
      showVerificationForm,
      showSetup,
      showRecoveryCodes,
      errorMessage,
      showError,
      editTwoFactorModel,
    } = this.state;

    return (
      <React.Fragment>
        {showSetup &&
          !showVerificationForm &&
          !showRecoveryCodes &&
          this.renderTwoFactorModes()}
        {!showRecoveryCodes &&
          showVerificationForm &&
          this.renderQRCode(editTwoFactorModel?.SharedKeyUri)}
        {showRecoveryCodes &&
          editTwoFactorModel?.RecoveryCodes &&
          this.renderRecoveryCodes(editTwoFactorModel?.RecoveryCodes)}
        {showVerificationForm && this.renderVerificationForm()}
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    void this.props.clearTwoFactorConnect();
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  appsettings: state.appsettings,
  authentication: state.authentication,
  twofactor: state.twofactor,
  loading: makeLoadingSelector([
    ActionTypes.TwoFactorAppConfirmRequest,
    ActionTypes.TwoFactorConfirmRequest,
    ActionTypes.TwoFactorEnableRequest,
    ActionTypes.TwoFactorModelRequest,
  ])(state),
});

const mapDispatchToProps = {
  getAuthenticationStatus: getAuthenticationStatus,
  toggleSharedKeyConnect: toggleSharedKey,
  clearTwoFactorConnect: clearTwoFactor,
  continueToAppConnect: continueToApp,
  login2FaModelConnect: login2FaModel,
  confirmLogin2FaCodeConnect: confirmLogin2FaCode,
  getTwoFactorModelConnect: getTwoFactorModel,
  enableTwoFactorModelStep1Connect: enableTwoFactorModelStep1,
  enableTwoFactorModelStep2Connect: enableTwoFactorModelStep2,
  confirmByAppConnect: confirmByApp,
  confirmByEmailConnect: confirmByEmail,
  deleteRememberMeCookiesConnect: deleteRememberMeCookies,
  setUpTwoFactorConnect: setUpTwoFactor,
  setVerificationFormVisibilityConnect: setVerificationFormVisibility,
};

export default compose(
  withCookies,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(TwoFactorSetup) as React.ComponentType;
