import * as SignalR from "@microsoft/signalr";

export type HubListener = (n: unknown) => void;

export interface HubOptions {
  hubUrl: string;
  authCookie: string;
  onNotify: HubListener;
  onChange: HubListener;
}

let defaultConnection = {} as SignalR.HubConnection;

const bindConnectionMessage = (
  connection: SignalR.HubConnection,
  onNotify: HubListener,
  onChange: HubListener
) => {
  connection.on("Notify", (n) => {
    onNotify(n);
  });
  connection.on("Change", (n) => {
    onChange(n);
  });
};

export const unsubscribeToChanges = (itemId?: string): void => {
  if (defaultConnection !== null)
    if (defaultConnection.state === SignalR.HubConnectionState.Connected) {
      void defaultConnection.invoke("Unsubscribe", itemId);
    }    
};

export const subscribeToChanges = (itemId?: string): void => {
  if (defaultConnection !== null)
    if (defaultConnection.state === SignalR.HubConnectionState.Connected) {
      void defaultConnection.invoke("Subscribe", itemId);
    }
};

const createConnection = (_url: string, authCookie: string) => {
  const c = new SignalR.HubConnectionBuilder()
    .withUrl(_url, {
      accessTokenFactory: () => authCookie
    })
    .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 60000])
    .configureLogging(SignalR.LogLevel.Warning)
    .build();

  return c;
};

const startHub = (connection: SignalR.HubConnection) => {
  // connection.start({ withCredentials: false }).then(
  connection.start().then(
    () => {
      console.info(`ConnectionId = ${connection.connectionId as string}`);
    },
    (error: Error) => {
      console.error("SignalR Log: ", error);
    }
  );
};

const reconnectHub = (connection: SignalR.HubConnection) => {
  connection.onreconnecting((error?: Error) => {
    if (error) {
      console.warn(
        `Connection lost due to error "${error.message}". Reconnecting.`,
        error
      );
    }
  });
  connection.onreconnected((connectionId) => {
    console.debug(
      `Connection reestablished. Connected with connectionId: "${
        connectionId as string
      }"`
    );
  });
};

export const createHub = (options: HubOptions): void => {
  if (defaultConnection.state !== SignalR.HubConnectionState.Connected) {
    if (
      defaultConnection !== null &&
      defaultConnection.state === SignalR.HubConnectionState.Connecting
    ) {
      return;
    }
    const connection = createConnection(options.hubUrl, options.authCookie);
    defaultConnection = connection;
    bindConnectionMessage(connection, options.onNotify, options.onChange);
    startHub(connection);
    reconnectHub(connection);
  }
};
