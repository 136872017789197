import merge from "lodash/merge";
import { Reducer } from "redux";
import {
  NotificationCountAction,
  NotificationDeleteAction,
  NotificationReadAction,
  NotificationsAction,
  NotificationsPageAction,
} from "./actions";
import { ActionTypes } from "../enums/ActionTypes";
import { UserNotification } from "../../abstractions/userNotification/UserNotification";
import { NotificationCounts } from "../../abstractions/userNotification/NotificationCounts";
import { forEach, cloneDeep } from "lodash";

const PAGE_SIZE = 10;

export interface NotificationsState {
  notifications: NotificationsList;
  count: NotificationCounts;
  page: number;
  pageSize: number;
  sort?: {
    by: string;
    direction: string;
  };
}

export interface NotificationsList {
  [Id: string]: UserNotification;
}

// Updates dashboards store in response to successful dashboards request
const reducer: Reducer = (
  state: NotificationsState | undefined,
  action:
    | NotificationsAction
    | NotificationCountAction
    | NotificationDeleteAction
    | NotificationReadAction
    | NotificationsPageAction
) => {
  if (state === undefined) {
    return {
      notifications: {},
      page: 0,
      pageSize: PAGE_SIZE,
    } as NotificationsState;
  }

  switch (action.type) {
    case ActionTypes.NotificationsSuccess || ActionTypes.NotificationReadSuccess: {
      if (action.response) {
        if (!action.response.Id) {
          const stateCopy = cloneDeep(state);
          // This would change to replacement
          // stateCopy.notifications = {} as NotificationsList;
          const notificationsArray =
            action.response as unknown as UserNotification[];
          const notifications = {} as NotificationsList;
          forEach(notificationsArray, (notification) => {
            notifications[notification.Id] = notification;
          });
          return merge({}, stateCopy, { notifications }) as NotificationsState;
        } else {
          const notification = action.response as unknown as UserNotification;
          return merge({}, state, {
            notifications: { [notification.Id]: notification },
          });
        }
      }
      break;
    }

    case ActionTypes.NotificationCountSuccess: {
      if (action.response) {
        const count = {} as Record<string, unknown>;
        let countAll = 0;
        const countArray = action.response as Record<
          string,
          { Value: number; Name: string }
        >;
        forEach(countArray, (type) => {
          count[type.Name] = merge(
            {},
            { NotificationType: type.Name, Count: type.Value }
          );
          countAll += type.Value;
        });
        count["ALL"] = { NotificationType: "ALL", Count: countAll };
        return merge({}, state, { count });
      }
      break;
    }

    case ActionTypes.NotificationDeleteRequest: {
      if (action.payload && action.payload.item) {
        if (action.payload.item.Id === "All") {
          const stateCopy = cloneDeep(state);
          stateCopy.notifications = {};
          return merge({}, stateCopy);
        } else {
          const stateCopy = cloneDeep(state);
          delete stateCopy.notifications[action.payload.item.Id as string];
          return merge({}, stateCopy);
        }
      }
      break;
    }

    case ActionTypes.NotificationDeleteFailure: {
      if (action.payload && action.payload.item && action.payload.prevItem) {
        if (action.payload.item.Id === "All") {
          return merge({}, state, {
            notifications: action.payload.prevItem,
          });
        } else {
          return merge({}, state, {
            notifications: {
              [action.payload.prevItem.Id as string]: action.payload.prevItem,
            },
          });
        }
      }
      break;
    }

    case ActionTypes.NotificationReadRequest: {
      if (action.payload && action.payload.item) {
        if (action.payload.item.Id === "All") {
          const stateCopy = cloneDeep(state);
          forEach(stateCopy.notifications, (note) => {
            note.Delivered = true;
          });
          return merge({}, stateCopy);
        } else {
          return merge({}, state, {
            notifications: {
              [action.payload.item.Id as string]: { Delivered: true },
            },
          });
        }
      }
      break;
    }

    case ActionTypes.NotificationReadFailure: {
      if (action.payload && action.payload.item) {
        if (action.payload.item.Id === "All" && action.payload.prevItem) {
          return merge({}, state, { notifications: action.payload.prevItem });
        } else {
          return merge({}, state, {
            notifications: {
              [action.payload.item.Id as string]: { Delivered: false },
            },
          });
        }
      }
      break;
    }

    case ActionTypes.NotificationsSetPage: {
      const notifications = {} as Record<string, unknown>;
      if (action.page !== undefined) {
        notifications["page"] = action.page;
      }
      if (action.pageSize !== undefined) {
        notifications["pageSize"] = action.pageSize;
      }
      if (action.sort) {
        notifications["sort"] = action.sort;
      }
      return merge({}, state, notifications);
    }
  }

  return state;
};

export default reducer;
