// React/Redux Library Imports
import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// Redux Store Imports
import { ApplicationState } from "../state";

// Component Imports
import AuthLoader from "../components/shared/AuthLoader"

// Type Imports
import { AuthStatus } from "../types";


interface PublicProps {
  children?: React.ReactNode,
}

interface StateProps {
  authenticationStatus?: AuthStatus
}

type AuthorizedRouteProps = PublicProps & StateProps;

const AuthorizedRoute: React.FunctionComponent<AuthorizedRouteProps> = ({
  authenticationStatus,
  ...otherProps
}: AuthorizedRouteProps) => {
  const { children } = otherProps;
  switch (authenticationStatus) {
    case AuthStatus.Authorized:
      return (<React.Fragment>{children}</React.Fragment>);
    case AuthStatus.LockedOut:
    case AuthStatus.NotAllowed:
    case AuthStatus.Unauthorized:
      return (<Redirect to={`${process.env.PUBLIC_URL}/login`} />);
    case AuthStatus.RequiresTwoFactor:
      return (<Redirect to={`${process.env.PUBLIC_URL}/twofactor`} />);
    default:
      return (<React.Fragment><AuthLoader /></React.Fragment>);
  }
};

const mapStateToProps = (state: ApplicationState) => ({
  authenticationStatus: state.authentication?.authenticationStatus
});

export default connect(mapStateToProps)(AuthorizedRoute) as React.ComponentType<PublicProps>;