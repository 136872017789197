import { CallApiAction } from "../shared/middleware/api";
import { ActionTypes } from "../enums/ActionTypes";
import { Endpoints } from "../enums/Endpoints";
import { Action } from "redux";
import { AppThunkAction } from "..";
import { getNotificationQuery } from "./selectors";
import { UserNotification } from "../../abstractions/userNotification/UserNotification";

export interface NotificationsAction extends CallApiAction {
  type:
    | typeof ActionTypes.NotificationsRequest
    | typeof ActionTypes.NotificationsSuccess
    | typeof ActionTypes.NotificationsFailure
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  response?: Record<string, unknown>;
  payload?: {
    item?: Record<string, unknown>;
    prevItem?: Record<string, unknown>;
  };
}

export interface NotificationCountAction extends CallApiAction {
  type:
    | typeof ActionTypes.NotificationCountRequest
    | typeof ActionTypes.NotificationCountSuccess
    | typeof ActionTypes.NotificationCountFailure
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  response?: Record<string, unknown>;
}

export interface NotificationDeleteAction extends CallApiAction {
  type:
    | typeof ActionTypes.NotificationDeleteRequest
    | typeof ActionTypes.NotificationDeleteSuccess
    | typeof ActionTypes.NotificationDeleteFailure
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  response?: Record<string, unknown>;
}

export interface NotificationReadAction extends CallApiAction {
  type:
    | typeof ActionTypes.NotificationReadRequest
    | typeof ActionTypes.NotificationReadSuccess
    | typeof ActionTypes.NotificationReadFailure
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  response?: Record<string, unknown>;
}

export interface NotificationUpdateAction extends CallApiAction {
  type:
    | typeof ActionTypes.NotificationUpdateRequest
    | typeof ActionTypes.NotificationUpdateSuccess
    | typeof ActionTypes.NotificationUpdateFailure
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  response?: Record<string, unknown>;
  payload?: {
    item: Record<string, unknown>;
    prevItem: Record<string, unknown>;
  };
}

export interface NotificationsPageAction extends Action {
  type: typeof ActionTypes.NotificationsSetPage | typeof ActionTypes.None;
  page?: number;
  pageSize?: number;
  sort?: {
    by: string;
    direction: string;
  };
}

// Changes page or pageSize for notifications
const setNotificationsPage = (
  page?: number,
  pageSize?: number,
  sort?: {
    by: string;
    direction: string;
  }
): NotificationsPageAction => ({
  type: ActionTypes.NotificationsSetPage,
  page: page,
  pageSize: pageSize,
  sort: sort,
});

export const changeNotificationsPage =
  (
    page?: number,
    pageSize?: number,
    sort?: {
      by: string;
      direction: string;
    }
  ): AppThunkAction<Action> =>
    (dispatch) => {
      return dispatch(setNotificationsPage(page, pageSize, sort));
    };

// Fetches Notification Counts from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchNotificationCount = (): NotificationCountAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.NotificationCountRequest,
      ActionTypes.NotificationCountSuccess,
      ActionTypes.NotificationCountFailure,
    ],
    endpoint: Endpoints.NotificationCountByType,
    useMy: true,
  },
  [ActionTypes.CallODATA]: {
    query: "?filter=(delivered eq false)",
  },
  type: ActionTypes.CallAPI,
});

export const loadNotificationCount =
  (): AppThunkAction<Action> => (dispatch) => {
    return dispatch(fetchNotificationCount());
  };

// Fetches User Notifications from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchUserNotifications = (
  query: string,
  page = 0,
  pageSize = 10,
  sort: Record<string, unknown> = { by: "default", direction: "desc" }
): NotificationsAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.NotificationsRequest,
      ActionTypes.NotificationsSuccess,
      ActionTypes.NotificationsFailure,
    ],
    endpoint: Endpoints.Notifications,
    useMy: true,
  },
  [ActionTypes.CallODATA]: {
    query: query,
  },
  payload: {
    item: {
      page,
      pageSize,
      sort,
    },
  },
  type: ActionTypes.CallAPI,
});

export const loadUserNotifications =
  (
    notificationTypes?: Record<string, unknown>[],
    page?: number,
    pageSize?: number,
    selectedFilter?: Record<string, unknown>
  ): AppThunkAction<Action> =>
    (dispatch, getState) => {
      const loadPage = page != undefined ? page : getState().notifications?.page;
      const loadPageSize = pageSize
        ? pageSize
        : getState().notifications?.pageSize;
      return dispatch(
        fetchUserNotifications(
          getNotificationQuery(
            loadPage,
            loadPageSize,
            selectedFilter,
            notificationTypes
          ),
          loadPage,
          loadPageSize,
          undefined
        )
      );
    };

// Patch an existing Notification
const patchNotification = (
  payload: Record<string, unknown>,
  prevItem: Record<string, unknown>
): NotificationUpdateAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.NotificationUpdateRequest,
      ActionTypes.NotificationUpdateSuccess,
      ActionTypes.NotificationUpdateFailure,
    ],
    endpoint: Endpoints.Notifications,
    useMy: true,
    options: {
      method: "PATCH",
      body: JSON.stringify(payload),
    },
  },
  [ActionTypes.CallODATA]: {
    query: `/${payload.Id as string}`,
  },
  payload: {
    item: payload,
    prevItem: prevItem,
  },
  type: ActionTypes.CallAPI,
});

export const updateNotification =
  (payload: Record<string, unknown>): AppThunkAction<Action> =>
    (dispatch, getState) => {
      const { notifications } = getState();
      let prevItem = {} as Record<string, unknown>;
      if (notifications && notifications.notifications) {
        prevItem = notifications.notifications[
          payload.Id as string
        ] as unknown as Record<string, unknown>;
      }
      return dispatch(patchNotification(payload, prevItem));
    };

// Mark a notification as read
const patchReadNotification = (
  notificationId: string
): NotificationReadAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.NotificationReadRequest,
      ActionTypes.NotificationReadSuccess,
      ActionTypes.NotificationReadFailure,
    ],
    endpoint: Endpoints.Notifications,
    useMy: true,
    options: {
      method: "PATCH",
    },
  },
  [ActionTypes.CallODATA]: {
    query: `/${notificationId}/Read`,
  },
  payload: {
    item: {
      Id: notificationId,
    },
  },
  type: ActionTypes.CallAPI,
});

export const markNotificationAsRead =
  (notificationId: string): AppThunkAction<Action> =>
    (dispatch) => {
      return dispatch(patchReadNotification(notificationId));
    };

// Mark all notifications as read
const patchReadAllNotification = (
  prevItem: Record<string, unknown>
): NotificationReadAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.NotificationReadRequest,
      ActionTypes.NotificationReadSuccess,
      ActionTypes.NotificationReadFailure,
    ],
    endpoint: Endpoints.Notifications,
    useMy: true,
    options: {
      method: "PATCH",
    },
  },
  [ActionTypes.CallODATA]: {
    query: `/Read`,
  },
  payload: {
    item: {
      Id: "All",
    },
    prevItem: prevItem,
  },
  type: ActionTypes.CallAPI,
});

export const markAllNotificationsAsRead =
  (): AppThunkAction<Action> => (dispatch, getState) => {
    const notificationsCopy = getState()?.notifications?.notifications || {};
    return dispatch(patchReadAllNotification(notificationsCopy));
  };

// Delete a notification
const deleteNotification = (
  notification: UserNotification
): NotificationDeleteAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.NotificationDeleteRequest,
      ActionTypes.NotificationDeleteSuccess,
      ActionTypes.NotificationDeleteFailure,
    ],
    endpoint: Endpoints.Notifications,
    useMy: true,
    options: {
      method: "DELETE",
    },
  },
  [ActionTypes.CallODATA]: {
    query: `/${notification.Id}`,
  },
  payload: {
    item: {
      Id: notification.Id,
    },
    prevItem: notification as unknown as Record<string, unknown>,
  },
  type: ActionTypes.CallAPI,
});

export const removeNotification =
  (notificationId: string): AppThunkAction<Action> =>
    (dispatch, getState) => {
      const { notifications } = getState();
      let notification = {} as UserNotification;
      if (notifications && notifications.notifications) {
        notification = notifications.notifications[notificationId];
      }
      return dispatch(deleteNotification(notification));
    };

// Delete all notifications
const deleteAllNotifications = (
  notifications: Record<string, UserNotification>
): NotificationDeleteAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.NotificationDeleteRequest,
      ActionTypes.NotificationDeleteSuccess,
      ActionTypes.NotificationDeleteFailure,
    ],
    endpoint: Endpoints.Notifications,
    useMy: true,
    options: {
      method: "DELETE",
    },
  },
  payload: {
    item: {
      Id: "All",
    },
    prevItem: notifications,
  },
  type: ActionTypes.CallAPI,
});

export const removeAllNotifications =
  (): AppThunkAction<Action> => (dispatch, getState) => {
    const { notifications } = getState();
    return dispatch(
      deleteAllNotifications(
        notifications?.notifications ? notifications?.notifications : {}
      )
    );
  };

export interface NotificationsActionCreators {
  changeNotificationsPage: typeof changeNotificationsPage;
  loadUserNotifications: typeof loadUserNotifications;
  loadNotificationCount: typeof loadNotificationCount;
  updateNotification: typeof updateNotification;
  markNotificationAsRead: typeof markNotificationAsRead;
  markAllNotificationsAsRead: typeof markAllNotificationsAsRead;
  removeNotification: typeof removeNotification;
  removeAllNotifications: typeof removeAllNotifications;
}
