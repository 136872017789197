/** @format */
// React/Redux Library Imports
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { ReactCookieProps, withCookies } from "react-cookie";
import { withRouter, RouteComponentProps } from "react-router-dom";

// Material-UI Imports
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Typography,
  Link,
  WithStyles,
  withStyles,
  Box,
} from "@material-ui/core";
import { TextField, Button } from "@material-ui/core";

// Redux Store Imports
import actions from "../../state/actions";
import { ApplicationState, DispatchThunkAction } from "../../state";
import { ActionTypes } from "../../state/enums/ActionTypes";

// Other Imports
import AuthenticationLayout from "../shared/AuthenticationLayout";
import { makeLoadingSelector } from "../../state/loading/selectors";
import {
  StringLocalizer,
  stringLocalizerSelector,
} from "../../state/localization/selectors";
import { PasswordResetAction } from "../../state/authentication/actions";
import { AuthenticationState } from "../../state/authentication";

// Generating css styles for the component
const styles = (theme: Theme) =>
  createStyles({
    submitBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      padding: `6px 16px`,
      height: "unset",
    },
    modeButton: {
      height: "unset",
      margin: `24px 0px 16px`,
      padding: `6px 16px`,
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    syneHeadersText: {
      fontFamily: "Roboto, sans-serif",
      color: "#4710AE",
      fontWeight: "bold",
    },
    signInBox: {
      width: "100%",
      maxWidth: 480,
    },
  });

// Deconstructed actions
const { resetPassword } = actions;

interface DispatchProps {
  resetPassword: DispatchThunkAction<typeof resetPassword>;
}

// StateProps
interface StateProps {
  loading: boolean;
  stringLocalizer: StringLocalizer;
  authentication: AuthenticationState;
}

interface ResetPasswordState {
  editPassword: string;
  editConfirmPassword: string;
  resetComplete: boolean;
}

interface RouteProps {
  token: string;
}

type ResetPasswordProps = StateProps &
DispatchProps &
ReactCookieProps &
RouteComponentProps<RouteProps> &
WithStyles<typeof styles>;

class ResetPassword extends React.PureComponent<
  ResetPasswordProps,
  ResetPasswordState
> {
  constructor(props: ResetPasswordProps) {
    super(props);
    this.state = {
      editPassword: "",
      editConfirmPassword: "",
      resetComplete: false,
    };
  }

  // Validation Logic
  validateForm() {
    // Ensure that the password field has been filled
    // and confirm matches
    const { editPassword, editConfirmPassword } = this.state;
    if (
      editPassword &&
      editConfirmPassword &&
      editPassword === editConfirmPassword
    ) {
      return true;
    }
    return false;
  }

  handleResetPasswordSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { resetPassword, location } = this.props;
    const { editPassword } = this.state;

    event.preventDefault();

    const token = new URLSearchParams(location.search).get("token");
    if (token) {
      void resetPassword(editPassword, token).then(
        (action: PasswordResetAction) => {
          if (action.type === ActionTypes.ResetPasswordSuccess) {
            this.setState({ resetComplete: true });
          }
        }
      );
    }
  };

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ editPassword: event.target.value });
  };

  handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ editConfirmPassword: event.target.value });
  };

  handleGoToSignIn = () => {
    if (window) {
      window.location.assign(`${process.env.PUBLIC_URL}/login`);
    }
  };

  renderPrivacyPolicy() {
    // Render the privacy policy link
    return (
      <Typography variant="body2" align="center">
        <Link
          color="inherit"
          href={`${process.env.PUBLIC_URL}/privacy`}
          variant="body2"
        >
          Privacy Policy
        </Link>{" "}
      </Typography>
    );
  }

  public render() {
    const { classes, authentication, loading, stringLocalizer } = this.props;
    const { editPassword, editConfirmPassword, resetComplete } = this.state;

    return (
      <AuthenticationLayout>
        <Box className={classes.signInBox} mb={5}>
          <Typography
            component="h1"
            variant="h5"
            className={classes.syneHeadersText}
          >
            Reset password
          </Typography>
          {!resetComplete && authentication?.showError && (
            <Typography variant="subtitle1" align="center" color="error">
              {authentication?.errorMessage
                ? authentication?.errorMessage
                : "Something went wrong. Please try again."}
            </Typography>
          )}
          {!resetComplete && (
            <React.Fragment>
              <form
                id="submit-reset-password-form"
                noValidate
                onSubmit={this.handleResetPasswordSubmit}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="New password"
                  name="password"
                  type="password"
                  value={editPassword}
                  onChange={this.handlePasswordChange}
                  autoFocus
                  size="small"
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="confirm-password"
                  label="Confirm password"
                  name="confirmPassword"
                  type="password"
                  value={editConfirmPassword}
                  onChange={this.handleConfirmPasswordChange}
                  size="small"
                />
                <Button
                  id="submit-reset-password"
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!this.validateForm() || loading}
                  className={classes.submit}
                >
                  {!loading
                    ? stringLocalizer("Reset password")
                    : stringLocalizer("Processing...")}
                </Button>
              </form>
            </React.Fragment>
          )}
          {resetComplete && (
            <React.Fragment>
              <Typography variant="body1">
                {stringLocalizer(
                  "Password change was successful, you can now sign in using your new password."
                )}
              </Typography>
              <Button
                id="go-to-signin"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.handleGoToSignIn}
              >
                Go to sign in
              </Button>
            </React.Fragment>
          )}
          {authentication?.errorMessage==="Invalid token." && <Link id="resend-link" href={`${process.env.PUBLIC_URL}/forgot-password`} >Resend link</Link>}
        </Box>
      </AuthenticationLayout>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  loading: makeLoadingSelector([ActionTypes.ResetPasswordRequest])(state),
  stringLocalizer: stringLocalizerSelector(state),
  authentication: state?.authentication,
});

const mapDispatchToProps = {
  resetPassword,
};

export default compose(
  withCookies,
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ResetPassword) as React.ComponentType;
