import * as React from "react";
// React/Redux Library Imports

// Material-UI Imports
import { createStyles, alpha, Theme } from "@material-ui/core/styles";
import { CssBaseline, Grid, makeStyles, Paper } from "@material-ui/core";

// Other Imports
import backdrop from "../../assets/images/Hero-Pattern.png";
import './Authentication.css'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: 460,
      margin: `${theme.spacing(0)} auto`,
    },
    header: {
      textAlign: "center",
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    card: {
      marginTop: theme.spacing(10),
    },
    root: {
      height: "100vh",
    },
    image: {
      backgroundImage: `url(${backdrop})`,
      backgroundRepeat: "no-repeat",
      backgroundColor: "#210c51",
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "block"
    },
    paper: {
      display: "flex",
      alignItems: "center",
      position: "relative",
      height: "100%",
      justifyContent: "center",
      flexDirection: "column",
      padding:"18px"
    },
  })
);

const AuthenticationLayout: React.FunctionComponent<{
  children?: React.ReactNode;
}> = (props: { children?: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <Grid container component="main" className="root">
      <CssBaseline />
      <img src={backdrop} className="xterioImg" />
      <div className={classes.paper} id="childComponent">
        <React.Fragment>{props.children}</React.Fragment>
      </div>
    </Grid>
  );
};

export default AuthenticationLayout;
