import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { RouteComponentProps } from "react-router-dom";
import { Alert, AutocompleteGetTagProps, AutocompleteRenderInputParams } from "@mui/material";
import {
  createStyles,
  WithStyles,
  withStyles,
  Box,
  Paper,
  TextField,
  Button,
  Theme,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Chip,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { withCookies, ReactCookieProps } from "react-cookie";
import CloseIcon from "@mui/icons-material/Close";
import { AccountState } from "../../state/account";
import { User } from "../../abstractions/user/User";
import { ApplicationState, DispatchThunkAction } from "../../state";
import actions from "../../state/actions";
import { DocumentsAction } from "../../state/documents/actions";
import { forEach, map } from "lodash";
import NexusAvatar from "../shared/NexusAvatar";
import _history from "../../_history";
import { ProfileType } from "../../abstractions/profileType/ProfileType";
import { ActionTypes } from "../../state/enums/ActionTypes";
import { UserGroup } from "../../abstractions/user/UserGroup";
import {
  stringLocalizerSelector,
  StringLocalizer,
} from "../../state/localization/selectors";
import { makeLoadingSelector } from "../../state/loading/selectors";

const styles = ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    root: {},
    header: {
      backgroundColor: palette.primary.main,
      borderRadius: "4px 4px 0 0",
      height: 180,
      padding: 15,
      color: "white",
      position: "relative",
    },
    avatar: {
      height: 150,
      width: 150,
      fontSize: "70px",
      [breakpoints.down("sm")]: {
        width: 120,
        height: 120,
      },
    },
    avatarRoot: {
      height: 150,
      width: 150,
    },
    avatarContainer: {
      minWidth: 180,
      [breakpoints.down("sm")]: {
        minWidth: 125,
      },
    },
    headerInfo: {
      padding: `${spacing(1)}px 0`,
    },
    profileInfo: {
      "&>div": {
        padding: spacing(2),
      },
    },
    editField: {
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      "&>button": {
        margin: 10,
        padding: spacing(2)
      },
      padding: spacing(3)
    },
    high: {
      color: palette.success.main
    },
    medium: {
      color: palette.warning.main
    },
    low: {
      color: palette.error.main
    },
    loading:{
      marginTop: spacing(1.5),
      marginLeft: spacing(3)
    }
  });

const { loadProfile, loadAllUsers, addUser, loadProfileTypes, updateUser, changeUserEmail } = actions;

interface RouteProps {
  id?: string;
}
interface StateProps extends ApplicationState {
  account?: AccountState;
  profileTypes?: Array<ProfileType>;
  newUser?: User;
  stringLocalizer: StringLocalizer;
}

interface PublicProps {
  userId?: string;
  canEdit?: boolean;
  match?: { params: { id: string } }
}

interface DispatchProps {
  loadProfile: DispatchThunkAction<typeof loadProfile>;
  addUser: DispatchThunkAction<typeof addUser>;
  loadProfileTypes: DispatchThunkAction<typeof loadProfileTypes>;
  loadAllUsers: DispatchThunkAction<typeof loadAllUsers>;
  updateUser: DispatchThunkAction<typeof updateUser>;
  changeUserEmail: DispatchThunkAction<typeof changeUserEmail>;
}

type CreateUserProps = StateProps &
PublicProps &
DispatchProps &
ReactCookieProps &
RouteComponentProps<RouteProps> &
WithStyles<typeof styles>;

interface CreateUserState {
  profileUserId: string;
  newUser: User | Record<string, unknown>;
  teamsArr?: Array<{ Name: string, Id: string }>;
  profileTypes?: Array<ProfileType>;
  users?: Array<User>;
  errorMessage?: string;
  prevProfile?: User;
}


class CreateUser extends React.Component<CreateUserProps, CreateUserState> {
  static defaultProps = {
    canEdit: true,
  };

  constructor(props: CreateUserProps) {
    super(props);
    this.state = {
      profileUserId: "",
      newUser: { Teams: [] as Array<string> } as User,
    };
  }

  /**
   * Set the new state of new user being created
   * @param param0 state value to be changed
   */
  changeUserState = ({ target }: React.ChangeEvent<{ value: string, name: string }>) => {
    let { newUser } = this.state;
    const index: string = target.name;
    newUser = { ...newUser, [index]: target.value };
    this.setState({ newUser: newUser });
  }

  /**
   * All single select input are handled.
   */
  // changeUserSelectState = ({ target }: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
  //   let { newUser } = this.state;
  //   const index = target.name as string;
  //   newUser = { ...newUser, [index]: target.value };
  //   this.setState({ newUser: newUser })
  // }

  /**
   * When a file is selected, get the file and set the new profile picture.
   * @param action selected file
   */
  setProfilePicture = (metadata: DocumentsAction) => {
    if (metadata.type === ActionTypes.DocumentUploadSuccess) {
      forEach(metadata.response, (r) => {
        const res = r as { Directory: string, FileId: string, Name: string }
        const { newUser } = this.state;
        const newProfileState = {...newUser};
        newProfileState.ProfilePictureId = res.FileId;
        this.setState({ newUser: newProfileState });
      });
    }
  };

  /**
   * Creates a new profile.
   */
  createProfile = () => {
    const { addUser } = this.props;
    const { newUser } = this.state;
    const payload = { ...newUser };

    if (!payload.Name || (!payload.FirstName && !payload.LastName)) {
      this.setState({ errorMessage: "Prefered Name or Firstname and Lastname required" });
      return;
    }

    void addUser(payload)
      .then((res) => {
        const resp = res as { error: any, type: string }
        if (resp.type === ActionTypes.UsersCreateSuccess) {
          _history.push(`${process.env.PUBLIC_URL}/admin`);
        }
        else {
          this.setState({ errorMessage: res.error as string });
        }
      })
      .catch((err) => {
        const errorResp = err as { error: any, type: string }
        this.setState({ errorMessage: errorResp.error as string });
      });
  };

  componentDidMount() {
    const { loadProfileTypes, loadProfile, match } = this.props;
    void loadProfileTypes().then((res) => {
      const response = res.response as { Values: Array<ProfileType> };
      this.setState({ profileTypes: response?.Values })
    });

    if (match?.params?.id) {
      void loadProfile(match.params?.id).then((res) => {

        const re = res?.response as { entities: { users: any }, Groups: UserGroup[], Teams: Array<{ TeamId: string }> };
        const userData = re.entities.users as Record<string, User>;
        let data;

        forEach(userData, (d) => {
          data = d;
        })

        if (data) {
          data = data as User;
          data.Groups = re.Groups
          this.setState({ newUser: data });
          this.setState({ prevProfile: data });
        }
      });
    }
  }

  onErrorClose = () => {
    this.setState({ errorMessage: undefined })
  };

  getChangedData = () => {
    const { prevProfile, newUser } = this.state;
    const changedDataRec: Record<string, any> = [];
    const newUserRec = Object.entries(newUser);
    const prevProfileRec = Object.entries(prevProfile as User);

    forEach(newUserRec, (rec) => {
      const key = rec[0];
      const keyPrevEntry = prevProfileRec.filter((r) => r[0] === key);

      if (keyPrevEntry?.length > 0) {
        const keyPrev = keyPrevEntry[0][0];
        if (keyPrev === key && rec[1] !== keyPrevEntry[0][1] && rec[1] !== "") {
          changedDataRec[key] = rec[1] as unknown
        }
      }
    })

    const changedData = { ...changedDataRec };
    return changedData;
  }

  editProfile = () => {
    const { newUser, prevProfile } = this.state;
    const { updateUser, changeUserEmail, match } = this.props;
    const payload = this.getChangedData();

    // only send request if  there was a change.
    if (Object.keys(payload).length > 0) {
      payload.Id = match?.params?.id;

      void updateUser(payload).then((res) => {
        const resp = res as { type: string }
        if (resp.type === ActionTypes.UserUpdateSuccess) {

          // initiate email change if required
          if (newUser.Email !== prevProfile?.Email) {
            void changeUserEmail({ Email: newUser.Email }, match?.params?.id);
          }
          _history.push(`${process.env.PUBLIC_URL}/admin`);
        }
        else {
          // may need to be reformated!!
          this.setState({ errorMessage: res.error as string });
        }
      })
    } else {
      this.setState({ errorMessage: "No change detected!" });
    }

  }

  renderSelectedProfile = (value : unknown) => {
    const valueArr = value as Array<string>
    return valueArr?.filter((x) => x).join(",");
  }

  changeSelectProfileState = (value: unknown) => {
    const {target} = value as {target: {name: string, value: Array<string>}};
    if(target.value.indexOf("None") !== -1){
      target.value = ["None"];
    }
    const {newUser} = this.state;
    const updatedUser = {...newUser};
    updatedUser.ProfileTypes = target.value.join(",");
    this.setState({newUser: updatedUser});
  }

  public render() {
    const { classes, match, stringLocalizer, userId, loading } = this.props;
    const { newUser, profileTypes, errorMessage } = this.state;
    const editingUserId = match?.params?.id
    const selectedProfileTypesStr = newUser.ProfileTypes ? newUser.ProfileTypes as string : "";
    const editing = editingUserId !== undefined;
    const selectedProfileTypes = selectedProfileTypesStr.split(",").map((p) => p.trim());

    const header = (
      <Box className={classes.header}>
        <Grid container>
          <Box className={classes.avatarContainer}>
            <NexusAvatar
              classes={{ root: classes.avatarRoot, avatar: classes.avatar }}
              user={newUser ? newUser : { Name: "None", ProfilePictureId: "" }}
              showUploadButton={'false'}
              updateProfilePicture={(e) => this.setProfilePicture(e)}
            />
          </Box>
        </Grid>
      </Box>
    );

    const body = (
      <Box>
        <Grid container className={classes.profileInfo}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              className={classes.editField}
              required
              id="first-name"
              placeholder={stringLocalizer("First Name")}
              name="FirstName"
              value={newUser.FirstName ? newUser.FirstName : ""}
              onChange={(e) => this.changeUserState(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              className={classes.editField}
              required
              id="last-name"
              placeholder={stringLocalizer("Last Name")}
              name="LastName"
              value={newUser.LastName ? newUser.LastName : ""}
              onChange={(e) => this.changeUserState(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              className={classes.editField}
              id="preferred-name"
              placeholder={stringLocalizer("Preferred Name")}
              name="Name"
              value={newUser.Name ? newUser.Name : ""}
              onChange={(e) => this.changeUserState(e)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              className={classes.editField}
              id="email"
              placeholder={stringLocalizer("Email")}
              name="Email"
              value={newUser.Email ? newUser.Email : ""}
              onChange={(e) => this.changeUserState(e)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              className={classes.editField}
              id="phone-number"
              placeholder={stringLocalizer("Phone Number")}
              name="PhoneNumber"
              value={newUser.PhoneNumber ? newUser.PhoneNumber : ""}
              onChange={(e) => this.changeUserState(e)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="profileTypes-select-label">Profile Type</InputLabel>
              <Select
                labelId="profileTypes-select-label"
                id="profileTypes-select"
                multiple
                value={selectedProfileTypes}
                name="ProfileTypes"
                onChange={(e) => this.changeSelectProfileState(e)}
                renderValue={(value) => this.renderSelectedProfile(value)}
              >
                {profileTypes?.map(
                  (pType) => (
                    <MenuItem key={pType.Name} value={pType.Name}>
                      <ListItemIcon>
                        <Checkbox checked={selectedProfileTypes?.indexOf(pType?.Name as string) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={pType.Display} />
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid className={classes.buttonContainer} id="btns-container" item xs={12}>
          <Button id="create-user-cancel-btn" onClick={() => _history.push(`${process.env.PUBLIC_URL}/admin`)}>
            <CloseIcon /> Cancel
          </Button>
          {
            editing && (
              <Button
                variant="contained"
                color="primary"
                id="edit-user-btn"
                onClick={this.editProfile}
                disabled={editingUserId === userId}
              >
                EDIT PROFILE
              </Button>)
          }
          {
            !editing && (
              <Button
                variant="contained"
                color="primary"
                id="create-user-btn"
                onClick={this.createProfile}
              >
                CREATE PROFILE
              </Button>)
          }
          {loading && (<div className={classes.loading}>
            <CircularProgress size={25}  />
          </div>)}

        </Grid>
      </Box>
    );


    return (
      <React.Fragment>
        <Paper elevation={2}>
          {header}
          {body}
        </Paper>

        <Snackbar
          open={!!errorMessage && errorMessage.length > 0}
          autoHideDuration={6000}
          onClose={this.onErrorClose}
        >
          <Alert onClose={this.onErrorClose} severity="error" id="errorMsg">
            {stringLocalizer(errorMessage as string)}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: StateProps) => {
  const stringLocalizer = stringLocalizerSelector(state);
  return {
    account: state.account,
    users: map(state?.users, (user) => user),
    userId: state.account?.Id,
    newUser: state.newUser,
    stringLocalizer,
    loading: makeLoadingSelector([
      ActionTypes.UsersCreateRequest,
      ActionTypes.UserUpdateRequest
    ])(state),
  }
};

const mapDispatchToProps = {
  loadProfile,
  addUser,
  loadProfileTypes,
  loadAllUsers,
  updateUser,
  changeUserEmail
};

export default compose(
  withStyles(styles),
  withCookies,
  connect(mapStateToProps, mapDispatchToProps)
)(CreateUser) as React.ComponentType<PublicProps>;