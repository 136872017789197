import { createSelector } from "reselect";
import { ApplicationState } from "../";
import { ActionTypes } from "../enums/ActionTypes";

const getLoading = (state: ApplicationState) => state.loading?.loading;

// import this function and pass in a Request action type string
// in order to create a selector that can be used to get a boolean
// that is true when the request is being made and false if the
// request has succeeded, failed, or not yet been made
export const makeLoadingSelector = (actionTypes: ActionTypes[]) => {
  return createSelector(
    [getLoading],
    (loading: Record<string, number> | undefined) => {
      if (!loading) return false;
      return actionTypes.some((actionType) =>
        loading[actionType] ? loading[actionType] > 0 : false
      );
    }
  );
};
