// import { ActionTypes } from "../../enums/ActionTypes";

// Config file for the Analytics Middleware

export interface AnalyticsConfig {
  // trackAllByDefault: boolean
  // if true all actions will be tracked by default
  // can be overridden by setting track for individaul actions
  trackAllByDefault?: boolean;

  // defaultIncludePayload: boolean
  // if true sends the payload along with the actions by default
  // can be overriden by declaring includePayload for individual actions
  defaultIncludePayload?: boolean;

  // defaultExcludeFields: string[]
  // define specific string keys that should be excluded from
  // action payloads by default, can be overwritten by
  // declaring excludeFields or onlyIncludeFields for individual actions
  defaultExcludeFields?: string[];

  // defaultOnlyIncludeFields: string[]
  // define specific string keys that should be included from
  // action payloads by default, excluding all other fields
  // if set, defaultExcludeFields is ignored
  // can be overwritten by declaring excludeFields or onlyIncludeFields
  // for individual actions
  defaultOnlyIncludeFields?: string[];

  // actions
  // configure the handling of specific actions
  actions?: {
    // ActionTypes are used as keys
    [actionType: string]: {
      // track: boolean
      // if true this action will be tracked, if not defined falls
      // back to default
      track?: boolean;

      // includePayload: boolean
      // if true sends the payload along with this action
      includePayload?: boolean;

      // excludeFields: string[]
      // define specific string keys that should be excluded from
      // this action's payload, is ignored if onlyIncludeFields is set
      excludeFields?: string[];

      // onlyIncludeFields: string[]
      // define specific string keys that should be included from
      // action payloads by default, excluding all other fields
      // if set, excludeFields is ignored
      onlyIncludeFields?: string[];
    };
  };
}

export default {
  trackAllByDefault: true,
  defaultIncludePayload: true,
  actions: {
    // Examples
    // [ActionTypes.Example1]: {
    //   includePayload: true,
    //   excludeFields: ["Name", "FirstName", "LastName", "Email"]
    // [ActionTypes.Example2]: {
    //   includePayload: true,
    //   onlyIncludeFields: ["ReportType"]
    // }
  },
} as AnalyticsConfig;
