import merge from "lodash/merge";
import { Reducer } from "redux";
import {
  LoginAction,
  CheckAuthenticationAction,
  RequireTwoFactorAction,
  PasswordResetAction,
  RegisterUserAction,
} from "./actions";
import { ActionTypes } from "../enums/ActionTypes";
import {
  ApiError,
  AuthStatus,
  LockedOutLoginError,
  LoginResult,
  NotAllowedLoginError,
} from "../../types";

export interface AuthenticationState {
  authenticationStatus: AuthStatus;
  errorMessage?: ApiError | string;
  redirectUrl?: string;
  showError?: boolean;
  authenticator?: string;
  rememberMeLogin?: boolean;
}

// Updates account cache in response to unknown action with response.account.
const reducer: Reducer<
  AuthenticationState | undefined,
  | LoginAction
  | CheckAuthenticationAction
  | RequireTwoFactorAction
  | PasswordResetAction
  | RegisterUserAction
> = (
  state: AuthenticationState | undefined,
  action:
    | LoginAction
    | CheckAuthenticationAction
    | RequireTwoFactorAction
    | PasswordResetAction
    | RegisterUserAction
) => {
  if (state === undefined) {
    return {
      authenticationStatus: AuthStatus.Processing,
    } as AuthenticationState;
  }
  const returnState = state;
  const errMsg = "Something went wrong. Please try again.";

  if (
    action.type == ActionTypes.CheckAuthenticationRequest ||
    action.type == ActionTypes.LoginRequest ||
    action.type == ActionTypes.RequireTwoFactorRequest
  ) {
    return merge({}, returnState, {
      authenticationStatus: AuthStatus.Processing,
    }) as AuthenticationState;
  }

  if (action.type == ActionTypes.LoginSuccess) {
    const loginResult = action.response as LoginResult;

    if (loginResult?.RequiresTwoFactor) {
      return merge({}, returnState, {
        authenticationStatus: AuthStatus.RequiresTwoFactor,
        authenticator: loginResult.Authenticator,
        rememberMeLogin: action?.payload?.item?.Remember ?? false
      }) as AuthenticationState;
    } else if (loginResult?.ForceTwoFactor) {
      return merge({}, returnState, {
        authenticationStatus: AuthStatus.ForceTwoFactor,
      }) as AuthenticationState;
    } else if (loginResult?.IsLockedOut) {
      return merge({}, returnState, {
        authenticationStatus: AuthStatus.LockedOut,
        showError: true,
        errorMessage: LockedOutLoginError,
      }) as AuthenticationState;
    } else if (loginResult?.IsNotAllowed) {
      return merge({}, returnState, {
        authenticationStatus: AuthStatus.NotAllowed,
        showError: true,
        errorMessage: NotAllowedLoginError,
      }) as AuthenticationState;
    } else if (loginResult?.Succeeded) {
      return merge({}, returnState, {
        authenticationStatus: AuthStatus.Authorized,
        showError: false,
      }) as AuthenticationState;
    }
  }

  if (action.type == ActionTypes.LoginFailure) {
    return merge({}, returnState, {
      authenticationStatus: AuthStatus.Unauthorized,
      redirectUrl: action.redirectUrl,
      showError: true,
      errorMessage: action.error || errMsg,
    }) as AuthenticationState;
  }

  if (action.type == ActionTypes.CheckAuthenticationSuccess) {
    return merge({}, returnState, {
      authenticationStatus: AuthStatus.Authorized,
      redirectUrl: action.redirectUrl,
      showError: false,
    }) as AuthenticationState;
  }

  if (action.type == ActionTypes.PasswordLessLoginSuccess) {
    return merge({}, returnState, {
      authenticationStatus: AuthStatus.PasswordLessAuthorized,
      redirectUrl: action.redirectUrl,
      showError: false,
    }) as AuthenticationState;
  }

  if (
    action.type == ActionTypes.CheckAuthenticationFailure ||
    action.type == ActionTypes.LogoutSuccess ||
    action.type == ActionTypes.PasswordLessLoginFailure
  ) {
    return merge({}, returnState, {
      authenticationStatus: AuthStatus.Unauthorized,
      redirectUrl: action.redirectUrl,
    }) as AuthenticationState;
  }

  if (action.type == ActionTypes.LogoutFailure) {
    return merge({}, returnState, {
      showError: true,
      errorMessage: action.error,
    });
  }

  if (action.type === ActionTypes.ResetPasswordFailure) {
    return merge({}, returnState, {
      showError: true,
      errorMessage: action.error,
    });
  }

  if (action.type === ActionTypes.RegisterUserFailure) {
    return merge({}, returnState, {
      errorMessage: action.error,
    });
  }

  if (action.type === ActionTypes.RegisterUserRequest) {
    delete returnState.errorMessage;
    return returnState;
  }

  if (action.type === ActionTypes.PasswordLessLoginRequest) {
    delete returnState.errorMessage;
    return returnState;
  }

  if (action.type === ActionTypes.AccountInvitationFailure) {
    return merge({}, returnState, {
      errorMessage: action.error,
      showError: true,
    });
  }

  return returnState;
};

export default reducer;
